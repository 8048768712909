import MainHeader from "../../../layout/MainLayout/header"
import { useNavigate } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import { useState } from "react";
import { createBadOutValueInvitationDoasnSurvey } from "../../../service";

const OPTIONCONTENTS = ["옵션1", "옵션2", "옵션3"]

const BadOutForm = () => {
    const navigate = useNavigate();
    const [badOutValue, setBadOutValue] = useState<string>('');

    const clickNextButton = () => {
        const badOutContents = badOutValue === '1' ? OPTIONCONTENTS[0] :
            badOutValue === '2' ? OPTIONCONTENTS[1] :
                badOutValue === '3' ? OPTIONCONTENTS[2] :
                    '';

        createBadOutValueInvitationDoasnSurvey(badOutValue, badOutContents)
            .then(res => {
                navigate('/invitation/dosan/program/3')
            }).catch(err => {
                console.error('err', err);
            })
    }

    return (<>
        <MainHeader
            title={<div style={{ display: 'flex', gap: '8px', justifyContent: 'center', alignItems: 'center', height: '18px' }}>
                <div style={{ width: '70px', height: '2px', backgroundColor: '#000000' }}></div>
                <div style={{ width: '70px', height: '2px', background: '#000000' }}></div>
                <div style={{ width: '70px', height: '2px', background: '#0000004D' }}></div>
            </div>}
            customNavigate={'/invitation/dosan/program/1'}
            hiddenRightIcon />

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 126px)', width: '100%' }}>
            <div style={{ flex: 1, padding: '20px' }}>
                <div style={{ padding: '20px' }}>
                    <div style={{ textAlign: 'center', fontSize: '28px', fontWeight: '700', lineHeight: '34px' }}>당신의 BAD OUT?
                        <br />
                        최대 2줄
                    </div>
                </div>
                <div style={{ marginTop: '48px' }}>
                    <div
                        onClick={() => setBadOutValue("1")}
                        style={{
                            backgroundColor: badOutValue === '1' ? 'black' : 'white',
                            color: badOutValue === '1' ? 'white' : 'black',
                            height: '48px', border: '1px solid #000000', borderRadius: '4px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 12px', fontSize: '14px', cursor: 'pointer'
                        }}>
                        <div>{OPTIONCONTENTS[0]}</div>
                        <div><CheckOutlined /></div>
                    </div>
                    <div
                        onClick={() => setBadOutValue("2")}
                        style={{
                            backgroundColor: badOutValue === '2' ? 'black' : 'white',
                            color: badOutValue === '2' ? 'white' : 'black',
                            marginTop: '32px', height: '48px', border: '1px solid #000000', borderRadius: '4px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 12px', fontSize: '14px', cursor: 'pointer'
                        }}>
                        <div>{OPTIONCONTENTS[1]}</div>
                        <div><CheckOutlined /></div>
                    </div>
                    <div
                        onClick={() => setBadOutValue("3")}
                        style={{
                            backgroundColor: badOutValue === '3' ? 'black' : 'white',
                            color: badOutValue === '3' ? 'white' : 'black',
                            marginTop: '32px', height: '48px', border: '1px solid #000000', borderRadius: '4px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 12px', fontSize: '14px', cursor: 'pointer'
                        }}>
                        <div>{OPTIONCONTENTS[2]}</div>
                        <div><CheckOutlined /></div>
                    </div>
                </div>
            </div>
        </div>
        {/* footer start */}
        <div style={{ position: "fixed", bottom: "0", width: "100%", maxWidth: "480px", textAlign: 'center' }}>
            <div style={{ fontSize: "15px", fontWeight: "700", backgroundColor: "black", color: "#FFFFFF", cursor: "pointer" }}>
                <div style={{ padding: "26px 0" }} onClick={() => { clickNextButton() }}>다음</div>
            </div>
        </div>
    </>)
}
export default BadOutForm
import { useEffect, useState } from "react";
import { ReactComponent as ArrowDown } from "../../../../assets/images/icon/arrow-down.svg";
import { IYogaCenter, yogaCenterDataList } from '../../model'
import { YogaCenterBottomSheet } from "../yogaCenterBottomSheet";
import { RootState } from "../../../../modules";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { updateInitYogaCenterSelectorThunk } from "../../../../modules/initYogaCenterSelector";
interface IProps {
    selectedYogaCenterId: number | undefined
    setSelectedYogaCenterId: Function
}

const YogaCenterSelcetorModal = ({ selectedYogaCenterId, setSelectedYogaCenterId }: IProps) => {
    const dispatch: ThunkDispatch<any, any, any> = useDispatch();
    const [isOpenYogaCenterBottomSheet, setIsOpenYogaCenterBottomSheet] = useState<boolean>(false);
    const { data } = useSelector((state: RootState) => state.initYogaCenterSelector.initYogaCenterSelector);

    useEffect(() => {
        if (data && data.isFirstTime) {
            setIsOpenYogaCenterBottomSheet(true)
            dispatch(updateInitYogaCenterSelectorThunk(false));
        }
    }, [])

    const selectedYogaCenterData = () => {
        return yogaCenterDataList.find((yogaCenter: IYogaCenter) => yogaCenter.id === selectedYogaCenterId) || yogaCenterDataList[3];
    }

    return (<>
        <div className="title/t2" style={{ cursor: 'pointer' }} onClick={() => setIsOpenYogaCenterBottomSheet(true)}>
            {selectedYogaCenterData() && selectedYogaCenterData().shortName} <ArrowDown width={16} height={16} stroke={"#AEAEAE"} />
        </div>
        <YogaCenterBottomSheet
            isOpen={isOpenYogaCenterBottomSheet}
            setIsOpen={setIsOpenYogaCenterBottomSheet}
            dataList={yogaCenterDataList}
            selectedYogaCenterId={selectedYogaCenterId}
            setSelectedYogaCenterId={setSelectedYogaCenterId}
        />
    </>
    )
}

export { YogaCenterSelcetorModal }
import { Skeleton } from "antd"
import { useMyContext } from "../../../../entities/context";

const NotificationDetailSkeleton = () => {
    const { topHeight } = useMyContext();

    return <div style={{ position: 'absolute', maxWidth: "480px", backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), width: '100%', height: '100vh' }}>
        <div style={{ padding: '0 24px 118px' }}>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
                <div style={{ width: '215px', display: 'inline-block' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
                </div>
            </div>
            <div style={{ marginTop: 100 }}>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: '160px' }} />
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24, marginTop: 4 }} />
            </div>
        </div>
    </div>
}

export default NotificationDetailSkeleton;
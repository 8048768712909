import { useMyContext } from "../../entities/context";

interface IProps {
    thema?: 'dark' | 'white';
}
const SafeArea = ({ thema = 'white' }: IProps) => {
    const { topHeight, currentDeviceBuildVersion } = useMyContext();

    return <>
        <div style={{
            maxWidth: '480px', width: '100%', position: 'fixed', zIndex: 1,
            height: currentDeviceBuildVersion ? topHeight + "px" : 0,
            backgroundColor: thema === 'dark' ? 'var(--basewhite)' : "transparent",
            transition: "background-color 0.3s, color 0.3s",
        }} />
    </>
}

export { SafeArea }
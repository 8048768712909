import { Button, DatePicker, Flex, Input, Modal, Space } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { formatPhoneNumber } from "../../../utils";
import dayjs, { Dayjs } from "dayjs";
import { cancelPrivateClassInquiry, getPrivateClassInquiryById, updatePrivateClassInquiry, sendSlackUsingWebhook } from "../../../service";
import { TopBar } from "../../../widgets";

const { TextArea } = Input;

const badgeStyle = {
    width: '38px',
    height: '20px',
    borderRadius: '4px',
    padding: '3px 6px',
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

const PrivateClassApplyDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [date, setDate] = useState<Dayjs>(dayjs());
    const [programName, setProgramName] = useState<string>('');
    const [personValue, setPersonValue] = useState<number>(1);
    const [phone, setPhone] = useState<string>('');
    const [requestText, setRequestText] = useState<string>('');
    const [status, setStaus] = useState<string>('');
    const [isOpenResultReservationModal, setIsOpenResultReservationModal] = useState<boolean>(false);

    useEffect(() => {
        if (!id) return;
        getPrivateClassInquiryById(id)
            .then((res) => {
                setRequestText(res.data.requestText);
                setProgramName(res.data.programName);
                setPersonValue(Number(res.data.personCnt));
                setDate(dayjs(res.data.requestDate.substring(0, 10)));
                setPhone(res.data.mobile);
                setStaus(res.data.status);
            })
            .catch((error) => { console.error('error', error); navigate(-1) });
    }, [])
    const handleChange = (event: any) => {
        const formattedPhoneNumber = formatPhoneNumber(event.target.value);
        setPhone(formattedPhoneNumber);
    }
    const DateFormatter = (date: Dayjs) => {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        const dayName = dayjs(date).format('ddd');
        const fullDate = `${formattedDate} (${dayName})`;
        return fullDate;
    };

    const clickUpdatePrivateClassInquiry = () => {
        if (!id) return;
        cancelPrivateClassInquiry(id, '취소')
            .then((res) => {
                if (res.data) {
                    sendSlackUsingWebhook(`프라이빗 클래스 요청 취소 \n 프로그램: ${programName} \n 신청 날짜 : ${DateFormatter(date)} \n 인원 : ${personValue} \n 연락처: ${phone} \n 요청 사항: ${requestText} \n`)
                        .then((res) => res.data && navigate(-1))
                        .catch((error) => console.error('error', error));
                }
            }).catch((error) => console.error('error', error));
    }
    const clickUpdateReservationPrivateClassButton = () => {
        if (!id) return;
        updatePrivateClassInquiry(id, {
            programName: programName,
            status: '신청중',
            requestDate: DateFormatter(date),
            personCnt: personValue.toString(),
            mobile: phone,
            requestText: requestText
        }).then((res) => {
            if (res.data) {
                sendSlackUsingWebhook(`프라이빗 클래스 변경 \n 프로그램: ${programName} \n 신청 날짜 : ${DateFormatter(date)} \n 인원 : ${personValue} \n 연락처: ${phone} \n 요청 사항: ${requestText} \n`)
                    .then((res) => res.data && setIsOpenResultReservationModal(true))
                    .catch((error) => console.error('error', error));
            }
        }).catch((error) => console.error('error', error))
    }

    return (
        <>
            <TopBar title="신청 내역 상세" rightIcon={false} />
            <div style={{ marginTop: '16px', padding: '12px 20px' }}>
                <Flex style={{ justifyContent: 'space-between', fontSize: '16px', lineHeight: '28px' }}>
                    <div style={{ color: '#555555' }}>프로그램</div>
                    <Flex gap={16}>
                        <div style={{ color: 'black' }}>{programName} </div>
                        {status === '신청중' ?
                            <div style={{ backgroundColor: '#E9E9E9', color: '#555555', ...badgeStyle }}>{status}</div> :
                            status === '취소' ?
                                <div style={{ backgroundColor: '#ffffff', color: '#555555', border: '1px solid #555555', ...badgeStyle }}>{status}</div> :
                                status === '확정 완료' ?
                                    <div style={{ backgroundColor: '#000000', color: '#ffffff', ...badgeStyle }}>{status}</div> :
                                    <div>{status}</div>}
                    </Flex>
                </Flex>
            </div>
            <div style={{ height: '6px', backgroundColor: '#F4F4F4', marginTop: '12px' }}></div>
            <div style={{ marginTop: '24px', padding: '0 20px' }}>
                <div style={{ fontWeight: 600, lineHeight: '20px' }}>날짜를 선택하세요</div>
                <DatePicker
                    value={date}
                    onChange={(e) => e && setDate(e)}
                    defaultValue={dayjs()}
                    size="large" style={{ width: '100%', marginTop: '12px', height: '48px' }} />
            </div>

            <div style={{ marginTop: '40px', padding: '0 20px' }}>
                <div style={{ fontWeight: 600, lineHeight: '20px' }}>인원을 선택하세요</div>
                <div style={{ marginTop: '12px' }}>
                    <Space.Compact style={{ width: '100%' }}>
                        <Button
                            onClick={() => personValue > 1 && setPersonValue(personValue - 1)}
                            size="large" style={{ height: '48px', borderRight: 'none', borderColor: '#d9d9d9' }}>-</Button>
                        <Input type="number" value={personValue} size="large" style={{ height: '48px', borderRight: 'none', borderLeft: 'none', textAlign: 'center', borderColor: '#d9d9d9', backgroundColor: 'white', color: '#000000' }} disabled />
                        <Button
                            onClick={() => setPersonValue(personValue + 1)}
                            size="large" style={{ height: '48px', borderLeft: 'none', borderColor: '#d9d9d9' }}>+</Button>
                    </Space.Compact>
                </div>
            </div>

            <div style={{ marginTop: '40px', padding: '0 20px' }}>
                <div style={{ fontWeight: 600, lineHeight: '20px' }}>연락처를 확인하세요</div>
                <div style={{ marginTop: '12px' }}>
                    <Input
                        value={phone}
                        onChange={handleChange}
                        placeholder="010-1234-5678" size="large" style={{ height: '48px' }} />
                </div>
                <div style={{ marginTop: '12px', color: '#7D7D7D', fontSize: '12px' }}>수업 예약 확정 연락을 받을 수 있는 번호를 입력해 주세요.</div>
            </div>

            <div style={{ marginTop: '40px', padding: '0 20px' }}>
                <div style={{ fontWeight: 600, lineHeight: '20px' }}>요청사항</div>
                <div style={{ marginTop: '12px' }}>
                    <TextArea
                        value={requestText}
                        rows={4}
                        placeholder="요청사항이 있으신 경우 작성해 주세요."
                        onChange={(e) => setRequestText(e.target.value)} />
                </div>
            </div>

            <div style={{ marginTop: '60px', padding: '0 20px' }}>
                {status === '확정 완료' ?
                    <div style={{ color: '#676767', fontSize: '14px', lineHeight: '24px' }}>※ 수업 취소를 원하실 경우, 담당자에게 연락 부탁드립니다.</div> :
                    <div>
                        <div
                            onClick={() => clickUpdateReservationPrivateClassButton()}
                            style={{ width: 'clac(100% - 40px)', backgroundColor: 'black', borderRadius: '4px', padding: '16px 20px', textAlign: 'center', color: 'white', fontSize: '16px' }}>수정하기</div>
                        <div
                            onClick={() => clickUpdatePrivateClassInquiry()}
                            style={{ marginTop: '16px', lineHeight: '20px', fontSize: '12px', textDecoration: 'underline', textAlign: 'center' }}>신청 취소하기</div>
                    </div>}
            </div>

            <Modal centered open={isOpenResultReservationModal} onCancel={() => setIsOpenResultReservationModal(false)} footer={[]}>
                <div style={{ textAlign: 'center' }}>
                    <div style={{ fontSize: '22px', fontWeight: 'bold', marginTop: '20px' }}>변경을 완료했습니다</div>
                    <div style={{ marginTop: '5px', fontSize: '13px' }}>내용 확인 후 24시간 내에<br />연락처로 연락드리겠습니다</div>
                </div>
                <div style={{ backgroundColor: 'black', border: '1px solid #555', borderRadius: '5px', color: 'white', padding: '15px', textAlign: 'center', fontSize: '15px', marginTop: '15px', cursor: 'pointer' }}
                    onClick={() => {
                        setIsOpenResultReservationModal(false);
                        navigate('/private/class/apply/history');
                    }}>확인</div>
            </Modal>
        </>
    )
}

export default PrivateClassApplyDetail
import { requestNotificationReceiptOk, updateFcmToken } from "../service";
import { isLogin } from '.';

declare global {
    interface Window {
        appleLogin: any;
        getAppleAuthCode: any;
        getCurrentDeviceBuildVersion: any;
        getFCMToken: any;
        getInjectedHardwareValue: any;
        getSafeAreaTopHeight: any;
        getStatusBarTopHeight: any;
        kakao: any
        mobileCheck: any;
        native_yoga_bridge: any
        notificationReceiptOk?: (memberId: string, notificationReceiptOkId?: string) => void;
        opera: any;
        reactNavigator: Function;
        webkit: any
    }
}

const deepLinkBridgeElseNewTap = (deepLinkUrl: string) => {
    if (window.native_yoga_bridge) {
        window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'openChrome', data: { url: deepLinkUrl } }));
        return;
    }
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
        window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'openSafariWebView', data: { openUrl: deepLinkUrl } }))
        return;
    }
    if (!window.native_yoga_bridge && !window.webkit) {
        return window.open(deepLinkUrl, '_blank');
    }
}
const notificationReceiptOkBridge = () => {
    if (window.native_yoga_bridge) {
        window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'notificationReceiptOk', data: {} }));
        window.notificationReceiptOk = (memberId: string, notificationReceiptOkId?: string) => {
            if (!notificationReceiptOkId) return;
            requestNotificationReceiptOk(notificationReceiptOkId)
        }
        return;
    }
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
        window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'notificationReceiptOk', data: {} }))
        window.notificationReceiptOk = (memberId: string, notificationReceiptOkId?: string) => {
            if (!notificationReceiptOkId) return;
            requestNotificationReceiptOk(notificationReceiptOkId)
        }
        return;
    }
}
const getSafeAreaTopHeight = (setTopHeight: Function, setCurrentDeviceBuildVersion?: Function) => {
    if (window.native_yoga_bridge) {
        window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'getCurrentDeviceBuildVersion', data: {} }));
        window.getCurrentDeviceBuildVersion = (currentVersion: string) => {
            if (Number(currentVersion.split('.')[0]) >= 8) {
                window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'getStatusBarTopHeight', data: {} }));
                window.getStatusBarTopHeight = (topHeight: string) => { setTopHeight(topHeight); setCurrentDeviceBuildVersion?.(true) }
            }
        }
    }
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
        window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'getCurrentDeviceBuildVersion', data: {} }))
        window.getCurrentDeviceBuildVersion = (currentVersion: string) => {
            if (Number(currentVersion.split('.')[0]) >= 8) {
                window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'getSafeAreaTopHeight', data: {} }))
                window.getSafeAreaTopHeight = (topHeight: string) => { setTopHeight(topHeight); setCurrentDeviceBuildVersion?.(true); }
            }
        }
    }
}
const requestStandardVersion = () => {
    if (window.native_yoga_bridge) {
        window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'appVersionCheck', data: { standardAppVersion: "4.0.0" } }));
    }
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
        window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'appVersionCheck', data: { standardAppVersion: "4.0.0" } }))
    }
}
const storedFCMTokenForMobile = () => {
    if (!isLogin()) return;
    if (window.native_yoga_bridge) {
        window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'getFCMToken', data: {} }));
        window.getFCMToken = (fcmToken: string) => { updateFcmToken(({ fcmToken })) }
    }
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
        window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'getFCMToken', data: {} }))
        window.getFCMToken = (fcmToken: string) => { updateFcmToken(({ fcmToken })) }
    }
}
const isIosMobile = () => window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge

const kakaoMapNative = (url: string) => {
    if (window.native_yoga_bridge) {
        window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'openChrome', data: { url: url } }));
        return;
    }
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
        window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'openSafariWebView', data: { openUrl: url } }))
        return;
    }
    if (!window.native_yoga_bridge && !window.webkit) {
        return window.open(url, '_blank');
    }
}


export { deepLinkBridgeElseNewTap, notificationReceiptOkBridge, getSafeAreaTopHeight, requestStandardVersion, storedFCMTokenForMobile, isIosMobile, kakaoMapNative }
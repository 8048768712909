
import { useEffect, useState } from "react"
import { TicketSelectCard } from "../../shared";
import TicketSkeleton from "../../components/skeleton/ticket/ticketSkeleton";
import { TopBar } from "../../widgets";
import { useNavigate } from "react-router-dom";
import { getAllWellnessTicketGroupAndWellnessTicketNameWithCenterInfoListByCompanyId } from "../../service/wellnessTicketGroup";

const WellnessTicketGroup = () => {
    const navigate = useNavigate();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const [wellnessTicketGroup, setWellnessTicketGroup] = useState<any[]>();
    const companyId = 1;
    useEffect(() => {
        if (!companyId) return;
        setIsInitLoading(true)
        getAllWellnessTicketGroupAndWellnessTicketNameWithCenterInfoListByCompanyId(companyId)
            .then(res => { setWellnessTicketGroup(res.data); })
            .catch(err => { console.error(err); })
            .finally(() => { setIsInitLoading(false); })
    }, [])

    return <>
        {isInitLoading && <TicketSkeleton />}
        <TopBar title="정기권 선택" />
        <div style={{ padding: '24px var(--space20)' }}>
            {wellnessTicketGroup
                ?.filter(q => q.isDisplay)
                ?.map(q => <TicketSelectCard key={q.id} title={q.title} description={q.description} onClick={() => { navigate(`/wellness-ticket-group/${q.id}`) }} />)}
        </div>
    </>
}
export { WellnessTicketGroup }
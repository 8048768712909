import { Outlet } from 'react-router-dom';
import { SafeArea } from '../../shared';
import ScrollToTop from '../../shared/scrollToTop';


const MainLayout: React.FC = () => {

    return (<>
        <SafeArea />
        <div style={{ maxWidth: "480px", marginInline: "auto", backgroundColor: 'var(--basewhite)', width: "auto", minHeight: "100vh" }}>
            <ScrollToTop />
            <Outlet />
        </div>
    </>);
};

export default MainLayout;
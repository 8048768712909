import { useNavigate } from "react-router-dom";
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Button, Flex, Modal } from "antd";
import MyResignSkeleton from "../../components/skeleton/my/myResignSkeleton";
import { getResignDataByMemberId } from "../../service";
import { removeUser } from "../../service";
import { resignUser } from "../../service";
import { BasicInfoRow, DividerThin } from "../../shared";
import { TopBar } from "../../widgets";

const Resign = () => {
    const navigate = useNavigate();
    const [isAgree, setIsAgree] = useState<boolean>(false);
    const [resignData, setResignData] = useState<IMemberMyResignWebResponseV2>();
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
    const [isOpenCompleteModal, setIsOpenCompleteModal] = useState<boolean>(false);
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);

    useEffect(() => {
        requestMemberCouponPointTicket();
    }, []);

    const requestMemberCouponPointTicket = () => {
        getResignDataByMemberId()
            .then(res => { setResignData(res.data) })
            .catch((error) => console.error('error', error))
            .finally(() => setIsInitLoading(false));
    }

    const resignButtonClick = async () => {
        try { await resignUser(); } catch (error) { console.error("error :", error) }
        removeUser();
        setIsOpenConfirmModal(false);
        setIsOpenCompleteModal(true);
    }

    return <>
        {isInitLoading && <MyResignSkeleton />}
        <TopBar title="탈퇴 안내" />
        <div style={{ padding: '20px' }}>
            <div className="title/t1">
                탈퇴하기 전 안내 사항을 꼭 확인해주세요.
            </div>

            <div>
                <DividerThin style={{ marginTop: '20px' }} />
                <BasicInfoRow title="회원등급" content={resignData?.grade} />
                <DividerThin />
                <BasicInfoRow title="소멸 예정 쿠폰" content={`${resignData?.issuedCouponCnt} 장`} />
                <DividerThin />
                <BasicInfoRow title="소멸 예정 포인트" content={`${resignData ? resignData?.totalPointValue.toLocaleString() : 0} p`} />
                <DividerThin />

                <div style={{ marginTop: '24px' }}>
                    <div className="sub-title/st2">1. 탈퇴 시 삭제되는 내용</div>
                    <ul className="body/b2" style={{ paddingLeft: '26px', marginTop: '5px' }}>
                        <li>탈퇴 시 고객님께서 보유하셨던 쿠폰과 포인트는 모두 소멸되며, 환불할 수 없습니다. 또한 다른 계정으로 양도 또는 이관할 수 없습니다.</li>
                        <li style={{ marginTop: '2px' }}>탈퇴한 계정 및 이용 내역은 복구할 수 없으니 탈퇴 시 유의하시기 바랍니다.</li>
                    </ul>
                    <div className="sub-title/st2">2. 탈퇴 시 보관 또는 유지되는 항목</div>
                    <ul className="body/b2" style={{ paddingLeft: '26px', marginTop: '5px' }}>
                        <li>반복 재가입 등의 방법을 이용한 서비스 부정이용 방지를 위하여 키 고유 정보(이름, ID, DI, 성별, 생년)는 회원 탈퇴 후 30일간 보관합니다.</li>
                        <li style={{ marginTop: '2px' }}>관계 법령에 의한 일정 기간 보관 의무를 부과하는 경우에해당 기간 동안 개인정보를 안전하게 보관 합니다.</li>
                    </ul>
                </div>

                <div
                    style={{ marginTop: '24px', fontSize: '14px', color: '#555555' }}
                    onClick={() => setIsAgree(!isAgree)}>
                    {isAgree ? <div style={{ color: 'black' }}>
                        <CheckCircleFilled style={{ paddingRight: '10px' }} />내용을 모두 확인했으며 탈퇴를 계속합니다.
                    </div> : <div>
                        <CheckCircleOutlined style={{ color: '#AEAEAE', paddingRight: '10px' }} />내용을 모두 확인했으며 탈퇴를 계속합니다.
                    </div>}
                </div>

                <div style={{ height: 100 }}></div>

            </div>
        </div>
        <div style={{
            width: '100%',
            height: 75,
            position: 'fixed',
            bottom: 0,
            maxWidth: '480px',
        }}>
            {isAgree
                ? <div
                    style={{
                        width: 'calc(100% - 40px)',
                        maxWidth: '440px',
                        backgroundColor: 'var(--baseblack)',
                        color: 'var(--basewhite)',
                        textAlign: 'center',
                        paddingBlock: 16,
                        borderRadius: '4px',
                        margin: '0 auto'
                    }}
                    onClick={() => setIsOpenConfirmModal(true)}>다음</div>
                : <div
                    style={{
                        width: 'calc(100% - 40px)',
                        maxWidth: '440px',
                        backgroundColor: 'var(--secondarys200)',
                        color: 'var(--secondarys400)',
                        textAlign: 'center',
                        paddingBlock: 16,
                        borderRadius: '4px',
                        margin: '0 auto'
                    }}>다음</div>
            }
        </div>


        <Modal
            open={isOpenConfirmModal}
            closable={false}
            centered
            onOk={() => console.log('ok')}
            onCancel={() => setIsOpenConfirmModal(false)}
            footer={[
                <Flex style={{ marginTop: '25px' }} gap={12}>
                    <Button
                        key="back"
                        onClick={() => setIsOpenConfirmModal(false)}
                        style={{ width: '50%', height: '50px', fontSize: '16px', fontWeight: '600', backgroundColor: 'black', color: 'white' }}>
                        취소
                    </Button>
                    <Button
                        key="submit"
                        danger
                        onClick={() => resignButtonClick()}
                        style={{ width: '50%', height: '50px', fontSize: '16px', fontWeight: '600', margin: 0, borderWidth: '1px' }}>
                        탈퇴
                    </Button>
                </Flex>
            ]}
        >
            <div style={{ textAlign: 'center' }}>
                <div className="sub-title/st4">탈퇴를 계속하시겠습니까?</div>
                <div className="body/b2 secondary/s800" style={{ marginTop: 8 }}>‘탈퇴’ 버튼을 누르시면 이용내역이 삭제되고 <br />계정을 복구할 수 없습니다.</div>
            </div>
        </Modal>

        <Modal
            open={isOpenCompleteModal}
            closable={false}
            centered
            onOk={() => console.log('ok')}
            onCancel={() => setIsOpenCompleteModal(false)}
            footer={[
                <Button
                    key="back"
                    onClick={() => navigate("/")}
                    style={{ width: '100%', height: '50px', fontSize: '16px', fontWeight: '600', backgroundColor: 'black', color: 'white' }}>
                    처음으로
                </Button>
            ]}
        >
            <div style={{ textAlign: 'center' }}>
                <div className="sub-title/st4">탈퇴를 완료했습니다</div>
                <div className="body/b2 secondary/s800" style={{ marginTop: '15px' }}>이용해주셔서 감사합니다.</div>
            </div>
        </Modal>
    </>
}

export default Resign
const PositiveHotelMemberShip = () => {
    return (
        <div>
            <div style={{ marginTop: 80, padding: '0 20px' }}>
                <div style={{ fontSize: '20px', lineHeight: '30px', fontFamily: 'TuskerGrotesk6500Medium' }}>POSITIVE HOTEL MEMBERSHIP</div>
                <div style={{ color: '#676767', fontSize: '14px', lineHeight: '24px', marginTop: '8px' }}>파지티브호텔 멤버십</div>
                <div style={{ fontSize: '14px', lineHeight: '24px', marginTop: '20px' }}>파지티브호텔이 제안하는 웰니스 프로그램을 차별화된 혜택과 리워드로 누려보세요.</div>
            </div>
            <div style={{ marginTop: 80 }}>
                <img src={'https://img.positivehotel.io/2024/7/23/PositiveHotelMembership.png'} alt="파지티브호텔 멤버십" style={{ width: '100%' }} />
            </div>
        </div>
    )
}
export default PositiveHotelMemberShip;
import { useNavigate, useSearchParams } from "react-router-dom";
import { getNaverUser } from "../../../service";
import { useEffect, useState } from "react";
import { getUrl, removeUrl } from "../../../service";
import { getPolicyDataTransferByToken } from "../../../service";
import PolicyDataTransfer from "../../../components/modal/policyDataTransfer";

const NaverLoginCallback = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    const [policyModalStatus, setPolicyModalStatus] = useState<boolean>(false);

    useEffect(() => {
        if (!code) {
            navigate('/login');
            return;
        }
        requestGetUser(code);
    }, [code]);
    const requestGetUser = async (code: string) => {
        try {
            await getNaverUser(code);
            const beforeUrl = getUrl();
            removeUrl();
            getPolicyDataTransferByToken()
                .then(res => {
                    if (!res.data.isPersonInfoPolicyAgree || !res.data.isServiceUseTermAgree) { setPolicyModalStatus(true) } else { navigate(beforeUrl ? beforeUrl : '/', { replace: true }) }
                })
                .catch(error => console.error("error", error))

        } catch (error) {
            console.error('error', error);
            navigate('/login');
        }
    }
    return <>
        <PolicyDataTransfer propsModalStatus={policyModalStatus} />
    </>
}
export default NaverLoginCallback;
import { useEffect, useState } from "react";
import MyRewardSkeleton from "../../../components/skeleton/my/myRewardSkeleton"
import { isLogin } from "../../../utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getReward, setUrl } from "../../../service";
import { CustomTabs } from "../../../shared";
import { PointTab } from "./ui/pointTab";
import { CouponTab } from "./ui/couponTab";
import { TopBar } from "../../../widgets";

const MyReward = () => {
    const navigate = useNavigate();
    const [reward, setReward] = useState<IReward | undefined>(undefined);
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const location = useLocation()
    const { isActiveTab } = useParams();
    useEffect(() => {
        if (!isLogin()) {
            setUrl(location.pathname + location.search)
            navigate("/login")
            return;
        }

        getReward()
            .then(res => setReward(res.data))
            .catch(err => console.error('err', err))
            .finally(() => setIsInitLoading(false))
    }, [])

    return (
        <div>
            {isInitLoading && <MyRewardSkeleton />}
            <TopBar title="포인트 · 쿠폰" rightIcon={false} />
            {reward && <>
                <CustomTabs items={[
                    { key: 0, label: '포인트', children: <PointTab totalPoint={reward.totalPoint} pointList={reward.pointList} /> },
                    { key: 1, label: '쿠폰', children: <CouponTab couponList={reward.issuedCouponList} /> }]}
                    defaultkey={isActiveTab === 'coupon' ? 1 : 0} />
            </>}
        </div>
    )
}

export { MyReward }
interface IProps {
    title: string | React.ReactNode;
    content: string | React.ReactNode;
    style?: React.CSSProperties;
    styles?: { container?: React.CSSProperties, title?: React.CSSProperties, content?: React.CSSProperties };
}
const InlineWellnessTicketReceipt = ({ title, content, style, styles }: IProps) => {
    return <div className="body/b2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 'var(--space12)', ...style, ...styles?.container }} >
        <div className="secondary/s800" style={styles?.title}>{title}</div>
        <div className="base/black" style={styles?.content}>{content}</div>
    </div>
}
export { InlineWellnessTicketReceipt }
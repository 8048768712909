import { Modal } from "antd"

interface IProps {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    barcodeUrl: string
}

const OnlyBarcodeFullScreenModal = ({ isOpen, setIsOpen, barcodeUrl }: IProps) => {
    return (<>
        <Modal
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            footer={false}
            closeIcon={false}
            centered
            styles={{
                content: { margin: "0", padding: "0", boxShadow: "none" },
                footer: { margin: "0", padding: "0" },
                body: { margin: "0", padding: "0", height: 0 },
                mask: { backgroundColor: '#000000' }
            }}>
            <img src={barcodeUrl}
                style={{
                    width: "160%",
                    height: "150px",
                    msTransform: "rotate(90deg)",
                    WebkitTransform: "rotate(90deg)",
                    transform: "rotate(90deg)",
                    marginLeft: "-30%",
                    marginTop: "-35%",
                    borderRadius: "15px",
                    padding: "13% 0",
                    backgroundColor: "#FFF"
                }}
                onClick={() => setIsOpen(false)} />
        </Modal>
    </>
    )
}

export { OnlyBarcodeFullScreenModal }
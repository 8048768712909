import { useNavigate, useParams } from "react-router-dom";
import { isLogin } from "../../../utils";
import { TopBar } from "../../../widgets";
import { useEffect, useState } from "react";
import { getWellnessLectureDetailByWellnessLectureId } from "../../../service/wellnessLecture";
import { Button, Checkbox, Flex, Input, Modal, Rate, Tag, message } from "antd";
import ImageUploader from "../ui/ImageUploader";
import { deleteWellnessLectureReview, getWellnessLectureReviewByWellnessLectureId, updateWellnessLectureReview } from "../../../service/wellnessLectureReview";
import dayjs from "dayjs";
import { DeleteOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const reviewCategoryList = [
    { id: 1, title: "🕒 수업 시간을 잘 지켜요" },
    { id: 2, title: "😉 자세를 꼼꼼히 봐주세요" },
    { id: 3, title: "💙 친절하게 설명해요" },
    { id: 4, title: "‍🤸‍♂️ 난이도가 적절해요" },
]

const ViewReview = () => {
    const navigate = useNavigate();
    const { wellnessLectureId } = useParams();
    const [wellnessLecture, setWellnessLecture] = useState<IWellnessLectureDetailWebResponseV2>();
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [uploadedUrls, setUploadedUrls] = useState<Array<string>>([]);
    const [content, setContent] = useState<string | undefined>(undefined);
    const [rating, setRating] = useState<number | undefined>(undefined);
    const [isPrivate, setIsPrivate] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
    const [wellnessLectureReviewId, setWellnessLectureReviewId] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (!isLogin()) {
            navigate("/login")
            return;
        }
        if (!wellnessLectureId) return;

        getWellnessLectureDetailByWellnessLectureId(wellnessLectureId)
            .then(res => {
                setWellnessLecture(res.data)
                const endDateTime = dayjs(res.data.wellnessLectureEndDateTime);
                const threeDaysLater = endDateTime.add(3, 'day');
                const hasThreeDaysPassed = dayjs().isAfter(threeDaysLater.set('hour', 23).set('minute', 59).set('second', 59));
                setIsDisabled(hasThreeDaysPassed);
            })
            .catch(err => console.error('err', err))

        getWellnessLectureReviewByWellnessLectureId(wellnessLectureId)
            .then(res => {
                const review = res.data;
                setWellnessLectureReviewId(review.id);
                setRating(review.rating);
                setSelectedTags(review.reviewCategoryList);
                setContent(review.content);
                setIsPrivate(review.isPrivate);
            })
            .catch(err => console.error('err', err))
    }, [])

    const handleChange = (tag: string, checked: boolean) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!wellnessLectureId) return;
        if (!wellnessLectureReviewId) return message.error("리뷰가 존재하지 않습니다.");
        if (!rating) return message.error("별점을 입력해주세요.");
        if (!content) return message.error("후기를 작성해주세요.");

        const params = {
            id: wellnessLectureReviewId,
            rating: rating,
            content: content,
            wellnessLectureId: Number(wellnessLectureId),
            reviewCategoryList: selectedTags,
            imageUrlList: uploadedUrls,
            isPrivate: isPrivate
        }

        updateWellnessLectureReview(params)
            .then(res => {
                if (res.data === true) {
                    navigate(-1);
                }
                else {
                    message.error('서버에서 에러가 발생했습니다.');
                }
            })
            .catch(err => console.error('err', err))
    }

    const clickDeleteButton = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!wellnessLectureReviewId) return message.error("리뷰가 존재하지 않습니다.");
        deleteWellnessLectureReview(wellnessLectureReviewId.toString())
            .then(res => {
                if (res.data === true) {
                    navigate(-1);
                }
                else {
                    message.error('서버에서 에러가 발생했습니다.');
                }
            })
            .catch(err => console.error('err', err))
    }

    return <>
        <TopBar title="리뷰 확인" rightIcon={false} />
        <div style={{ padding: 20 }}>
            {wellnessLecture && <>
                <div>
                    <Flex style={{ padding: 12, alignItems: 'center', border: '1px solid #E8EBED', borderRadius: 4 }} gap={16}>
                        <div style={{ width: 56, height: 56 }}>
                            <img src={wellnessLecture.wellnessLectureImageUrl || `${process.env.PUBLIC_URL}/assets/LectureDetailDefault.jpg`} alt="수업사진" width={'100%'} style={{ borderRadius: "999px", aspectRatio: 1, objectFit: 'cover' }} />
                        </div>
                        <div>
                            <div style={{ fontSize: 16, lineHeight: '26px', fontWeight: 600 }}>{wellnessLecture.wellnessLectureName}</div>
                            <div style={{ fontSize: 12, lineHeight: '20px' }}>{dayjs(wellnessLecture.wellnessLectureStartDateTime).format('YYYY.MM.DD (dd) HH:mm')} - {dayjs(wellnessLecture.wellnessLectureEndDateTime).format('HH:mm')} | {wellnessLecture.yogaCenterName}</div>
                            <div style={{ fontSize: 12, lineHeight: '20px' }}>{wellnessLecture.teacherName} 코치</div>
                        </div>
                    </Flex>

                    <div style={{ marginTop: 32, textAlign: 'center' }}>
                        <div style={{ color: '#828F9B', fontSize: 14 }}>별점</div>
                        <div style={{ marginTop: 24 }}>
                            <Rate
                                disabled={isDisabled}
                                style={{ fontSize: 40 }}
                                value={rating}
                                onChange={(value) => setRating(value)} />
                        </div>
                    </div>

                    <div style={{ marginTop: 32 }}>
                        <div style={{ color: '#828F9B', fontSize: 14, textAlign: 'center' }}>오늘 수업은 어땠나요?</div>
                        <div style={{ marginTop: 24 }}>
                            {reviewCategoryList.map<React.ReactNode>((tag) => (
                                <Tag.CheckableTag
                                    key={tag.id}
                                    checked={selectedTags.includes(tag.title)}
                                    onChange={(checked) => !isDisabled && handleChange(tag.title, checked)}
                                    style={{
                                        border: selectedTags.includes(tag.title) ? '1px solid #369AFF' : '1px solid #D2D6DB',
                                        backgroundColor: selectedTags.includes(tag.title) ? '#e6f7ff' : 'white',
                                        color: 'black',
                                        marginTop: 16, marginRight: 16,
                                        pointerEvents: isDisabled ? 'none' : 'auto'
                                    }}
                                >
                                    <div style={{ padding: '4px 5px', lineHeight: '24px', fontSize: 14, fontWeight: 600 }}>{tag.title}</div>
                                </Tag.CheckableTag>
                            ))}
                        </div>
                    </div>

                    <div style={{ marginTop: 32 }}>
                        <TextArea
                            showCount
                            maxLength={300}
                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => !isDisabled && setContent(e.target.value)}
                            placeholder="이번 수업에 대한 의견을 남겨주세요."
                            value={content}
                            style={{ height: 88, resize: 'none' }}
                        />
                    </div>

                    <div style={{ marginTop: 32 }}>
                        <ImageUploader isDisabled={isDisabled} setUploadedUrls={setUploadedUrls} />
                    </div>

                    <div style={{ marginTop: 32, color: '#828F9B', fontSize: 14 }}>
                        <Checkbox checked={isPrivate} onChange={(e) => setIsPrivate(e.target.checked)} disabled={isDisabled}>비공개로 작성할래요</Checkbox>
                        <br />
                        <div style={{ marginLeft: 25 }}>작성하신 후기는 강사님께 전달되지 않습니다.</div>
                    </div>

                    <div style={{ marginTop: 24 }}>
                        <Flex gap={12}>
                            <Button type="primary"
                                style={{ width: '100%', height: '50px', fontSize: 14, fontWeight: 600 }}
                                onClick={handleClick}
                                disabled={isDisabled}
                            >{isDisabled ? '수정 기간이 끝났습니다.' : '수정 완료'}</Button>
                            <Button
                                danger
                                style={{ height: '50px', fontSize: 14, fontWeight: 600 }}
                                disabled={isDisabled}
                                onClick={() => setIsOpenDeleteModal(true)}
                            ><DeleteOutlined /></Button>
                        </Flex>

                    </div>
                    <br /><br /><br />
                </div>
            </>}
        </div >
        <Modal
            open={isOpenDeleteModal}
            closable={false}
            centered
            onOk={() => console.log('ok')}
            onCancel={() => setIsOpenDeleteModal(false)}
            footer={[
                <Flex key="footer-buttons" style={{ marginTop: '25px' }} gap={12}>
                    <Button
                        key="back"
                        onClick={() => setIsOpenDeleteModal(false)}
                        style={{ width: '50%', height: '50px', fontSize: '16px', fontWeight: '600', backgroundColor: 'white', color: 'black' }}>
                        돌아가기
                    </Button>
                    <Button
                        type="primary"
                        key="submit"
                        danger
                        onClick={clickDeleteButton}
                        style={{ width: '50%', height: '50px', fontSize: '16px', fontWeight: '600', margin: 0, borderWidth: '1px' }}>
                        삭제하기
                    </Button>
                </Flex>
            ]}
        >
            <div>
                <div className="sub-title/st4">리뷰를 삭제하시겠습니까?</div>
                <div className="body/b2 secondary/s800" style={{ marginTop: 8 }}>삭제 후 복구가 불가합니다.</div>
            </div>
        </Modal>

    </>
}

export default ViewReview;
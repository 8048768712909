import dayjs from "dayjs";

interface IProps {
    notification: INotification;
    onClick?: Function;
}

const NotificationAllCard = ({ notification, onClick }: IProps) => {
    function removeLeadingZeros(dateString: string) {
        return dateString.replace(/\b0(\d)\b/g, '$1');
    }


    return <>
        <div style={{ display: "flex", justifyContent: "space-between", gap: 'var(--space8)', cursor: 'pointer', marginBottom: 'var(--space8)', paddingBlock: 'var(--space12)' }} onClick={() => onClick?.()}>
            <div>
                <div className={"sub-title/st2 " + ((notification.isNotificationV2 && !notification.isReceipt) ? 'base/black' : 'secondary/s400')}>
                    {notification.title}
                </div>
                <div className={"body/b2 " + ((notification.isNotificationV2 && !notification.isReceipt) ? 'base/black' : 'secondary/s400')} style={{ marginTop: 'var(--space2)' }}>
                    {notification.content}
                </div>
            </div>
            <div className={"caption/c1 " + ((notification.isNotificationV2 && !notification.isReceipt) ? 'secondary/s500' : 'secondary/s400')}
                style={{ minWidth: '60px', textAlign: 'right' }}>
                {removeLeadingZeros(dayjs(notification.createdDate).format("MM월 DD일"))}
            </div>
        </div>
    </>
}
export { NotificationAllCard };
import { createReducer } from 'typesafe-actions';
import { InitYogaCenterSelectorState, InitYogaCenterSelectorAction } from './types';
import {
    GET_INITYOGACENTERSELECTOR,
    GET_INITYOGACENTERSELECTOR_SUCCESS,
    GET_INITYOGACENTERSELECTOR_ERROR
} from './actions';


const initialState: InitYogaCenterSelectorState = {
    initYogaCenterSelector: {
        loading: false,
        error: null,
        data: { isFirstTime: true }
    }
};

const initYogaCenterSelector = createReducer<InitYogaCenterSelectorState, InitYogaCenterSelectorAction>(initialState, {
    [GET_INITYOGACENTERSELECTOR]: state => ({
        ...state,
        initYogaCenterSelector: {
            loading: true,
            error: null,
            data: { isFirstTime: true }
        }
    }),
    [GET_INITYOGACENTERSELECTOR_SUCCESS]: (state, action) => ({
        ...state,
        initYogaCenterSelector: {
            loading: false,
            error: null,
            data: action.payload
        }
    }),
    [GET_INITYOGACENTERSELECTOR_ERROR]: (state, action) => ({
        ...state,
        initYogaCenterSelector: {
            loading: false,
            error: action.payload,
            data: { isFirstTime: true }
        }
    }),
});

export default initYogaCenterSelector;
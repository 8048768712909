import { AxiosResponse } from 'axios';
import api from './Api'

interface IParmas {
    eventCouponId: string
    memberId: string
    tossPayId: string
}
export const createEventCouponHistory = (params: IParmas): Promise<AxiosResponse<any, any>> => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/eventCouponHistory`, params);
}
export const wellnesTicketFrequencyUnitToKr = (frequencyUnit?: string) => {
    const frequencyUnitMap: { [key: string]: string } = {
        'DAY': '일',
        'WEEK': '주',
        'MONTH': '월',
        'YEAR': '연',
        'NOLIMIT': '-',
    };

    if (!frequencyUnit) return frequencyUnit;
    return frequencyUnitMap[frequencyUnit] ?? frequencyUnit;
}

export const wellnesTicketTypeToKr = (frequencyUnit?: string) => {
    const frequencyUnitMap: { [key: string]: string } = {
        "COUNT": "횟수",
        "PERIOD": "기간",
    };

    if (!frequencyUnit) return frequencyUnit;
    return frequencyUnitMap[frequencyUnit] ?? frequencyUnit;
}

export const wellnesTicketDiscountTypeToKr = (frequencyUnit?: string) => {
    const frequencyUnitMap: { [key: string]: string } = {
        "PERCENT": "비율",
        "FIXED": "고정",
    };

    if (!frequencyUnit) return frequencyUnit;
    return frequencyUnitMap[frequencyUnit] ?? frequencyUnit;
}
import { ReactComponent as ArrowRight } from "../../../assets/images/icon/arrow-right.svg"
import { ReactComponent as ArrowDown } from "../../../assets/images/icon/arrow-down.svg"
interface IProps {
    type?: "default" | "underline";
    icon?: boolean;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    onClick?: Function;
    textDecorationColor?: string;
    rightIcon?: 'arrowRight' | 'arrowDown';
}

const TextBtn = ({ type, icon = true, rightIcon, style, children, onClick, textDecorationColor }: IProps) => {
    const getStyleByType = () => {
        if (type === 'underline') {
            return {
                textDecoration: 'underline',
                textDecorationColor: textDecorationColor ?? 'var(--secondarys600)',
                textUnderlineOffset: '3px',
            }
        }
    }
    const getRightIcon = (rightIcon?: string) => {
        switch (rightIcon) {
            case 'arrowRight':
                return <ArrowRight width={16} height={16} stroke={'#737373'} />;
            case 'arrowDown':
                return <ArrowDown width={16} height={16} stroke={'#737373'} />;
            default:
                return <ArrowRight width={16} height={16} stroke={'#737373'} />;
        }
    }

    return <>
        <div className="btn/text-btn secondary/s600" style={{
            display: 'inline-block',
            cursor: 'pointer',
            ...style
        }}
            onClick={() => onClick && onClick()}
        >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 'var(--space4)', padding: 'var(--space4) 1px var(--space4) var(--space4)' }}>
                <div style={{ ...getStyleByType(), }}>{children}</div>
                {icon && getRightIcon(rightIcon)}
            </div>
        </div>
    </>
}

export { TextBtn };
import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { InitYogaCenterSelectorAction } from './types';
import { getInitYogaCenterSelectorAsync } from './actions';

export function updateInitYogaCenterSelectorThunk(isFirstTime: boolean): ThunkAction<void, RootState, null, InitYogaCenterSelectorAction> {
    return async dispatch => {
        const { request, success, failure } = getInitYogaCenterSelectorAsync;
        try {
            dispatch(success({ isFirstTime }));
        } catch (e: any) {
            dispatch(failure(e));
        }
    }
}
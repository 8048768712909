import { Helmet } from "react-helmet-async";
interface IProps {
    title: string;
    imgUrl: string;
    description: string;
}
const TagHead = ({ title, imgUrl, description }: IProps) => {
    return <>
        <Helmet>
            <title>{title ?? ''}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title ?? ''} />
            <meta property="og:image" content={imgUrl ?? ''} />
            <meta property="og:description" content={description} />
        </Helmet>
    </>
}

export default TagHead;

import { ConfigProvider } from 'antd';
import './App.css'
import './design-token-style.css'

import Routes from './routes'

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          controlHeightLG: 44,
          colorPrimary: '#000',
          lineWidth: 0.1,
          fontFamily: 'PretendardVariable, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif'
        },
        components: {
          Popover: {
            minWidth: 250,
          },
          Checkbox: {
            colorPrimary: '#000',
            colorPrimaryHover: '#000',
            borderRadiusSM: 2
          },
          Radio: {
            colorPrimary: '#000',
            buttonCheckedBg: '#000',
            buttonSolidCheckedColor: 'yellow',
            buttonSolidCheckedHoverBg: '#000',
            borderRadius: 0,
            buttonSolidCheckedActiveBg: '#000'
          },
          Tabs: {
            itemSelectedColor: '#000',
            inkBarColor: '#000',
            horizontalItemGutter: 0,
            itemActiveColor: '#000',
            itemHoverColor: '#000'
          },
          Carousel: {
            dotWidth: 100,
            dotActiveWidth: 100
          },
        },
      }}>
      <Routes />
    </ConfigProvider>
  );
};

export default App;
import { Divider, Flex, Skeleton } from "antd"
import { useMyContext } from "../../../../entities/context";

const PayInfoSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<div style={{ position: 'absolute', maxWidth: "480px", backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), width: '100%' }}>
        <div style={{ padding: '0 24px 118px' }}>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
                <div style={{ width: '215px', display: 'inline-block' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
                </div>
            </div>
            <div>
                <div style={{ marginTop: '24px' }}>
                    <div style={{ height: '135px', padding: '22px 20px 0 20px', backgroundColor: 'black', color: 'white' }}>

                        <div style={{ width: '100%' }}>
                            <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={12}>
                                <div style={{ width: '100%' }}>
                                    <div style={{ backgroundColor: 'white', borderRadius: '4px' }}>
                                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                                    </div>
                                </div>
                                <div style={{ backgroundColor: 'white', borderRadius: '4px' }}>
                                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 30 }} />
                                </div>
                            </Flex>
                        </div>

                        <div style={{ width: '100%', marginTop: '30px' }}>
                            <div style={{ width: '160px', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 34 }} />
                            </div>
                        </div>
                        <div style={{ width: '100%', marginTop: '4px' }}>
                            <div style={{ width: '160px', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingTop: '14px', backgroundColor: 'black', position: 'relative' }}>
                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                            <div style={{ width: '15px', height: '15px', backgroundColor: 'white', borderTopRightRadius: '100px' }}></div>
                        </div>
                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <div style={{ width: '15px', height: '15px', backgroundColor: 'white', borderTopLeftRadius: '100px' }}></div>
                        </div>
                    </div>

                    <div style={{ padding: '40px 20px', backgroundColor: 'white', position: 'relative', boxShadow: '0px 4px 8px #F4F4F4' }}>
                        <div style={{ position: 'absolute', top: 0, left: 0 }}>
                            <div style={{ width: '15px', height: '15px', backgroundColor: 'white', borderBottomRightRadius: '100px' }}></div>
                        </div>
                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <div style={{ width: '15px', height: '15px', backgroundColor: 'white', borderBottomLeftRadius: '100px' }}></div>
                        </div>
                        <div>
                            <div style={{ marginBottom: '28px', fontSize: '16px', fontWeight: '700' }}>
                                <div style={{ width: '100%', marginTop: '4px' }}>
                                    <div style={{ width: '215px', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                    </div>
                                </div>

                                <div style={{ width: '100%', marginTop: 32 }}>
                                    <Flex gap={16}>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                    </Flex>
                                </div>
                                <Divider style={{ margin: "13px 0" }} />
                                <div style={{ width: '100%' }}>
                                    <Flex gap={16}>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                    </Flex>
                                </div>
                                <Divider style={{ margin: "13px 0" }} />
                                <div style={{ width: '100%' }}>
                                    <Flex gap={16}>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                    </Flex>
                                </div>

                                <div style={{ width: '100%', marginTop: '52px' }}>
                                    <div style={{ width: '215px', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                    </div>
                                </div>

                                <div style={{ width: '100%', marginTop: 32 }}>
                                    <Flex gap={16}>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                    </Flex>
                                </div>
                                <Divider style={{ margin: "13px 0" }} />
                                <div style={{ width: '100%' }}>
                                    <Flex gap={16}>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                    </Flex>
                                </div>
                                <Divider style={{ margin: "13px 0" }} />
                                <div style={{ width: '100%' }}>
                                    <Flex gap={16}>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                    </Flex>
                                </div>

                                <div style={{ width: '100%', marginTop: '52px' }}>
                                    <div style={{ width: '215px', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                    </div>
                                </div>

                                <div style={{ width: '100%', marginTop: 32 }}>
                                    <Flex gap={16}>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                    </Flex>
                                </div>
                                <Divider style={{ margin: "13px 0" }} />
                                <div style={{ width: '100%' }}>
                                    <Flex gap={16}>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                    </Flex>
                                </div>
                                <Divider style={{ margin: "13px 0" }} />
                                <div style={{ width: '100%' }}>
                                    <Flex gap={16}>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                    </Flex>
                                </div>

                                <div style={{ width: '100%', marginTop: '52px' }}>
                                    <div style={{ width: '215px', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                    </div>
                                </div>

                                <div style={{ width: '100%', marginTop: 32 }}>
                                    <Flex gap={16}>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                    </Flex>
                                </div>
                                <Divider style={{ margin: "13px 0" }} />
                                <div style={{ width: '100%' }}>
                                    <Flex gap={16}>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                    </Flex>
                                </div>
                                <Divider style={{ margin: "13px 0" }} />
                                <div style={{ width: '100%' }}>
                                    <Flex gap={16}>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                        <div style={{ width: '100%', display: 'inline-block', backgroundColor: 'white', borderRadius: '4px' }}>
                                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                                        </div>
                                    </Flex>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default PayInfoSkeleton;
import { Flex } from "antd"
import MainHeader from "../../../layout/MainLayout/header"
import { useNavigate } from "react-router-dom"
import { ReactComponent as ArrowRight } from "../../../assets/images/icon/arrow-right.svg"
import { TopBar } from "../../../widgets"

const PrivateClassApply = () => {
    const navigate = useNavigate();

    return (
        <>
            <TopBar title="프라이빗 클래스 신청" rightIcon={false} />
            <div style={{ padding: '24px 20px' }}>
                <div style={{ fontWeight: 700, fontSize: '16px', lineHeight: '20px' }}>웰니스 프로그램을 선택해 주세요</div>
                <div
                    onClick={() => navigate('/private/class/apply/form', { state: { programName: 'Positive MORNING' } })}
                    style={{ marginTop: '32px', padding: '16px', border: '1px solid #D9D9D9', borderRadius: '4px', cursor: 'pointer' }}>
                    <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <div style={{ fontWeight: 600, lineHeight: '20px', fontSize: '16px' }}>Positive MORNING</div>
                            <div style={{ marginTop: '12px', fontSize: '14px', lineHeight: '24px' }}>밤새 굳어있던 몸을 깨우고 나에게 온전히 <br />집중하며 마음을 함께 다스립니다.</div>
                        </div>
                        <ArrowRight width={24} height={24} stroke="#000000" />
                    </Flex>
                </div>

                <div
                    onClick={() => navigate('/private/class/apply/form', { state: { programName: 'Positive RECOVERY' } })}
                    style={{ marginTop: '32px', padding: '16px', border: '1px solid #D9D9D9', borderRadius: '4px', cursor: 'pointer' }}>
                    <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <div style={{ fontWeight: 600, lineHeight: '20px', fontSize: '16px' }}>Positive RECOVERY</div>
                            <div style={{ marginTop: '12px', fontSize: '14px', lineHeight: '24px' }}>몸의 균형에 집중하여 스트레스와 긴장을<br />완화하여 회복을 촉진합니다.</div>
                        </div>
                        <ArrowRight width={24} height={24} stroke="#000000" />
                    </Flex>
                </div>

                <div
                    onClick={() => navigate('/private/class/apply/form', { state: { programName: 'Positive ENERGY' } })}
                    style={{ marginTop: '32px', padding: '16px', border: '1px solid #D9D9D9', borderRadius: '4px', cursor: 'pointer' }}>
                    <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <div style={{ fontWeight: 600, lineHeight: '20px', fontSize: '16px' }}>Positive ENERGY</div>
                            <div style={{ marginTop: '12px', fontSize: '14px', lineHeight: '24px' }}>다이내믹한 움직임으로 몸에 긍정적인 에너지를<br />끌어올립니다.</div>
                        </div>
                        <ArrowRight width={24} height={24} stroke="#000000" />
                    </Flex>
                </div>
            </div>
        </>
    )
}

export default PrivateClassApply
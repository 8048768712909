const formatPhoneNumber = (value: string) => {
    const phoneNumber = value.replace(/\D/g, '');
    if (phoneNumber.length < 4) {
        return phoneNumber;
    } else if (phoneNumber.length < 8) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}`;
    } else {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7, 11)}`;
    }
};

export { formatPhoneNumber }
import { Modal } from 'antd';
import { ReactComponent as Close } from '../../../assets/images/icon/close.svg';

interface IProps {
    isOpen: boolean,
    setIsOpen: Function,
    member: IMember,
}
const BarcodeWithNameFullScreen = ({ isOpen, setIsOpen, member }: IProps) => {
    return <Modal
        open={isOpen}
        getContainer={document.body}
        footer={null}
        closeIcon={null}
        styles={{ content: { padding: 0 }, body: { height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' } }}
        style={{ top: 0, padding: 0, maxWidth: '100%' }}
    >
        <div style={{ transform: "rotate(90deg)" }}>
            <div style={{ textAlign: 'right' }}>
                <Close style={{ cursor: "pointer" }} width={24} height={24} stroke="var(--secondarys500)" onClick={() => setIsOpen(false)} />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", padding: '0 50px' }}>
                <div style={{ fontSize: "24px", fontWeight: "700", lineHeight: "28.64px" }}>
                    {member.name} 님
                </div>
                <div className='body/b2'>
                    {member.mobile.slice(0, 3) + "-" + member.mobile.slice(3, 5) + "**" + "-" + member.mobile.slice(7, 9) + "**"}
                </div>
            </div>
            <img style={{ width: "100%", paddingBlock: "16px" }} src={member ? member?.barcodeImageUrl : ""} alt="barcode" />
        </div>
    </Modal>
}

export { BarcodeWithNameFullScreen }
import { ReactComponent as ArrowRight } from "../../../assets/images/icon/arrow-right.svg";

interface IProps {
    title: string;
    description: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
const TicketSelectCard = ({ title, description, onClick }: IProps) => {
    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white', padding: 'var(--space20) var(--space16)', border: '1px solid var(--secondarys300)', borderRadius: 'var(--radius4)', marginBottom: '24px', cursor: 'pointer' }} onClick={onClick}>
        <div style={{ fontSize: '16px', fontWeight: '500', lineHeight: '26px' }}>
            <div className="sub-title/st3 base/black" style={{ whiteSpace: 'pre-wrap' }}>{title}</div>
            <div className="body/b2 secondary/s700" style={{ whiteSpace: 'pre-wrap', marginTop: 'var(--space20)' }}>
                {description}
            </div>
        </div>
        <ArrowRight width={24} height={24} stroke="#000000" style={{ marginLeft: 'var(--space16)' }} />
    </div>
}

export { TicketSelectCard }
import { Flex } from "antd"
import { BottomSheet } from "../../../../../shared"
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Barcode } from "../../../../../assets/images/icon/barcode.svg"

interface IProps {
    couponList: Array<IssuedCoupon>;
}

const CouponTab = ({ couponList }: IProps) => {
    const navigate = useNavigate();
    const [isShowBottomSheet, setIsShowBottomSheet] = useState<boolean>(false);
    const [couponFilter, setCouponFilter] = useState<string>('사용가능');

    const issuedCouponList = couponList.filter((coupon) => {
        return couponFilter === '사용가능'
            ? !coupon.isDelete && !coupon.isUsed
            : couponFilter === '완료만료'
                ? coupon.isDelete || coupon.isUsed
                : false;
    });

    return (
        <div>
            <div style={{ padding: '24px 20px' }}>
                <Flex justify="space-between">
                    <div className="sub-title/st3">전체 {issuedCouponList.length}개</div>
                    <div>
                        <BottomSheet
                            isOpen={isShowBottomSheet}
                            setIsOpen={setIsShowBottomSheet}
                            dataList={[
                                { id: 0, label: '사용 가능', value: '사용가능' },
                                { id: 1, label: '완료・만료', value: '완료만료' },
                            ]}
                            value={couponFilter}
                            setValue={(value: string) => setCouponFilter(value)}
                            isShowLabel={true}
                        />
                    </div>
                </Flex>
                {issuedCouponList.length > 0 ? (
                    <div style={{ marginTop: 24 }}>
                        {issuedCouponList.map((data: IssuedCoupon) => {
                            const statusLabelStyle = {
                                padding: '4px 8px',
                                backgroundColor: 'var(--secondarys100)',
                                display: 'inline-block',
                                marginTop: 8,
                            };

                            return <Fragment key={data.id}>
                                <div
                                    style={{
                                        opacity: (data.isUsed || data.isDelete) ? 0.48 : 1,
                                        display: "flex", justifyContent: "space-between", alignItems: "center", borderRadius: "4px", cursor: 'pointer',
                                        border: '1px solid var(--secondarys200)',
                                        marginBottom: '20px'
                                    }} onClick={() => navigate(`/my/${data.couponDto?.couponType ?? 'issued-coupon'}/${data.id}`)}>
                                    <div style={{ padding: '16px' }}>

                                        <div className="body/b1">
                                            {data.couponDto?.name}
                                        </div>
                                        <div className="sub-title/st4" style={{ marginTop: 2 }}>
                                            {data.couponDto?.shortDescription}
                                        </div>

                                        {data.isUsed ? (
                                            <div className="caption/c1" style={statusLabelStyle}>사용완료</div>
                                        ) : data.isDelete ? (
                                            <div className="caption/c1" style={statusLabelStyle}>기간만료</div>
                                        ) : (
                                            <div className="caption/c1 secondary/s800" style={{ marginTop: 16 }}>
                                                {`${data.expireDate?.slice(0, 4)}년 ${data.expireDate?.slice(5, 7)}월 ${data.expireDate?.slice(8, 10)}일까지`}
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ textAlign: "center", borderLeft: "1px dotted #DDDDDD", padding: "43px 20px" }}  >
                                        <Barcode />
                                    </div>
                                </div>
                            </Fragment>
                        })}
                    </div>
                ) : (
                    <div className="body/b2 secondary/s600" style={{ marginTop: 24, textAlign: 'center' }}>{
                        couponFilter === '사용가능'
                            ? '사용가능한 쿠폰이 없습니다.'
                            : couponFilter === '완료만료'
                                ? '완료・만료된 쿠폰이 없습니다'
                                : '쿠폰 이력이 없습니다.'}</div>
                )}
            </div>
        </div>
    )
}

export { CouponTab }
import { ReactComponent as Warning } from '../../../assets/images/icon/warning.svg';
import { Modal } from "antd";
import { BarcodeWithNameFullScreen } from '../../';
import { useState } from 'react';

interface IProps {
    isOpen: boolean;
    setIsOpen: Function;
    member: IMember;
}

const BarcodeBasicModal = ({ isOpen, setIsOpen, member }: IProps) => {
    const [isOpenBarcodeWithNameFullScreenModal, setIsOpenBarcodeWithNameFullScreenModal] = useState<boolean>(false);
    return <>
        <Modal
            open={isOpen}
            closeIcon={false}
            centered
            styles={{ content: { padding: "var(--space20) var(--space16)", backgroundColor: "#FFFFFF", borderRadius: 'var(--radius4)' } }}
            onCancel={() => setIsOpen(false)}
            footer={null}
        >
            <div onClick={() => { setIsOpenBarcodeWithNameFullScreenModal(true); }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: "40" }}>
                    <div style={{ display: 'flex', alignItems: "flex-end" }}>
                        <div style={{ fontSize: "24px", fontWeight: "700", lineHeight: "28.64px" }}>
                            {member.name} 님
                        </div>
                    </div>
                    <div className='body/b2'>
                        {member.mobile.slice(0, 3) + "-" + member.mobile.slice(3, 5) + "**" + "-" + member.mobile.slice(7, 9) + "**"}
                    </div>
                </div>
                <img style={{ width: "100%", paddingBlock: "var(--space16)", lineHeight: "22.4px" }} src={member.barcodeImageUrl} alt="barcode" />
                <div style={{ display: "flex", borderRadius: "5px", gap: "12px", alignItems: "center" }}>
                    <Warning width={16} height={16} fill="#7D7D7D" />
                    <div style={{ textAlign: "left", fontSize: "16px", fontWeight: "500", letterSpacing: "-0.4px", color: "#7D7D7D" }}>체크인할 때 컨시어지에 바코드를 보여주세요</div>
                </div>
            </div>
        </Modal>
        {member && <BarcodeWithNameFullScreen isOpen={isOpenBarcodeWithNameFullScreenModal} setIsOpen={setIsOpenBarcodeWithNameFullScreenModal} member={member} />}
    </>
}

export { BarcodeBasicModal }
import { AxiosResponse } from 'axios';
import api from './Api'

export const getPrivateClassInquiryList = (): Promise<AxiosResponse<Array<IPrivateClassInquiry>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/private/class/inquiry`);
}

export const getPrivateClassInquiryById = (id: string): Promise<AxiosResponse<IPrivateClassInquiry, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/private/class/inquiry/${id}`);
}

export const createPrivateClassInquiry = (body: IPrivateClassInquiryRequest): Promise<AxiosResponse<IPrivateClassInquiry, any>> => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/private/class/inquiry`, body);
}

export const updatePrivateClassInquiry = (id: string, body: IPrivateClassInquiryRequest): Promise<AxiosResponse<IPrivateClassInquiry, any>> => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/private/class/inquiry/${id}`, body);
}

export const cancelPrivateClassInquiry = (id: string, status: string): Promise<AxiosResponse<IPrivateClassInquiry, any>> => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/private/class/inquiry/${id}`, { status: status });
}

import { ReactComponent as Copy } from "../../assets/images/icon/copy.svg";
import { ReactComponent as Phone } from "../../assets/images/icon/phone.svg";
import { ReactComponent as Location } from "../../assets/images/icon/location.svg";
import { kakaoMapNative } from "../../utils";
import { message } from "antd";
import { SolidBtn, InlineTitleAndContent } from "../../shared";
interface IProps {
    yogaCenterDetail: IYogaCenterDetail;
    style?: React.CSSProperties;
}
const YogaCenterInfo = ({ yogaCenterDetail, style }: IProps) => {
    return <div style={style}>
        <div className="body/b1 secondary/s700">YOGA & KITCHEN</div>
        <div className="title/t3 base/black" style={{ marginTop: 'var(--space8)' }}>{yogaCenterDetail.shortDescription}</div>
        <div className="body/b2 secondary/s700" style={{ whiteSpace: 'pre-wrap', marginTop: 'var(--space16)' }}>{yogaCenterDetail.description}</div>

        <div style={{ backgroundColor: 'var(--secondarys100)', padding: 'var(--space12)', borderRadius: 'var(--radius4)', marginBlock: 'var(--space20)' }}>
            <InlineTitleAndContent title={"지점위치"} content={yogaCenterDetail.roadNameAddress} />
            <span className="btn/text-btn secondary/s600" style={{ marginLeft: '62px' }}
                onClick={() => { navigator.clipboard.writeText(yogaCenterDetail.roadNameAddress); message.success("복사완료") }}>
                <Copy width={"16px"} height="16px" fill="#737373" style={{ verticalAlign: 'middle' }} />복사
            </span>
            <InlineTitleAndContent title={"전화번호"} content={yogaCenterDetail.contactNumber} styles={{ container: { marginTop: 'var(--space8)' } }} />
            <InlineTitleAndContent title={"주차위치"} content={yogaCenterDetail.parkingInfo} styles={{ container: { marginTop: 'var(--space8)' } }} />
            {yogaCenterDetail.valetInfo && <InlineTitleAndContent title={"발렛안내"} content={yogaCenterDetail.valetInfo} styles={{ container: { marginTop: 'var(--space8)' } }} />}
        </div>

        <div style={{ display: "flex" }}>
            <SolidBtn
                style={{ width: 'calc((100% - var(--space16))/2)', borderColor: 'var(--secondarys500)', color: 'var(--secondarys500)' }}
                children={'전화하기'} type='line'
                customIcon={<Phone width={16} height={16} fill="#7D7D7D" />}
                onClick={() => window.location.href = "tel://" + yogaCenterDetail.contactNumber} />
            <SolidBtn
                style={{ width: 'calc((100% - var(--space16))/2)', borderColor: 'var(--secondarys500)', color: 'var(--secondarys500)', marginLeft: 'var(--space16)' }}
                children={'길찾기'} type='line'
                customIcon={<Location width={16} height={16} fill="#7D7D7D" />}
                onClick={() => kakaoMapNative(`https://map.kakao.com/link/map/${yogaCenterDetail.shortDescription},${yogaCenterDetail.lon},${yogaCenterDetail.lat}`)} />
        </div>
    </div>
}
export { YogaCenterInfo }
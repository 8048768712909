import dayjs from "dayjs";
import { ReactComponent as CircleCheck } from "../../../../assets/images/icon/circle-check.svg";

interface IProps {
    issuedYogaCoupon?: IAvailableIssuedYogaCoupon;
    isSelect?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

}
const IssuedYogaCouponCard = ({ issuedYogaCoupon, isSelect = false, onClick }: IProps) => {
    return <div style={{ padding: 'var(--space16)', borderRadius: 'var(--radius4)', marginBottom: 'var(--space20)', border: `1px solid ${isSelect ? 'var(--baseblack)' : 'var(--secondarys300)'}` }
    } onClick={onClick} >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
                <div className="body/b2 base/black">{issuedYogaCoupon?.yogaCouponShortDescription}</div>
                <div className="title/t2 base/black" style={{ marginTop: 4 }}>{issuedYogaCoupon?.yogaCouponName}</div>
                <div className="caption/c1 secondary/s700" style={{ marginTop: 12 }}>{dayjs(issuedYogaCoupon?.issuedYogaCouponExpireDate).format("YYYY년 MM월 DD일까지")}</div>
            </div>
            <CircleCheck width={20} height={20} stroke={isSelect ? "var(--basewhite)" : "var(--secondarys400)"} fill={isSelect ? "var(--baseblack)" : "var(--basewhite)"} />
        </div>
    </div >
}

export { IssuedYogaCouponCard }
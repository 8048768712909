import { useState } from "react";
import { checkDevAndLocal } from "../../utils";
import { useMyContext } from "../../entities/context";

const ShowEnvBadge = () => {
    const [checkDevAndLocalStatus, setCheckDevAndLocalStatus] = useState<"dev" | "local" | undefined>(checkDevAndLocal());
    const { topHeight } = useMyContext();

    return <>
        {checkDevAndLocalStatus &&
            <div className="title/b2 error/e500"
                style={{ position: 'fixed', top: 0, marginTop: topHeight + 'px', zIndex: 3, width: '38px', backgroundColor: "white", border: "1px solid black", cursor: "pointer", padding: "3px" }}
                onClick={() => setCheckDevAndLocalStatus?.(undefined)}>
                {checkDevAndLocalStatus}
            </div>}
    </>
}
export { ShowEnvBadge }
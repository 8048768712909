import { useEffect, useState } from "react";
import { ReactComponent as BarcodeBlack } from "../../assets/images/icon/barcode-black.svg"
import { ReactComponent as BarcodeWhite } from "../../assets/images/icon/barcode-white.svg"
import { isLogin } from "../../utils";
import { BarcodeBasicModal } from "../../shared";
import { getMemberDataByToken } from "../../service";

interface IProps {
    color?: 'white' | 'black'
    style?: React.CSSProperties;
}

const BarcodeIconWithModal = ({ color, style }: IProps) => {
    const [isOpenBarcodeBasicModal, setIsOpenBarcodeBasicModal] = useState<boolean>(false);
    const [member, setMember] = useState<IMember>();


    useEffect(() => {
        if (!isLogin() || !isOpenBarcodeBasicModal) return;
        if (!member) {
            getMemberDataByToken()
                .then((res) => {
                    setMember(res.data);
                })
                .catch((error) => { console.error('error', error); })
        }
    }, [isOpenBarcodeBasicModal])

    const BarcodeToColor = ({ color }: { color?: 'white' | 'black' }) => {
        switch (color) {
            case 'white':
                return <BarcodeWhite style={{ cursor: 'pointer', ...style }} onClick={() => setIsOpenBarcodeBasicModal(true)} />
            case 'black':
                return <BarcodeBlack style={{ cursor: 'pointer', ...style }} onClick={() => setIsOpenBarcodeBasicModal(true)} />
            default:
                return <BarcodeBlack style={{ cursor: 'pointer', ...style }} onClick={() => setIsOpenBarcodeBasicModal(true)} />
        }
    }

    return <>
        <BarcodeToColor color={color} />
        {member && <BarcodeBasicModal isOpen={isOpenBarcodeBasicModal} setIsOpen={setIsOpenBarcodeBasicModal} member={member} />}
    </>
}

export { BarcodeIconWithModal };
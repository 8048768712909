import Count from "./Count";
import CountWithLimit from "./CountWithLimit";
import Period from "./Period";

interface IProps {
    issuedWellnessTicket: IIssuedWellnessTicketParam
}

interface IIssuedWellnessTicketParam {
    expireDate: string;
    remainingCnt: number;
    name: string;
    startColor: string;
    endColor: string;
    textColor: string;
    type: "COUNT" | "PERIOD";
    frequencyUnit: string;
    frequencyUsableCnt: number;
}


const IssuedWellnessTicket = ({ issuedWellnessTicket }: IProps) => {
    if (issuedWellnessTicket.type === "PERIOD") {
        return <Period issuedWellnessTicket={issuedWellnessTicket} />
    }

    const isLimit = issuedWellnessTicket.frequencyUnit === null || issuedWellnessTicket.frequencyUnit === 'NOLIMIT' || issuedWellnessTicket.frequencyUsableCnt === null;

    if (isLimit) {
        return <Count issuedWellnessTicket={issuedWellnessTicket} />
    } else {
        return <CountWithLimit issuedWellnessTicket={issuedWellnessTicket} />
    }
}

export default IssuedWellnessTicket;
const converteYogaCenter = (conselingBranch: string) => {
    switch (conselingBranch) {
        case "GANGNAM":
            return "강남";
        case "DOSAN":
            return "도산";
        case "SEOLLEUNG":
            return "선릉";
        default:
            return "선릉";
    }
}
const converteReservationStatusText = (status: string): string => {
    switch (status) {
        case 'RESERVATION':
            return '예약';
        case 'CANCELED_RESERVATION':
            return '취소';
        case 'CLASS_BEFORE':
            return '예약';
        case 'CHECK_IN_RESERVATION_CANCELED':
            return '취소';
        case 'CHECK_IN':
            return '출석';
        case 'ABSENT':
            return '결석';
        default:
            return '기타';
    }
};
const converteConselingBranch = (yogaCenterName: string | undefined) => {
    if (!yogaCenterName) return "SEOLLEUNG";
    const nameLength = yogaCenterName?.split(",")?.length
    if (nameLength === 1) {
        switch (yogaCenterName) {
            case "강남":
                return "GANGNAM";
            case "도산":
                return "DOSAN";
            case "선릉":
                return "SEOLLEUNG";
            default:
                return "SEOLLEUNG";
        }
    } else if (nameLength === 2) {
        if ("선릉".includes(yogaCenterName)) {
            return "SEOLLEUNG"
        } else if ("도산".includes(yogaCenterName)) {
            return "DOSAN"
        } else if ("강남".includes(yogaCenterName)) {
            return "GANGNAM"
        }
    }
    return "SEOLLEUNG"
}

export { converteYogaCenter, converteReservationStatusText, converteConselingBranch }
import { useEffect, useState } from "react";
import MySkeleton from "../../components/skeleton/my/mySkeleton";
import { MyBeforeLogin } from "./ui/myBeforeLogin";
import { TopBar } from "../../widgets";
import { IssuedWellnessTicketCarousel, ReservatedProgramList } from "../../features";
import { GnbFooter, MemberNameAndTitle, MemberBenefitInfo, BasicInfoRow } from "../../shared";
import { removeUser, getPremiumMembership, getDosanProgramMember, getMemberByMemberId, getAllReservationByMemberIdAndReservationStatus } from "../../service";
import { isLogin, moneyStringFormatByNumber, isPlatformTeamMember } from "../../utils";
import { Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { getAllIssuedWellnessTicketByMemberId } from "../../service/issuedWellnessTicket";

const My = () => {
    const navigate = useNavigate();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const [member, setMember] = useState<MemberMyWebResponseV2>();
    const [premiumMembership, setPremiumMembership] = useState<IPremiumMembership | undefined>(undefined);
    const [grade, setGrade] = useState<string>('-');
    const [dosanProgramMember, setDosanProgramMember] = useState<IDosanProgramMember | undefined>(undefined);
    const [myWellnessTicketList, setMyWellnessTicketList] = useState<IIssuedWellnessTicketBasicResponseV2[]>([]);
    const [myReservationList, setMyReservationList] = useState<Array<IReservationMyWebResponseV2>>([]);

    const requestIssuedWellnessTicketByMemberId = async () => {
        try {
            const res = await getAllIssuedWellnessTicketByMemberId()
            setMyWellnessTicketList(res.data);
        } catch (error) {
            console.error('error', error);
        }
    }

    const requestReservationByMemberId = async () => {
        await getAllReservationByMemberIdAndReservationStatus('RESERVATION')
            .then((res) => { setMyReservationList(res.data); })
            .catch((error) => { console.error('error', error); })
    }
    const initRequest = async () => {
        try {
            await Promise.all([
                requestIssuedWellnessTicketByMemberId(),
                requestReservationByMemberId(),
                getMemberByMemberId()
                    .then((res) => {
                        setMember(res.data);
                        setGrade(res.data.grade);
                    }),
                getDosanProgramMember()
                    .then((res) => setDosanProgramMember(res.data)),
                getPremiumMembership()
                    .then((res) => setPremiumMembership(res.data)),
            ]);
        } catch (error) {
            console.error('error', error);
            removeUser();
        }
    }
    useEffect(() => {
        if (isLogin()) {
            initRequest()
                .finally(() => setIsInitLoading(false))
        } else {
            setIsInitLoading(false);
        }
    }, [])

    return <>
        {isInitLoading && <MySkeleton />}
        <div style={{ marginBottom: '52px' }}>
            {!member
                ? <>
                    <TopBar title="MY" leftIcon={false} rightIcon={false} />
                    <MyBeforeLogin />
                </>
                : <>
                    <TopBar title="MY" leftIcon={false} rightType='setting' />
                    <div style={{ marginInline: 'var(--space20)', marginTop: 43 }}>
                        <MemberNameAndTitle
                            name={member.name}
                            title={member.isManager ? "매니저님" : member.isTeacher ? '코치님' : '회원님'} />
                        <div style={{ display: 'flex', gap: 'var(--space16)', marginBlock: '24px' }}>
                            <div style={{ width: '50%' }}>
                                <MemberBenefitInfo title="멤버십" value={premiumMembership ? premiumMembership.membershipTitle : grade} to={`/member-ship?grade=${grade === "STANDARD" ? 0 : grade === "DELUXE" ? 1 : 2}`} />
                            </div>
                            <div style={{ width: '50%' }}>
                                <MemberBenefitInfo title="포인트 · 쿠폰" value={moneyStringFormatByNumber(member.totalPoint) + "p"} to="/my/reward" />
                            </div>
                        </div>
                    </div>

                    <IssuedWellnessTicketCarousel myIssuedWellnessTicketList={myWellnessTicketList} />
                    <ReservatedProgramList dosanProgramMember={dosanProgramMember} requestMyReservationByToken={requestReservationByMemberId} requestMyTicketByToken={requestIssuedWellnessTicketByMemberId} myReservationList={myReservationList} memberData={member} />

                    <div style={{ padding: 'var(--space16) var(--space20) 0' }}>
                        {member.isTeacher && <>
                            <BasicInfoRow title={member.isManager ? "모든 수업 확인" : "내 수업 확인"} onClick={() => navigate(`/mylecture/teacher/${member.teacherId}`)} />
                            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                        </>}
                        <BasicInfoRow title={<>수련 내역 & 리뷰</>} onClick={() => navigate('/my/training-history')} />
                        <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                        <BasicInfoRow title={"결제 내역"} onClick={() => navigate("/my/paid-history/" + member.id)} />
                        <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                        <BasicInfoRow title={"정기권 구매"} onClick={() => navigate("/wellness-ticket-group")} />
                        {member.isManager && <>
                            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                            <BasicInfoRow title={"이벤트"} onClick={() => navigate("/event")} />
                        </>}
                        {isPlatformTeamMember(member.mobile) && <>
                            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                            <BasicInfoRow
                                title={window.location.origin === "https://dev.positivehotelyoga.com" ? "운영용 페이지로 이동" : "개발용 페이지로 이동"}
                                onClick={() => window.location.href = window.location.origin === "https://dev.positivehotelyoga.com" ? "https://positivehotelyoga.com" : "https://dev.positivehotelyoga.com"} />
                            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                            {/* <BasicInfoRow title={"도산 초대장"} onClick={() => navigate("/invitation/dosan/program")} /> */}
                        </>}
                    </div>
                </>}
        </div>
        <GnbFooter activeButton='my' />
    </>
}

export { My }
import dayjs, { Dayjs } from "dayjs";

const dateStringFormat = (targetDate: Date) => {
    const year = targetDate.getFullYear();
    const month = targetDate.getMonth() + 1;
    const date = targetDate.getDate();
    const hour = targetDate.getHours();
    const min = targetDate.getHours();

    return `${year}-${month >= 10 ? month : '0' + month}-${date >= 10 ? date : '0' + date} ${hour}:${min}`
}

const dateStringFormatDate = (targetDate: Date) => {
    const year = targetDate.getFullYear();
    const month = targetDate.getMonth() + 1;
    const date = targetDate.getDate();

    return `${year}-${month >= 10 ? month : '0' + month}-${date >= 10 ? date : '0' + date}`
}

const dateKrTime = () => {
    const parsingDate = new Date();
    const utc = parsingDate.getTime() + (parsingDate.getTimezoneOffset() * 60 * 1000);
    const cur_date_korea = new Date(utc + (9 * 60 * 60 * 1000));
    return cur_date_korea;
}

const yesterDay = () => {
    const parsingDate = new Date();
    const utc = parsingDate.getTime() + (parsingDate.getTimezoneOffset() * 60 * 1000);
    const cur_date_korea = new Date(utc + (9 * 60 * 60 * 1000) - (24 * 60 * 60 * 1000));
    return cur_date_korea;
}

const oneYearBefore = () => {
    const parsingDate = new Date();
    const utc = parsingDate.getTime() + (parsingDate.getTimezoneOffset() * 60 * 1000);
    const cur_date_korea = new Date(utc + (9 * 60 * 60 * 1000) - (24 * 60 * 60 * 1000 * 365));
    return cur_date_korea;
}

const dateStringFormatDateKorea = (targetDate: Dayjs) => {
    const year = targetDate.year();
    const month = targetDate.month() + 1;
    const date = targetDate.date();

    return `${year}년 ${month >= 10 ? month : '0' + month}월 ${date >= 10 ? date : '0' + date}일`
}

const lectureDatePeriodFormat = (startDateTime: string, endDateTime: string) => {
    return `${dayjs(startDateTime).format('YYYY-MM-DD (dd)')} ${dayjs(startDateTime).format('HH:mm')} - ${dayjs(endDateTime).format('HH:mm')}`
}

const usableDateFormat = (stringDateTime: string) => {
    return stringDateTime.slice(0, 10).replaceAll("-", ".");
}
const usableDateText = (period: string) => {
    const startDate = dayjs(period.split(' ~ ')[0].replaceAll(".", "-"));
    const endDate = dayjs(period.split(' ~ ')[1].replaceAll(".", "-"));
    const dayDiff = endDate.diff(startDate, "day", true);
    return dayDiff;
}

export { dateStringFormat, dateStringFormatDate, dateKrTime, yesterDay, oneYearBefore, dateStringFormatDateKorea, usableDateFormat, usableDateText, lectureDatePeriodFormat }

import { Divider, Flex, Skeleton } from "antd";
import { useMyContext } from "../../../../entities/context";


const MyResignSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<div style={{ position: 'absolute', maxWidth: "480px", backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), width: '100%' }}>
        <div style={{ padding: '0 20px 118px' }}>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
                <div style={{ width: '215px', display: 'inline-block' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
                </div>
            </div>
            <div style={{ width: '100%', marginTop: '37px' }}>
                <div style={{ marginTop: 12, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
            </div>
            <Divider style={{ margin: "24px 0 14px 0" }} />
            <div style={{ width: '100%' }}>
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={12}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                </Flex>
            </div>
            <Divider style={{ margin: "14px 0" }} />
            <div style={{ width: '100%' }}>
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={12}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                </Flex>
            </div>
            <Divider style={{ margin: "14px 0" }} />
            <div style={{ width: '100%' }}>
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={12}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 24 }} />
                </Flex>
            </div>

            <Divider style={{ margin: "14px 0" }} />

            <div style={{ width: '100%', marginTop: '24px' }}>
                <div style={{ marginTop: 12 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
            </div>
            <div style={{ width: '100%', marginTop: '8px' }}>
                <div style={{ marginTop: 12 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
            </div>
            <div style={{ width: '100%', marginTop: '8px' }}>
                <div style={{ marginTop: 12 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
            </div>
            <div style={{ width: '100%', marginTop: '8px' }}>
                <div style={{ marginTop: 12, width: 160 }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                </div>
            </div>

            <div style={{ width: '100%', maxWidth: 440 }}>
                <div style={{ width: '100%', marginTop: '330px' }}>
                    <div style={{ marginTop: 12 }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 51 }} />
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default MyResignSkeleton;
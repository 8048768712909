import TextArea from "antd/es/input/TextArea"
import MainHeader from "../../../layout/MainLayout/header"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { createGoodInValueInvitationDoasnSurvey } from "../../../service";

const GoodInForm = () => {
    const navigate = useNavigate();
    const [goodInValue, setGoodInValue] = useState<string>('');

    const clickNextButton = () => {
        createGoodInValueInvitationDoasnSurvey(goodInValue)
            .then(res => {
                navigate('/invitation/dosan/program/2')
            }).catch(err => {
                console.error('err', err);
            })
    }

    return (<>
        <MainHeader
            hiddenBackButton
            title={<div style={{ display: 'flex', gap: '8px', justifyContent: 'center', alignItems: 'center', height: '18px' }}>
                <div style={{ width: '70px', height: '2px', backgroundColor: '#000000' }}></div>
                <div style={{ width: '70px', height: '2px', background: '#0000004D' }}></div>
                <div style={{ width: '70px', height: '2px', background: '#0000004D' }}></div>
            </div>}
            hiddenRightIcon />
        <div style={{ padding: '20px' }}>
            <div style={{ textAlign: 'center', fontSize: '28px', fontWeight: '700', lineHeight: '34px' }}>당신의 GOOD IN?
                <br />
                최대 2줄
            </div>
            <div style={{ marginTop: '32px' }}>
                <TextArea
                    value={goodInValue}
                    onChange={(e) => setGoodInValue(e.target.value)}
                    rows={9}
                    placeholder={`가이드 텍스트1 \n 이런 내용을 써주세요 \n 이런 내용을 써주세요`}
                />
            </div>
        </div>

        {/* footer start */}
        <div style={{ position: "fixed", bottom: "0", width: "100%", maxWidth: "480px", textAlign: 'center' }}>
            <div style={{ fontSize: "15px", fontWeight: "700", backgroundColor: "black", color: "#FFFFFF", cursor: "pointer" }}>
                <div style={{ padding: "26px 0" }} onClick={clickNextButton}>다음</div>
            </div>
        </div>
    </>)
}
export default GoodInForm
import { Link } from "react-router-dom"

const NavigateLogin = () => {
    return <>
        <div style={{ marginTop: 20 }}>
            <div style={{ textAlign: 'center', backgroundColor: "var(--secondarys100)", padding: '60px 0' }}>
                <div className="body/b2" style={{ color: "var(--secondarys600)" }}>
                    로그인 후 이용해 주세요.
                </div>
                <div style={{ marginTop: '8px' }}>
                    <Link className="btn/text-btn" to={'/login'} style={{ color: 'var(--secondarys800)', textDecoration: 'underline' }}>
                        로그인 ・ 회원가입하기
                    </Link>
                </div>
            </div>
        </div>
    </>
}

export { NavigateLogin }
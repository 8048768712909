import { Fragment } from "react";
import { DividerThin, NotificationNoticeCard, NotificationAllCard } from "../../../../shared";

const items = (notificationList: INotification[], noticePopupList: INoticePopup[], onClick: Function) => [
    {
        key: 0,
        label: '전체',
        children: <div style={{ padding: "24px var(--space20)" }}>
            {notificationList.length <= 0
                ? <div className="body/b2 secondary/s500" style={{ marginBlock: '50%', textAlign: "center" }}>아직 받은 알림이 없습니다<br />최대 1달 전까지의 알림을 보여드립니다</div >
                : <>
                    {notificationList.map((notification) => <NotificationAllCard key={notification.id} notification={notification} onClick={() => { onClick(notification) }} />)}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingInline: 'var(--space20)', marginTop: '30px' }}>
                        <DividerThin style={{ width: '67.5px' }} />
                        <div className="body/b1 secondary/s300" style={{ width: '100%', textAlign: 'center' }}>
                            최대 1달 전까지의 알림을 보여드립니다
                        </div>
                        <DividerThin style={{ width: '67.5px' }} />
                    </div>
                </>}
        </div>
        ,
    },
    {
        key: 1,
        label: '공지',
        children: <div style={{ padding: "24px var(--space20)" }}>
            {noticePopupList.length <= 0
                ? <div className="body/b2 secondary/s500" style={{ marginBlock: '50%', textAlign: "center" }}>등록된 공지사항이 없습니다</div >
                : noticePopupList.map((noticePopup, idx) => <Fragment key={noticePopup.id}>
                    <NotificationNoticeCard noticePopup={noticePopup} />
                    {noticePopupList.length - 1 !== idx && <DividerThin style={{ marginBlock: 'var(--space4)' }} />}
                </Fragment>)
            }
        </div>,
    },
];


export { items }
import { useNavigate } from "react-router-dom";

const Intro = () => {
    const navigate = useNavigate();
    return <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <div>초대장 이미지 및 내용</div>
        </div>
        <div style={{
            height: '50px',
            width: 'calc(100% - 40px)',
            maxWidth: '440px',
            margin: '25px 20px',
            backgroundColor: 'black',
            color: 'white',
            position: 'fixed',
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px',
            fontSize: '16px',
            fontWeight: '600'
        }}
            onClick={() => navigate('/invitation/dosan/program/1')}
        >GOOD IN BAD OUT 선택</div>
    </>

}

export default Intro;
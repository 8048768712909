const countryCodeList = [
    {
        "label": "South Korea (+82)",
        "value": "82"
    },
    {
        "label": "Greece (+30)",
        "value": "30"
    },
    {
        "label": "Netherlands (+31)",
        "value": "31"
    },
    {
        "label": "Norway (+47)",
        "value": "47"
    },
    {
        "label": "New Zealand (+64)",
        "value": "64"
    },
    {
        "label": "Taiwan (+886)",
        "value": "886"
    },
    {
        "label": "Denmark (+45)",
        "value": "45"
    },
    {
        "label": "Germany (+49)",
        "value": "49"
    },
    {
        "label": "Laos (+856)",
        "value": "856"
    },
    {
        "label": "Latvia (+371)",
        "value": "371"
    },
    {
        "label": "Russia (+7)",
        "value": "7"
    },
    {
        "label": "Lebanon (+961)",
        "value": "961"
    },
    {
        "label": "Romania (+40)",
        "value": "40"
    },
    {
        "label": "Luxembourg (+352)",
        "value": "352"
    },
    {
        "label": "Lithuania (+370)",
        "value": "370"
    },
    {
        "label": "Macau (+853)",
        "value": "853"
    },
    {
        "label": "Macedonia (+389)",
        "value": "389"
    },
    {
        "label": "Malaysia (+60)",
        "value": "60"
    },
    {
        "label": "Malta (+356)",
        "value": "356"
    },
    {
        "label": "USA/Canada (+1)",
        "value": "1"
    },
    {
        "label": "Myanmar (+95)",
        "value": "95"
    },
    {
        "label": "Bahrain (+973)",
        "value": "973"
    },
    {
        "label": "Bangladesh (+880)",
        "value": "880"
    },
    {
        "label": "Vietnam (+84)",
        "value": "84"
    },
    {
        "label": "Belgium (+32)",
        "value": "32"
    },
    {
        "label": "Belarus (+375)",
        "value": "375"
    },
    {
        "label": "Bosnia (+387)",
        "value": "387"
    },
    {
        "label": "Bulgaria (+359)",
        "value": "359"
    },
    {
        "label": "Brunei (+673)",
        "value": "673"
    },
    {
        "label": "Saudi Arabia (+966)",
        "value": "966"
    },
    {
        "label": "Serbia (+381)",
        "value": "381"
    },
    {
        "label": "Sweden (+46)",
        "value": "46"
    },
    {
        "label": "Switzerland (+41)",
        "value": "41"
    },
    {
        "label": "Spain (+34)",
        "value": "34"
    },
    {
        "label": "Slovakia (+421)",
        "value": "421"
    },
    {
        "label": "Slovenia (+386)",
        "value": "386"
    },
    {
        "label": "Singapore (+65)",
        "value": "65"
    },
    {
        "label": "UAE (+971)",
        "value": "971"
    },
    {
        "label": "Armenia (+374)",
        "value": "374"
    },
    {
        "label": "Ireland (+353)",
        "value": "353"
    },
    {
        "label": "Azerbaijan (+994)",
        "value": "994"
    },
    {
        "label": "Afghanistan (+93)",
        "value": "93"
    },
    {
        "label": "Albania (+355)",
        "value": "355"
    },
    {
        "label": "Estonia (+372)",
        "value": "372"
    },
    {
        "label": "UK (+44)",
        "value": "44"
    },
    {
        "label": "Yemen (+967)",
        "value": "967"
    },
    {
        "label": "Oman (+968)",
        "value": "968"
    },
    {
        "label": "Austria (+43)",
        "value": "43"
    },
    {
        "label": "Jordan (+962)",
        "value": "962"
    },
    {
        "label": "Uzbekistan (+998)",
        "value": "998"
    },
    {
        "label": "Ukraine (+380)",
        "value": "380"
    },
    {
        "label": "Israel (+972)",
        "value": "972"
    },
    {
        "label": "Egypt (+20)",
        "value": "20"
    },
    {
        "label": "Italy (+39)",
        "value": "39"
    },
    {
        "label": "India (+91)",
        "value": "91"
    },
    {
        "label": "Indonesia (+62)",
        "value": "62"
    },
    {
        "label": "Japan (+81)",
        "value": "81"
    },
    {
        "label": "China (+86)",
        "value": "86"
    },
    {
        "label": "Czech Republic (+420)",
        "value": "420"
    },
    {
        "label": "Cambodia (+855)",
        "value": "855"
    },
    {
        "label": "Kuwait (+965)",
        "value": "965"
    },
    {
        "label": "Croatia (+385)",
        "value": "385"
    },
    {
        "label": "Kyrgyzstan (+996)",
        "value": "996"
    },
    {
        "label": "Cyprus (+357)",
        "value": "357"
    },
    {
        "label": "Tajikistan (+992)",
        "value": "992"
    },
    {
        "label": "Thailand (+66)",
        "value": "66"
    },
    {
        "label": "Turkey (+90)",
        "value": "90"
    },
    {
        "label": "Turkmenistan (+993)",
        "value": "993"
    },
    {
        "label": "Pakistan (+92)",
        "value": "92"
    },
    {
        "label": "Papua New Guinea (+675)",
        "value": "675"
    },
    {
        "label": "Portugal (+351)",
        "value": "351"
    },
    {
        "label": "Poland (+48)",
        "value": "48"
    },
    {
        "label": "France (+33)",
        "value": "33"
    },
    {
        "label": "Finland (+358)",
        "value": "358"
    },
    {
        "label": "Philippines (+63)",
        "value": "63"
    },
    {
        "label": "Hungary (+36)",
        "value": "36"
    },
    {
        "label": "Australia (+61)",
        "value": "61"
    },
    {
        "label": "Hong Kong (+852)",
        "value": "852"
    }
]
export default countryCodeList;
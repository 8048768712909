import { useEffect } from "react";
import { IYogaCenter } from "../../model";
import { Modal } from "antd";
import { ReactComponent as CircleCheck } from "../../../../assets/images/icon/circle-check.svg";

interface IProps {
    isOpen: boolean
    setIsOpen: Function
    dataList: Array<IYogaCenter>
    selectedYogaCenterId: number | undefined
    setSelectedYogaCenterId: Function
}

const YogaCenterBottomSheet = ({ isOpen, setIsOpen, dataList, selectedYogaCenterId, setSelectedYogaCenterId }: IProps) => {
    const contentHeight = (dataList.length * 104) + 60 + 39;
    const minWidth = window.innerWidth <= 480 ? '100vw' : '480px';
    const marginInline = window.innerWidth <= 480 ? '0' : (window.innerWidth - 480) / 2;

    useEffect(() => {
        if (isOpen) {
            while (document.querySelector('style[data-rc-order="append"]')) {
                const styleElement = document.querySelector('style[data-rc-order="append"]');
                styleElement && styleElement.parentNode && styleElement.parentNode.removeChild(styleElement);
            }
            const styleElement = document.createElement('style');
            styleElement.setAttribute('data-rc-order', 'append');
            styleElement.innerHTML = `html body { overflow-y: hidden; }`;
            document.head.appendChild(styleElement);
        }
        if (!isOpen) {
            const styleElement = document.querySelector('style[data-rc-order="append"]');
            styleElement && styleElement.parentNode && styleElement.parentNode.removeChild(styleElement);
        }
    }, [isOpen])

    const selectedYogaCenterData = () => {
        return dataList.find((yogaCenter: IYogaCenter) => yogaCenter.id === selectedYogaCenterId) || dataList[3];
    }

    return <>
        <Modal
            transitionName="custom-modal"
            style={{ top: `calc(100vh - ${contentHeight}px)`, padding: 0, margin: 0 }}
            styles={{
                content: { minWidth: minWidth, marginInline: marginInline, padding: "20px 20px 40px 20px", height: contentHeight + 'px', borderRadius: "5% 5% 0 0" }

            }}
            title={<div className="title/t1">스튜디오 선택</div>}
            open={isOpen}
            closeIcon={null}
            footer={null}
            onCancel={() => setIsOpen(false)}
        >
            <div style={{ marginTop: 16 }}>
                {dataList.sort((a, b) => b.id - a.id).map((yogaCenterData: IYogaCenter) => {
                    return <div key={yogaCenterData.id}>
                        <div
                            style={{ minHeight: '77px', padding: '12px 0', cursor: 'pointer' }}
                            onClick={() => {
                                setSelectedYogaCenterId(yogaCenterData.id);
                                setIsOpen(false);
                            }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", gap: "16px" }}>
                                <div style={{ display: "flex", gap: "16px" }}>
                                    <div>
                                        <img style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '5px' }} src={yogaCenterData.image} alt="요가 센터"></img>
                                    </div>
                                    <div>
                                        <div className="sub-title/st2">{yogaCenterData.name}</div>
                                        <div className="body/b1 secondary/s700" style={{ whiteSpace: "pre-wrap" }}>{selectedYogaCenterData() && selectedYogaCenterData().description}</div>
                                    </div>
                                </div>
                                <CircleCheck width={20} height={20} stroke={yogaCenterData.id === selectedYogaCenterId ? "#FFFFFF" : "#AEAEAE"} fill={yogaCenterData.id === selectedYogaCenterId ? "#000000" : '#FFFFFF'} />
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </Modal >
    </>
}

export { YogaCenterBottomSheet }
import { Divider, Flex } from "antd";
import { BarcodeIconWithModal } from "../../../../../features";
import { moneyStringFormatByNumber } from "../../../../../utils";
import dayjs from "dayjs";
import { BottomSheet } from "../../../../../shared";
import { Fragment, useState } from "react";


interface IPointItem {
    id: number;
    value: number;
    actionStatus: string;
    actionTitle: string;
    memo: string;
    lastUpdatedDate: string;
    createdDate: string;
}
interface IProps {
    totalPoint: number;
    pointList: Array<IPointItem>;
}

const PointTab = ({ totalPoint, pointList }: IProps) => {
    const [isShowBottomSheet, setIsShowBottomSheet] = useState<boolean>(false);
    const [pointFilter, setPointFilter] = useState<string>('전체');

    const filteredPoints = pointList
        .filter((item: IPointItem) => pointFilter === '적립'
            ? item.value > 0
            : pointFilter === '사용'
                ? item.value < 0
                : true);

    return (<>
        <div style={{ padding: '24px 20px' }}>
            <Flex style={{ padding: '16px 12px', backgroundColor: 'var(--secondarys100)', justifyContent: 'space-between' }}>
                <div className="sub-title/st2" style={{ color: 'var(--secondarys800)' }}>보유 포인트</div>
                <Flex gap={12} style={{ alignItems: 'center' }}>
                    <div className="sub-title/st4">{moneyStringFormatByNumber(totalPoint)}p</div>
                    <BarcodeIconWithModal />
                </Flex>
            </Flex>
        </div>
        <div style={{ padding: '8px 20px 80px 20px' }}>
            <Flex justify="space-between">
                <div className="sub-title/st3">내역</div>
                <div>
                    <BottomSheet
                        isOpen={isShowBottomSheet}
                        setIsOpen={setIsShowBottomSheet}
                        dataList={[
                            { id: 0, label: '전체', value: '전체' },
                            { id: 1, label: '적립', value: '적립' },
                            { id: 2, label: '사용', value: '사용' }
                        ]}
                        value={pointFilter}
                        setValue={(value: string) => setPointFilter(value)}
                        isShowLabel={true}
                    />
                </div>
            </Flex>
            <div style={{ marginTop: 24 }}>
                {filteredPoints.length > 0 ? (
                    filteredPoints.map((item: IPointItem, idx: number) => (<Fragment key={idx}>
                        <Flex justify="space-between" style={{ padding: '8px 0', alignItems: 'center' }}>
                            <div>
                                <div className="caption/c1 secondary/s700">{dayjs(item.createdDate?.slice(0, 10)).format('YYYY.MM.DD').toString()}</div>
                                <div className="body/b2" style={{ marginTop: 4 }}>{item.actionTitle}</div>
                            </div>
                            {item.value > 0
                                ? <div className="sub-title/st2">{`+${moneyStringFormatByNumber(item.value)}`}p</div>
                                : <div className="sub-title/st2 secondary/s600">{moneyStringFormatByNumber(item.value)}p</div>
                            }
                        </Flex>
                        <Divider style={{ margin: '16px 0' }} />
                    </Fragment>))
                ) : (
                    <div style={{ marginTop: 24, textAlign: 'center' }}>
                        <div className="body/b2 secondary/s600">포인트 내역이 없습니다</div>
                    </div>
                )}
            </div>
        </div>
    </>)
}

export { PointTab }
import { useNavigate, useParams } from "react-router-dom";
import Intro from "./ui/Intro";
import GoodInForm from "./ui/GoodInForm";
import BadOutForm from "./ui/BadOutForm";
import SolutionForm from "./ui/SolutionForm";
import CompleteServey from "./ui/CompleteSurvey";
import { useEffect } from "react";
import { getInvitationDoasnSurvey } from "../../service";

const InvitationDosanProgram = () => {
    const { step } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (step) return;
        getInvitationDoasnSurvey()
            .then((res) => {
                navigate(`/invitation/dosan/program/${res.data.step}`)
            })
            .catch((err) => console.error('err', err));
    }, [])

    return (
        <div>
            {!step ? <Intro /> :
                step === '1' ? <GoodInForm /> :
                    step === '2' ? <BadOutForm /> :
                        step === '3' ? <SolutionForm /> :
                            step === '4' ? <CompleteServey /> :
                                <Intro />}
        </div>
    )
}

export default InvitationDosanProgram
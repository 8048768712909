export const getLocalAccessToken = () => {
    let accessToken = localStorage.getItem("positivehotel-yoga-client-accessToken");
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
        window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({
            functionName: 'getInjectedHardwareValue',
            data: {
                "key": "accessToken"
            }
        }))
        window.getInjectedHardwareValue = (accessTokenFromDevice: string) => {
            if (accessTokenFromDevice && accessTokenFromDevice !== "not found value to device") {
                localStorage.setItem("positivehotel-yoga-client-accessToken", accessTokenFromDevice);
                accessToken = accessTokenFromDevice;
            }
        }
    }
    if (accessToken === 'undefined' || !accessToken) return null;
    return accessToken;
};
export const setUser = (accessToken: string) => {
    localStorage.setItem("positivehotel-yoga-client-accessToken", accessToken);
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
        window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({
            functionName: 'injectValueToHarware',
            data: {
                "key": "accessToken",
                "value": accessToken
            }
        }))
    }
};
export const removeUser = () => {
    localStorage.removeItem("positivehotel-yoga-client-accessToken");
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
        window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({
            functionName: 'injectValueToHarware',
            data: {
                "key": "accessToken",
                "value": ""
            }
        }))
    }
};
import { Flex } from "antd"
import { useState } from "react"
import { ReactComponent as ArrowDown } from '../../../../assets/images/icon/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../../assets/images/icon/arrow-up.svg';
import { DividerThin } from "../../../../shared";

interface IProps {
    description: string
}

const ClassDescription = ({ description }: IProps) => {
    const [isExpend, setIsExpend] = useState<boolean>(false)

    return <>
        {description && <>
            <DividerThin style={{ marginBlock: 20 }} />
            <div
                style={{ paddingBlock: 7, cursor: 'pointer' }}
                onClick={() => setIsExpend(!isExpend)}>
                <Flex style={{ justifyContent: 'space-between' }}>
                    <div className="sub-title/st3">클래스 설명</div>
                    <div>{description.length > 70 && (isExpend ? <ArrowUp width={16} height={16} stroke="#555555" style={{ paddingInline: 8 }} /> : <ArrowDown width={16} height={16} stroke="#555555" style={{ paddingInline: 8 }} />)}</div>
                </Flex>
                <div
                    className="body/b2"
                    style={{
                        display: isExpend ? 'block' : '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                        marginTop: '12px',
                        whiteSpace: "pre-wrap",
                        overflow: "hidden",
                    }}>
                    {description}
                </div>
            </div>
        </>
        }
    </>
}
export { ClassDescription }
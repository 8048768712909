import { BarcodeIconWithModal } from "../../features";

interface IProps {
    name: string;
    title: string;
}

const MemberNameAndTitle = ({ name, title }: IProps) => {
    return <>
        <div className="sub-title/st4 base/black" style={{ display: 'flex', gap: 'var(--space12)' }}>
            <div>
                {name} {title}
            </div>
            <BarcodeIconWithModal
                style={{ paddingBlock: '3.5px' }}
                color={'black'}
            />
        </div>
    </>
}

export { MemberNameAndTitle }
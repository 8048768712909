import { TopBar } from "../../../widgets";

interface IProps {
    isModal?: boolean
}
const PersonInfoPolicy = ({ isModal = false }: IProps) => {
    return <>
        {(!isModal) && <TopBar title="개인정보 처리방침" rightIcon={false} />}
        {isModal && <div className="title/t2" style={{ marginBottom: "12px" }}>개인정보 처리방침</div>}
        <div>
            <div className="body/b2" style={{ padding: isModal ? '' : '24px 20px' }}>
                <div className="sub-title/st2">(주)파지티브호텔 (이하 '회사'는) 이용자의 개인정보를 중요시하며, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’), 개인정보보호법 등 국내의 개인정보 보호에 관한 법률을 준수하고 있습니다</div>
                <div>본 방침은 2021년 1월 15일부터 시행됩니다. '회사'는 회원가입, 상담, 서비스 신청 등을 위해 아래와 같은 개인정보를 수집하고 있습니다. </div>
                <br />
                <div className="sub-title/st2">**1. 개인정보의 수집 및 이용 목적**</div>
                <div>회사는 이용자의 개인정보를 최소한으로 수집하며, 수집된 개인정보는 다음의 목적을 위하여 활용합니다.</div>
                <div>가. 모바일 어플리케이션(파지티브호텔 모바일 컨시어지, Positive Hotel Mobile Concierge) 회원 서비스 제공</div>
                <div>- 회원 가입</div>
                <div>- 서비스 및 상품 제공을 위한 결제</div>
                <div>- 포인트 적립 등</div>
                <div>- 오더 서비스 (제품 픽업 및 룸서비스) 및 딜리버리 서비스 제공 등</div>
                <div>- 웰니스 서비스 (컨텐츠 제공, 오프라인 이벤트 이용권 판매, 웰니스 제품 큐레이션) 제공 등</div>
                <div>나. 마케팅 정보 활용 (서비스 개선, 상품 정보 및 행사 정보 등 안내)</div>
                <div>- 콘텐츠 등 기존 서비스 제공(마케팅 및 광고 포함)에 더하여, 서비스 방문, 이용기록의 분석, 신규 서비스(제품)개발 및 특화, 신규 서비스(제품)개발 및 특화 목적의 소비자 리서치 진행, 이벤트 등 광고성 정보 전달, 인구통계학적 특성에 따른 서비스 제공, 기존 서비스 개선 등</div>
                <div>- 이벤트 기간 내 변동사항 고지 또는 기간 임박, 공지 및 이벤트 홍보 안내를 위한 문자메시지, 이메일 발송</div>
                <div>- 서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등</div>
                <div>다. 개인별 맞춤 서비스 제공</div>
                <div>라. 이용자 보호 및 서비스 운영 </div>
                <div>- 법령 및 회사 이용약관을 위반하는 이용자에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등</div>
                <div>마. 제휴 서비스 제공 </div>
                <br />
                <div className="sub-title/st2">**2. 수집하는 개인정보 항목 및 수집 방법**</div>
                <div>가. 회사가 모바일 어플리케이션을 통해 이용자로부터 수집하는 개인정보 항목은 다음과 같습니다 : </div>
                <div>[개인정보 수집항목]</div>
                <div>- 선택항목은 입력을 하지 않아도 회원 가입이나 서비스 이용에 제한이 없으며 필요한 경우 개인정보 수정 화면 등에서 직접 입력 하실 수 있습니다.</div>
                <div>- 허위 정보 입력 시 회사의 조치 </div>
                <div>이용자는 자신의 정보에 대해 정확성 및 적법성을 보장해야 합니다. 만약 이를 위반하여 타인의 정보를 도용하는 등 각종 방법으로 허위 정보를 입력할 경우 회사는 해당 이용자를 관계법령에 따라 신고 할 수 있으며 강제 탈퇴를 시킬 수도 있습니다.</div>
                <div>나. 서비스 이용과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.  </div>
                <div>- 서비스 이용기록, 접속 로그, 쿠키, 접속IP정보, 결제기록, 이용정지 기록, 포인트 적립기록, 단말기정보(OS종류, PUSH용 토큰, 디바이스 ID), DI,  신용카드 결제 및 등록 정보 등 - 쿠키의 경우 구체적인 운영 내역과 설정 거부 방법 등은 본 개인정보처리방침에 정하고 있습니다.</div>
                <div>다. 회사는 아래의 방법을 통해 개인정보를 수집합니다.</div>
                <div>- 모바일 어플리케이션 내 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우 해당 개인정보를 수집합니다.</div>
                <div>- 서비스 이용 과정에서 이용자의 동의를 받지는 않았지만 이용자와의 계약의 체결 및 이행을 위하여 불가피하게 필요한 경우 등 개인정보보호법 및 정보통신망법의 개인정보의 수집, 이용에 해당하는 경우 개인정보를 수집합니다</div>
                <div>- 홈페이지, 1:1 문의하기, 전화, 팩스, 이벤트 응모, 매장을 통한 문의 등의 과정에서 이용자의 개인정보가 수집될 수 있습니다.</div>
                <div>- 기기정보와 같은 생성정보는 자동으로 생성되어 수집될 수 있습니다.</div>
                <div>- 외부 업체 서비스 제휴 등으로 제3자로부터 개인정보를 제공받을 수 있습니다.</div>
                <br />
                <div className="sub-title/st2">**3. 개인정보의 보유∙이용기간 및 파기**</div>
                <div>가. 회사는 회원이 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당 개인정보를 지체 없이 파기합니다. 회사는 이용자의 개인정보 제공일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및 이용하게 됩니다. 단, 반복 재가입 등의 방법을 이용한 서비스 부정이용 방지를 위하여 키 고유 정보(이름, ID, DI, 성별, 생년)는 회원 탈퇴 후 30일간 보관하며, 관계 법령에 의한 일정 기간 보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관 합니다.</div>
                <div>- 서비스이용기록, 접속로그, 접속IP정보 (통신비밀보호법 : 3개월)</div>
                <div>- 표시/광고에 관한 기록 (전자상거래법 : 6개월)</div>
                <div>- 계약 또는 청약철회 등에 관한 기록 (전자상거래법 : 5년)</div>
                <div>- 대금결제 및 재화 등의 공급에 관한 기록 (전자상거래법 : 5년)</div>
                <div>- 소비자의 불만 또는 분쟁처리에 관한 기록 (전자상거래법 : 3년)</div>
                <div>나. 파기 방법</div>
                <div>- 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 완전하게 삭제하고, 출력물 등은 분쇄기로 분쇄하거나 소각하는 방식 등으로 파기합니다.</div>
                <br />
                <div className="sub-title/st2">**4. 개인정보의 제3자 제공 및 위탁 내용**</div>
                <div>회사는 원칙적으로 이용자 동의 없이 개인정보를 제 3자에 제공하지 않습니다.</div>
                <div>다만, 회사는 편리하고 더 나은 서비스 제공을 위해 아래와 같이 개인정보 취급 업무를 외부 전문업체에 위탁하여 운영하고 있습니다.</div>
                <div>**위탁 상세 정보**</div>
                <div>수탁업체 : 주식회사 토스페이먼츠</div>
                <div>위탁업무 내용 : 전자지불서비스, 파지티브페이 카드 등록 서비스 등 결제 관련 업무 </div>
                <br />
                <div className="sub-title/st2">**5. 이용자 및 법정대리인의 권리와 그 행사방법**</div>
                <div>가. 이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.</div>
                <div>- 이용자들의 개인정보 조회, 수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’ 등)을, 가입해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.</div>
                <div>- 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.</div>
                <div>나. 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다. </div>
                <div>다. 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</div>
                <br />
                <div className="sub-title/st2">**6. 개인정보에 대한 민원서비스**</div>
                <div>가. 회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.</div>
                <div>고객서비스담당부서: 고객지원팀</div>
                <div>전화번호: 1522-3902</div>
                <div>이메일: app@positivehotel.com</div>
                <div>개인정보최고책임자/정보보호최고책임자: 장예지</div>
                <div>전화번호: 1522-3902</div>
                <div>이메일: yjjang@positivehotel.com</div>
                <div>귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.</div>
                <div>나. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</div>
                <div>- 개인정보침해 신고센터 ([http://privacy.kisa.or.kr](http://privacy.kisa.or.kr/) / 국번 없이 118)</div>
                <div>- 대검찰청 사이버수사과 ([www.spo.go.kr](http://www.spo.go.kr/) / 국번 없이 1301)</div>
                <div>- 경찰청 사이버안전국([www.ctrc.go.kr](http://www.ctrc.go.kr/) / 국번 없이 182)</div>
                <div>- 개인정보 분쟁조정위원회([www.kopico.go.kr](http://www.kopico.go.kr/) / 1833-6972)</div>
                <br />
                <div className="sub-title/st2">**7. 개인정보의 기술적, 관리적 보호 대책** </div>
                <div>가. 기술적 대책</div>
                <div>이용자의 개인정보는 암호화된 통신구간을 이용하여 전송하고, 비밀번호 등 중용정보는 암호화 하여 보관하고 있습니다. 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있습니다. 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 송수신하고 있습니다.</div>
                <div>나.  관리적 대책</div>
                <div>개인정보 취급자를 최소한을 유지하고 개인정보 취급자에 대한 정기적인 교육 및 캠페인을 통하여 이용자의 개인정보 보호가 회사의 가장 중요한 가치임을 끊임없이 강조하고 있습니다. 이용자 개인정보 보호 의무를 올바르게 준수할 수 있도록 기술적/관리적 보호조치를 상시 수행하고 있습니다. 개인정보보호 정책에 대한 이행사항 및 직원의 준수여부를 감시하기 위한 내부절차를 마련하고 있습니다.</div>
                <br />
                <div className="sub-title/st2">**8. 개정 전 고지 의무**</div>
                <div>현 개인정보처리방침은 2022년 1월 15일부터 적용되며, 법령, 정책 또는 보안기술의 변경에 따라 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 모바일 어플리케이션 내 '공지사항'을 통해 고지할 것입니다.다만, 이용자 권리의 중대한 변경을 개정하는 경우에는 적용일로부터 30일전까지 모바일 어플리케이션에 공시하고 회원이 입력한 가장 최근의 이메일로 전송하는 방법으로 회원에게 고지합니다. 변경된 개인정보처리방침은 고지한 적용일로부터 효력이 발생합니다.</div>
            </div>
        </div>
    </>
}

export { PersonInfoPolicy };
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getInvitationDoasnSurvey } from "../../../service";
import { getDosanProgramMember } from "../../../service";

const CompleteServey = () => {
    const navigate = useNavigate();
    const [invitationDosanSurvey, setInvitationDosanSurvey] = useState<IInvitationDosanSurvey | undefined>(undefined);
    const [DosanProgramMember, setDosanProgramMember] = useState<IDosanProgramMember | undefined>(undefined);

    useEffect(() => {
        getInvitationDoasnSurvey()
            .then((res) => {
                setInvitationDosanSurvey(res.data)
            })
            .catch((err) => console.error('err', err));

        getDosanProgramMember()
            .then((res) => {
                setDosanProgramMember(res.data)
            })
            .catch((err) => console.error('err', err));
    }, [])


    return (
        <div>
            {invitationDosanSurvey && <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', textAlign: 'center' }}>
                    <div style={{ flex: 1 }}>
                        <div style={{ fontSize: '16px', fontWeight: '600' }}>내가 선택한 솔루션</div>
                        <div style={{ marginTop: '20px', fontSize: '40px', fontWeight: 400, lineHeight: '58px' }}>{invitationDosanSurvey.solution}</div>
                        <div style={{ marginTop: '27px', fontWeight: '600' }}>설문 1 결과값</div>
                        <div>{invitationDosanSurvey.goodInValue}</div>
                        <div style={{ marginTop: '27px', fontWeight: '600' }}>설문 2 결과값</div>
                        <div>{invitationDosanSurvey.badOutContents}</div>
                        <hr style={{ margin: '43px 0 56px 0' }} />
                        <div>날짜 / 장소 안내</div>
                        <div>{DosanProgramMember?.startDate} / {DosanProgramMember?.location}</div>
                        <div>유의사항</div>
                        <div>{DosanProgramMember?.notice}</div>
                        <div>초대장은 마이 {'>'} 다가오는 프로그램에서<br />
                            다시 확인 하실 수 있습니다</div>
                        <div>도착해서 바코드를 보여주세요<br />
                            바코드는 마이 {'>'} 이름 옆에 있습니다
                        </div>
                    </div>
                </div>
                <div style={{ position: "fixed", bottom: "0", width: "100%", maxWidth: "480px", textAlign: 'center' }}>
                    <div style={{ fontSize: "15px", fontWeight: "700", backgroundColor: "black", color: "#FFFFFF", cursor: "pointer" }}>
                        <div style={{ padding: "26px 0" }} onClick={() => { navigate('/my') }}>앱 둘러보기</div>
                    </div>
                </div>
            </>}
        </div>
    )
}

export default CompleteServey;
import { Flex } from "antd"
import { ReactComponent as ArrowRight } from '../../assets/images/icon/arrow-right.svg'
import { Link } from "react-router-dom"

interface IProps {
    title: string;
    to: string;
    state?: any;
}

const ToLinkBarWithTitleArrow = ({ title, to, state }: IProps) => {
    return (
        <Link to={to} style={{ textDecoration: 'none' }} state={state}>
            <div style={{ padding: '14px 12px', cursor: 'pointer' }} >
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} gap={16}>
                    <div className="body/b3" style={{ width: '100%', color: 'var(--secondarys800)' }}>{title}</div>
                    <ArrowRight width={16} height={16} stroke="var(--secondarys600)" />
                </Flex>
            </div>
        </Link>)
}

export { ToLinkBarWithTitleArrow }
import { TopBar } from "../../../widgets";
interface IProps {
    isModal?: boolean
}
const MarketingAgreement = ({ isModal }: IProps) => {

    const contents = () => {
        return <div>
            <div className="sub-title/st2">1. 광고성 정보의 이용목적</div>
            <div className="body/b2">
                회사가 제공하는 회원 맞춤형 서비스 및 상품 추천, 각종 경품 행사, 이벤트 등의 광고성 정보를 어플리케이션 내 푸시알림, 이메일, 문자메시지(SMS 또는 카카오톡), 서신우편, 전화 등을 통해 회원에게 제공합니다.<br />
                마케팅 수신 동의는 거부하실 수 있으며 동의 이후에라도 고객의 의사에 따라 동의를 철회할 수 있습니다. 동의를 거부하시더라도 파지티브호텔이 어플리케이션을 통해 제공하는 서비스의 이용에 제한이 되지 않습니다. 단, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.
            </div>
            <br />
            <div className="sub-title/st2">2. 미동의 시 불이익 사항</div>
            <div className="body/b2">
                개인정보보호법 제22조 제5항에 의해 선택정보 사항에 대해서는 동의를 거부하시더라도 서비스 이용이 제한되거나 불이익이 발생하지 않습니다. 단, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.
            </div>
            <br />
            <div className="sub-title/st2">3. 마케팅 정보 수신 동의 철회</div>
            <div className="body/b2">
                파지티브호텔에서 제공하는 마케팅 정보를 원하지 않을 경우 어플리케이션 내 'MY {'>'} 설정 {'>'} 약관 및 개인정보 처리 동의'에서 철회를 요청할 수 있습니다. 또한 향후 마케팅 정보 수신에 새롭게 동의하시고자 하는 경우에도 'MY {'>'} 설정 {'>'} 약관 및 개인정보 처리 동의'에서 동의하실 수 있습니다.
            </div>
        </div>
    }

    return <>
        {isModal
            ? <>
                <div className="title/t2" style={{ marginBottom: "12px" }}>마케팅 정보 수신 동의</div>
                {contents()}
            </>
            : <>
                <TopBar title="정기권 환불 정책" rightIcon={false} />
                <div style={{ padding: '24px 20px' }}>
                    {contents()}
                </div>
            </>}
    </>
}

export { MarketingAgreement };
import { AxiosResponse } from 'axios';
import api from './Api'

export const createGoodInValueInvitationDoasnSurvey = (goodInValue: string): Promise<AxiosResponse<any, any>> => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/invitation/dosan/survey`, { goodInValue: goodInValue, step: 2 });
}
export const createBadOutValueInvitationDoasnSurvey = (badOutValue: string, badOutContents: string): Promise<AxiosResponse<any, any>> => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/invitation/dosan/survey`, { badOutValue: badOutValue, badOutContents: badOutContents, step: 3 });
}
export const createSolutionInvitationDoasnSurvey = (solution: string): Promise<AxiosResponse<any, any>> => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/invitation/dosan/survey`, { solution: solution, step: 4 });
}
export const getInvitationDoasnSurvey = (): Promise<AxiosResponse<IInvitationDosanSurvey, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/invitation/dosan/survey`);
}
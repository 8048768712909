import dayjs from "dayjs";
import { Link } from "react-router-dom";

interface IProps {
    noticePopup: INoticePopup;
}
const NotificationNoticeCard = ({ noticePopup }: IProps) => {
    return <Link to={`/notice-popup/${noticePopup.id}`} style={{ display: 'block', textDecorationLine: 'none', paddingBlock: 'var(--space12)' }} key={noticePopup.id} >
        <div className="body/b1 secondary/s500">{dayjs(noticePopup.createdDate).format("YYYY년 MM월 DD일")}</div>
        <div className="sub-title/st2 base/black" style={{ marginTop: '4px' }}>{noticePopup.title}</div>
    </Link>
}
export { NotificationNoticeCard }
import { AxiosResponse } from 'axios';
import api from './Api'

export const getTicketListByYogaCenterId = (yogaCenterId: string): Promise<AxiosResponse<IMobileTicketByYogaCenter, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/ticket/${yogaCenterId}`)
}
export const getMyTicketByToken = (): Promise<AxiosResponse<Array<IMyTicket>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/ticket/token`);
}
export const getPopupTicketByLectureId = (lectureId: string): Promise<AxiosResponse<Array<IMobileDefaultTicket>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/ticket/popup/${lectureId}`)
}
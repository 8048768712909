import { Modal } from "antd";
import './index.css'
import { ReactComponent as Close } from "../../../assets/images/icon/close.svg"
interface IProps {
    resignInfoModalStatus: boolean;
    setResignInfoModalStatus: Function;
}

const ResignInfo = ({ resignInfoModalStatus, setResignInfoModalStatus }: IProps) => {
    return <>
        <Modal centered styles={{ content: { padding: "20px" } }} open={resignInfoModalStatus} onCancel={() => setResignInfoModalStatus(false)} footer={null} closeIcon={<Close width={24} height={24} stroke="#000000" />}>
            <div>
                <div className="sub-title/st3">탈퇴 안내</div>
                <div className="body/b1" style={{ marginTop: "16px", color: "var(--secondarys800)" }}>
                    아래의 경로를 통해 파지티브호텔 탈퇴를 직접 진행하실 수 있습니다.
                    <div className="sub-title/st1" style={{ display: "flex", gap: "5px" }}>
                        <div>1.</div><div> 파지티브호텔에 가입한 이전 기기/이전 파지티브호텔 앱을 통한 탈퇴</div>
                    </div>
                    <div>
                        <div style={{ display: "flex", gap: "5px" }}>
                            <div>&middot;</div><div>[앱 실행 {'>'} MY {'>'} 설정 {'>'} 하단 ’탈퇴하기’ 선택]</div>
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                            <div>&middot;</div><div>1년간 이용하지 않은 파지티브호텔 계정은 휴면 상태로 전환합니다.</div>
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                            <div>&middot;</div><div>고객님의 소중한 개인정보보호를 위해 서비스를 1년간 이용하지 않은 계정을 휴면 상태로 전환합니다.</div>
                        </div>
                    </div>
                    <div className="sub-title/st1">
                        2. 아래 사항에 경우는 탈퇴를 진행하실 수 없습니다.
                    </div>
                    <div>
                        <div style={{ display: "flex", gap: "5px" }}>
                            <div>&middot;</div><div>파지티브호텔 앱을 사용할 수 없는 경우</div>
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                            <div>&middot;</div><div>기존에 가입한 카카오계정, 애플계정, 네이버계정으로 로그인 할 수 없는 경우</div>
                        </div>
                    </div>
                    <br />
                    앱 이름 : 파지티브호텔
                </div>
            </div>
        </Modal >
    </>
}

export { ResignInfo };
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../../assets/images/icon/arrow-left.svg";
import { ReactComponent as Logo } from "../../../assets/images/icon/logo.svg"
import { ReactComponent as Setting } from "../../../assets/images/icon/setting.svg"
import { ReactComponent as Bell } from "../../../assets/images/icon/bell.svg"
import { ReactComponent as Share } from "../../../assets/images/icon/share.svg"

interface IProps {
    title?: React.ReactNode;
    hiddenBackButton?: boolean;
    hiddenRightIcon?: boolean;
    showRightIcon?: "bell" | "share" | "setting"
    customNavigate?: string | number | undefined;
    isFixed?: boolean;
    isBlankForMobile?: boolean;
    styles?: { container?: React.CSSProperties, left?: React.CSSProperties, title?: React.CSSProperties, right?: React.CSSProperties, isFixed?: React.CSSProperties, isBlankForMobile?: React.CSSProperties };
}

const MainHeader = ({ title, hiddenBackButton, hiddenRightIcon, customNavigate, showRightIcon = "bell", isFixed, isBlankForMobile, styles }: IProps) => {
    const [currentDeviceBuildVersion, setCurrentDeviceBuildVersion] = useState<boolean>(false);
    const [topHeight, setTopHeight] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (window.native_yoga_bridge) {
            window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'getCurrentDeviceBuildVersion', data: {} }));
            window.getCurrentDeviceBuildVersion = (currentVersion: string) => {
                if (Number(currentVersion.split('.')[0]) >= 8) {
                    setCurrentDeviceBuildVersion(true)
                    window.native_yoga_bridge.sendMessage(JSON.stringify({ functionName: 'getStatusBarTopHeight', data: {} }));
                    window.getStatusBarTopHeight = (topHeight: string) => { setTopHeight(topHeight); setCurrentDeviceBuildVersion(true) }
                }
            }
        }
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
            window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'getCurrentDeviceBuildVersion', data: {} }))

            window.getCurrentDeviceBuildVersion = (currentVersion: string) => {
                if (Number(currentVersion.split('.')[0]) >= 8) {
                    window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({ functionName: 'getSafeAreaTopHeight', data: {} }))
                    window.getSafeAreaTopHeight = (topHeight: string) => { setTopHeight(topHeight); setCurrentDeviceBuildVersion(true); }
                }
            }
        }
    }, [])

    const customNavigatePath = () => {
        if (customNavigate) {
            if (typeof customNavigate === "string") { navigate(customNavigate, { replace: true }) } else if (typeof customNavigate === "number") { navigate(customNavigate) } else { navigate("/") }
        } else {
            navigate(-1)
        }
    }

    const rightIconComponent = () => {
        switch (showRightIcon) {
            case "bell":
                return <Bell width={24} height={24} stroke="#000000" onClick={() => navigate("/notification")} style={{ padding: 12, cursor: 'pointer' }} />
            case "share":
                return <Share width={24} height={24} stroke="#000000" style={{ padding: 12, cursor: 'pointer' }} />
            case "setting":
                return <Setting width={24} height={24} onClick={() => navigate("/setting")} style={{ padding: 12, cursor: 'pointer' }} />;
            default:
                return <Bell width={24} height={24} stroke="#000000" onClick={() => navigate("/notification")} style={{ padding: 12, cursor: 'pointer' }} />
        }
    }
    return <>
        {isBlankForMobile ? <div style={{ width: "100%", marginTop: currentDeviceBuildVersion ? topHeight + "px" : '0', backgroundColor: "transparent", ...styles?.isBlankForMobile }}></div>
            : <>
                <div style={{ position: isFixed ? "fixed" : "unset", backgroundColor: styles?.container?.backgroundColor ? styles?.container?.backgroundColor : "#FFFFFF", width: "100%", maxWidth: "480px" }}>
                    <div style={{ display: "flex", marginTop: currentDeviceBuildVersion ? topHeight + "px" : "0", justifyContent: "space-between", alignItems: 'center', backgroundColor: "#FFFFFF", ...styles?.container }}>
                        <div style={{ margin: '0 16px', cursor: 'pointer', ...styles?.left }} >
                            {!hiddenBackButton ?
                                <ArrowLeft width={24} height={24} stroke="black" style={{ cursor: "pointer", padding: '12px' }} onClick={customNavigatePath} /> :
                                <div style={{ width: '48px' }}></div>}
                        </div>
                        <div style={{ width: "auto", textAlign: "center" }}>
                            {title ?
                                <div style={{ fontSize: '18px', fontWeight: '700', padding: '13px', ...styles?.title }}>{title}</div> :
                                <Link to="/">
                                    <Logo style={{ width: "72px", height: '26px', padding: '15px', margin: '0 auto', cursor: "pointer" }} fill={"black"} />
                                </Link>}
                        </div>
                        <div style={{ width: '48px', margin: '0 16px', ...styles?.right }}>
                            {!hiddenRightIcon ? rightIconComponent() : <div style={{ width: '48px' }}></div>}
                        </div>
                    </div>
                </div>
            </>
        }
        {isFixed && <div style={{ width: "100vw", maxWidth: "480px", height: currentDeviceBuildVersion ? topHeight + "px" : '0', ...styles?.isFixed }}></div>}
    </>
}
export default MainHeader;
import { converteReservationStatusText } from "../../../utils";

interface IProps {
    status: string;
    style?: React.CSSProperties;
}

const CourseBadge = ({ status, style }: IProps) => {
    const statusText = converteReservationStatusText(status);
    const tagColor = statusText === '출석'
        ? 'base/white'
        : statusText === '결석'
            ? 'error/e500'
            : statusText === '예약' || '취소'
                ? 'secondary/s800'
                : undefined;

    const tagStyle: React.CSSProperties = {
        width: '21px',
        height: '14px',
        padding: 'var(--space4) var(--space8)',
        lineHeight: '14.32px',
        borderRadius: 'var(--radius4)',
        border: statusText === '결석' ? '1px solid var(--errore400)' : statusText === '취소' ? '1px solid var(--secondarys800)' : undefined,
        backgroundColor: statusText === '결석' || statusText === '취소' ? 'white' : statusText === '출석' ? 'var(--baseblack)' : 'var(--secondarys100)',
    };

    return <div className={"caption/c1 " + tagColor} style={{ ...tagStyle, ...style }}>{statusText}</div>
};

export { CourseBadge };
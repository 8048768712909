import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";


interface IProps {
    paidHistory: IPaidHistoryBasicWebResponseV2
    style?: React.CSSProperties
}
const PaidHistoryCard = ({ paidHistory, style }: IProps) => {
    const { memberId } = useParams();
    const navigate = useNavigate();
    return <div key={paidHistory.issuedWellnessTicketId} style={{ cursor: "pointer", ...style }} onClick={() => navigate(`/my/paid-history/${memberId}/${paidHistory.issuedWellnessTicketId}`)}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className="body/b3 base/black">
                {paidHistory.issuedWellnessTicketName}
            </div>
            <div className="sub-title/st3 base/black">
                {paidHistory.PaymentCategoryEnum === "ON_SITE" ? "앱 외 결제"
                    : paidHistory.totalPayValue ? paidHistory.totalPayValue.toLocaleString() + "원" : "0원"}
            </div>
        </div>
        <div className="body/b1 secondary/s500" style={{ marginTop: 'var(--space12)' }}>
            {dayjs(paidHistory.paymentDate).format('YYYY.MM.DD HH:mm:ss')}
        </div>
    </div>
}
export { PaidHistoryCard }
import { useNavigate } from "react-router-dom";
import { BarcodeIconWithModal } from "../../features"
import { ReactComponent as Logo } from "../../assets/images/icon/logo.svg"
import { ReactComponent as Bell } from "../../assets/images/icon/bell.svg"
import { ReactComponent as ArrowLeft } from "../../assets/images/icon/arrow-left.svg";
import { ReactComponent as Setting } from "../../assets/images/icon/setting.svg"
import { isLogin } from "../../utils";
import { useMyContext } from "../../entities/context";

interface IProps {
    type?: 'default' | 'logo';
    rightType?: 'default' | 'bell' | 'setting';
    title?: string | React.ReactNode;
    leftIcon?: boolean;
    middleIcon?: boolean;
    rightIcon?: boolean;
    thema?: 'dark' | 'white';
    position?: React.CSSProperties['position'] | undefined;
    style?: React.CSSProperties;
}
const TopBar = ({ type = 'default', rightType = 'default', title, leftIcon = true, middleIcon = true, rightIcon = true, thema = 'white', position = 'static', style }: IProps) => {
    const navigate = useNavigate()
    const { topHeight } = useMyContext();

    const LeftIconToType = ({ type }: { type?: 'default' | 'logo' }) => {
        switch (type) {
            case 'logo':
                return <>
                    {isLogin()
                        ? <BarcodeIconWithModal
                            color={thema === 'dark' ? 'black' : 'white'}
                            style={{ margin: 'var(--space4) var(--space16)', padding: '14.5px 9px' }} />
                        : <div style={{ width: 24, height: 24, padding: 'var(--space12)', margin: 'var(--space4) var(--space16)' }} />
                    }
                </>
            default:
                return <>
                    <ArrowLeft width={24} height={24} stroke="black"
                        style={{ cursor: "pointer", padding: 'var(--space12)', marginInline: 'var(--space16)' }}
                        onClick={() => navigate(-1)} />
                </>;
        }
    }
    const MiddleIconToType = ({ type }: { type?: 'default' | 'logo' }) => {
        switch (type) {
            case 'logo':
                return <Logo
                    width={72} height={26}
                    style={{ padding: '15px', margin: '0 auto', cursor: "pointer" }}
                    fill={thema === 'dark' ? "var(--baseblack)" : "var(--basewhite)"}
                    onClick={() => { navigate("/") }} />
            default:
                return <div className="title/t2" style={{ padding: '13px' }}>{title}</div>;
        }
    }
    const RightIconToType = ({ rightType }: { rightType?: 'default' | 'bell' | 'setting' }) => {
        switch (rightType) {
            case 'bell':
                return <Bell style={{ padding: 'var(--space12)', margin: 'var(--space4) var(--space16)', cursor: 'pointer' }}
                    width={24} height={24} stroke={thema === 'dark' ? "var(--baseblack)" : 'var(--basewhite)'}
                    onClick={(event) => { event.stopPropagation(); navigate("/notification") }} />
            case 'setting':
                return <Setting style={{ padding: 'var(--space12)', cursor: 'pointer', marginInline: 'var(--space16)' }}
                    width={24} height={24} onClick={event => { event.stopPropagation(); navigate("/setting") }} />;
            default:
                return <Bell style={{ padding: 'var(--space12)', margin: 'var(--space4) var(--space16)', cursor: 'pointer' }}
                    width={24} height={24} stroke={thema === 'dark' ? "var(--baseblack)" : 'var(--basewhite)'}
                    onClick={(event) => { event.stopPropagation(); navigate("/notification") }} />
        }
    }
    return <>
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                position: position,
                top: 0,
                paddingTop: (topHeight + 'px'),
                zIndex: 2,
                width: "100%",
                maxWidth: '480px',
                transition: "background-color 0.3s, color 0.3s",
                backgroundColor: thema === 'dark' ? "var(--basewhite)" : "transparent",
                ...style
            }}>
            {leftIcon ? <LeftIconToType type={type} /> : <div style={{ width: '24px', padding: '12px', marginInline: 'var(--space16)' }}></div>}
            {middleIcon ? <MiddleIconToType type={type} /> : <div style={{ width: '24px', padding: '12px' }}></div>}
            {rightIcon ? <RightIconToType rightType={rightType} /> : <div style={{ width: '24px', padding: '12px', marginInline: 'var(--space16)' }}></div>}
        </div >

    </>
}

export { TopBar }
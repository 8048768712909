const centerCardList = [{
    imageUrl: `https://img.positivehotel.io/2024/7/11/seolleung-02.jpg`,
    title: <>선릉<br />YOGA & KITCHEN</>,
    content: <>
        지중해식 기반 웰니스 키친과 150평 규모의<br />
        요가 스튜디오가 결합된 도심 속 웰니스 공간
    </>,
    to: "/yoga-center/3"
}, {
    imageUrl: `https://img.positivehotel.io/2023/12/8/PHB09250.jpg`,
    title: <>도산<br />YOGA & KITCHEN</>,
    content: <>
        엠비언트 조명과 사운드 시스템을 갖춘 요가 스튜디오와<br />
        키친이 결합된 홀리스틱 웰니스 공간
    </>,
    to: "/yoga-center/2"
}, {
    imageUrl: `https://img.positivehotel.io/2024/7/11/gangnam-01.jpg`,
    title: <>강남<br />YOGA</>,
    content: <>
        클래식 요가에 최적화된 아틀리에 스튜디오이자<br />
        전문 요가 인스트럭터 양성을 위한 공간
    </>,
    to: "/yoga-center/1"
}]

export interface ICenterCard {
    imageUrl: string
    title: React.ReactNode
    content: React.ReactNode
    to: string
}

export { centerCardList }
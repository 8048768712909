import { Divider, Flex, Skeleton } from "antd";

import { ReactComponent as ArrowRight } from "../../../../assets/images/icon/arrow-right.svg"
import { useMyContext } from "../../../../entities/context";

const WellnessLecutreDetailSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<div style={{ position: 'absolute', maxWidth: "480px", backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), width: '100%' }}>

        <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
            <div style={{ width: '215px', display: 'inline-block' }}>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
            </div>
        </div>

        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 375, marginTop: 13 }} />
        <div style={{ padding: '0 24px 118px' }}>
            <Skeleton.Button active={true} size={"default"} block={true} style={{ width: 160, height: 22, marginTop: 24 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 8 }} />
            <div style={{ marginTop: 16 }}></div>
            <Flex gap={20} style={{ marginTop: 4 }}>
                <div style={{ width: 52 }}><Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} /></div>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
            </Flex>
            <Flex gap={20} style={{ marginTop: 4 }}>
                <div style={{ width: 52 }}><Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} /></div>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
            </Flex>
            <Flex gap={20} style={{ marginTop: 4 }}>
                <div style={{ width: 52 }}><Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} /></div>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
            </Flex>

            <Divider style={{ margin: "20px 0" }} />

            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 32 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 72, marginTop: 12 }} />

            <Divider style={{ margin: "20px 0" }} />

            <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 160 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 20, marginTop: 8 }} />
                </div>
                <div><ArrowRight width={16} height={16} stroke="#AEAEAE" /></div>
            </Flex>

            <Divider style={{ margin: "20px 0" }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ width: 160, height: 32 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 48, marginTop: 16 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 48, marginTop: 12 }} />
        </div>
    </div>)
}

export default WellnessLecutreDetailSkeleton;
import { Fragment, useEffect, useState } from "react";
import { TopBar } from "../../../../widgets";
import { NavigateLogin, BottomSheet, TrainingHistoryCard, DividerThin } from "../../../../shared";
import { converteReservationStatusText, isLogin } from "../../../../utils";
import { useParams } from "react-router-dom";
import MyIssuedTicketSkeleton from "../../../../components/skeleton/my/myIssuedTicketSkeleton";
import { getIssuedWellnessTicketDetailDataForIssuedWellnessTicketId } from "../../../../service/issuedWellnessTicket";
import IssuedWellnessTicket from "../../../../features/IssuedWellnessTicket";
const IssuedWellnessTicketDetail = () => {
    const { id } = useParams();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const [issuedWellnessTicketDetail, setIssuedWellnessTicketDetail] = useState<IIssuedWellnessTicketBasicWebResponseV2>();
    const bottomSheetDataList: IBottomSheet[] = [{ id: 1, label: '전체', value: '전체' }, { id: 2, label: '예약', value: '예약' }, { id: 3, label: '출석', value: '출석' }, { id: 4, label: '결석', value: '결석' }, { id: 5, label: '취소', value: '취소' }]
    const [bottomSheetValue, setButtomSheetValue] = useState<string>('전체');
    const [bottomSheetModalStatus, setBottomSheetModalStatus] = useState<boolean>(false);
    const requestIssuedTicketDetailById = async (id: string) => {
        try {
            const res = await getIssuedWellnessTicketDetailDataForIssuedWellnessTicketId(id);
            setIssuedWellnessTicketDetail(res.data);
        } catch (error: any) {
            console.error('error', error.message);
        } finally {
            setIsInitLoading(false);
        }
    }
    useEffect(() => {
        if (!id) return;
        requestIssuedTicketDetailById(id);
    }, [id])


    return <>
        {isInitLoading && <MyIssuedTicketSkeleton />}
        {!isLogin()
            ? <>
                <TopBar title="정기권 상세" rightIcon={false} />
                <NavigateLogin />
            </>
            : issuedWellnessTicketDetail && <>
                <TopBar title="정기권 상세" rightIcon={false} />
                <div style={{ padding: '20px 24px 40px 24px' }}>
                    <IssuedWellnessTicket issuedWellnessTicket={issuedWellnessTicketDetail} />
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBlock: '24px' }}>
                        <div className="sub-title/st3 base/black">
                            사용 내역
                        </div>
                        <BottomSheet
                            isOpen={bottomSheetModalStatus}
                            setIsOpen={setBottomSheetModalStatus}
                            dataList={bottomSheetDataList}
                            value={bottomSheetValue}
                            setValue={setButtomSheetValue}
                            isShowLabel />
                    </div>


                    {issuedWellnessTicketDetail.reservationInfoList
                        .filter(w => bottomSheetValue === '전체' ? w : converteReservationStatusText(w.status) === bottomSheetValue)
                        .map((trainingHistory, idx) =>
                            <Fragment key={idx}>
                                <TrainingHistoryCard trainingHistory={trainingHistory} />
                                {(issuedWellnessTicketDetail.reservationInfoList?.length ?? 0) - 1 !== idx && <DividerThin style={{ marginBlock: 'var(--space16)' }} />}
                            </Fragment>
                        )}
                </div>
            </>}
    </>
}

export { IssuedWellnessTicketDetail }
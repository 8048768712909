import { Flex } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom"
import { ReservationBadge } from "../../../../../shared/reservationBadge";
import { lectureDatePeriodFormat } from "../../../../../utils";


interface IProps {
    dataList: Array<IWellnessLectureDetail> | null
}

const LectureCardInTeacherDetail = ({ dataList }: IProps) => {
    const navigate = useNavigate();

    return <>{dataList === null
        ? <div style={{ textAlign: "center", fontSize: "14px" }}>조회할 수업이 없습니다.</div>
        : <>{dataList.length === 0
            ? <div className="body/b2 secondary/s600" style={{ textAlign: 'center', marginTop: 24 }}>예약 가능한 수업이 없습니다</div>
            : <div style={{ paddingTop: 4 }}>{dataList.sort((a, b) => dayjs(a.startDateTime).diff(dayjs(b.startDateTime))).map((wellnessLecture, idx) => {
                console.log(dayjs().isAfter(dayjs(wellnessLecture.startDateTime)))
                console.log('dayjs', dayjs())
                console.log('startDateTime', dayjs(wellnessLecture.startDateTime))
                console.log('wellnessLecture.isReservation', wellnessLecture.maxReservationCnt)
                console.log('wellnessLecture.nowReservationCnt', wellnessLecture.nowReservationCnt)
                return <div
                    key={idx}
                    style={{ marginTop: 20, padding: '8px 0', cursor: 'pointer' }}
                    onClick={() => navigate(`/lecture/${wellnessLecture.id}`)}
                >
                    <Flex style={{ justifyContent: 'space-between' }} gap={16}>
                        <div style={{ width: '25%' }}>
                            <img src={wellnessLecture.lectureImageUrl || `${process.env.PUBLIC_URL}/assets/LectureDetailDefault.jpg`} alt="수업사진" width={'100%'} style={{ borderRadius: "999px", aspectRatio: 1, objectFit: 'cover' }} />
                        </div>
                        <div style={{ width: '75%' }}>
                            <Flex style={{ justifyContent: 'space-between' }}>
                                <div>
                                    <div className="sub-title/st1">{wellnessLecture.yogaProgramName && (wellnessLecture.yogaCenterName !== "강남") ? wellnessLecture.yogaProgramName : ""}</div>
                                    <div className="sub-title/st3" style={{ marginTop: 2 }}>{wellnessLecture.yogaLectureName}</div>
                                </div>
                                <div>
                                    {wellnessLecture.isReservation
                                        ? <ReservationBadge status="Complete" />
                                        : dayjs().isAfter(dayjs(wellnessLecture.startDateTime))
                                            ? <ReservationBadge status="Close" />
                                            : (wellnessLecture.maxReservationCnt - wellnessLecture.nowReservationCnt > 0)
                                                ? <ReservationBadge status="Available" />
                                                : <ReservationBadge status="Close" />
                                    }
                                </div>
                            </Flex>
                            <Flex style={{ justifyContent: 'space-between', marginTop: 12 }}>
                                <div className="body/b1 secondary/s500">{lectureDatePeriodFormat(wellnessLecture.startDateTime, wellnessLecture.endDateTime)}</div>
                                <div className="body/b1 secondary/s500">{wellnessLecture.yogaCenterName} {wellnessLecture.room}</div>
                            </Flex>
                        </div>
                    </Flex>
                </div>
            })}</div>}
        </>
    }</>
}

export { LectureCardInTeacherDetail }
import { useEffect, useRef, useState } from "react";
import { Table } from 'antd';
import './index.css';

interface IProps {
    data: string;
    style?: React.CSSProperties;
}
type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
const YogaCenterSchedule = ({ data, style }: IProps) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const tableContainerRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        if (tableContainerRef.current) {
            setIsScrolled(tableContainerRef.current.scrollLeft > 0);
        }
    };

    const [scheduleData, setScheduleData] = useState<Array<any>>([]);

    useEffect(() => {
        setScheduleData(JSON.parse(data))
    }, [data])
    useEffect(() => {
        const tableContainer = tableContainerRef.current;
        if (tableContainer) {
            tableContainer.addEventListener('scroll', handleScroll);
            return () => {
                tableContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);


    const columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'TIME',
            dataIndex: 'time',
            width: '60px',
            fixed: 'left',
            editable: true,
            render: (text: string) => <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: '12px', lineHeight: '20px', color: '#676767' }}><div>{text}</div></div>,
        },
        {
            title: 'MON',
            dataIndex: 'monday',
            width: '100px',
            editable: true,
            render: (text: string) => <>
                {text.split('!').map((i: string, index: number) => index === 0 ?
                    <div key={index} style={{ textAlign: 'center' }}>{i}</div> :
                    <div key={index} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i}</div>)}
            </>
        },
        {
            title: 'TUE',
            dataIndex: 'tuesday',
            width: '100px',
            editable: true,
            render: (text: string) => <>
                {text.split('!').map((i: string, index: number) => index === 0 ?
                    <div key={index} style={{ textAlign: 'center' }}>{i}</div> :
                    <div key={index} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i}</div>)}
            </>

        },
        {
            title: 'WED',
            dataIndex: 'wednesday',
            width: '100px',
            editable: true,
            render: (text: string) => <>
                {text.split('!').map((i: string, index: number) => index === 0 ?
                    <div key={index} style={{ textAlign: 'center' }}>{i}</div> :
                    <div key={index} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i}</div>)}
            </>
        },
        {
            title: 'THU',
            dataIndex: 'thursday',
            width: '100px',
            editable: true,
            render: (text: string) => <>
                {text.split('!').map((i: string, index: number) => index === 0 ?
                    <div key={index} style={{ textAlign: 'center' }}>{i}</div> :
                    <div key={index} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i}</div>)}
            </>
        },
        {
            title: 'FRI',
            dataIndex: 'friday',
            width: '100px',
            editable: true,
            render: (text: string) => <>
                {text.split('!').map((i: string, index: number) => index === 0 ?
                    <div key={index} style={{ textAlign: 'center' }}>{i}</div> :
                    <div key={index} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i}</div>)}
            </>
        },
        {
            title: 'SAT',
            dataIndex: 'saturday',
            width: '100px',
            editable: true,
            render: (text: string) => <>
                {text.split('!').map((i: string, index: number) => index === 0 ?
                    <div key={index} style={{ textAlign: 'center' }}>{i}</div> :
                    <div key={index} style={{ textAlign: 'center', fontWeight: 'bold' }}>{i}</div>)}
            </>
        }
    ];

    return <>
        <div style={{ overflow: 'auto', ...style }} ref={tableContainerRef}>
            <Table
                className={isScrolled ? ' isScrolled scheduleTable' : 'scheduleTable'}
                style={{ whiteSpace: 'pre-wrap', minWidth: '780px' }}
                dataSource={scheduleData}
                columns={columns}
                size="small"
                pagination={false}
            />
        </div>
    </>
}
export { YogaCenterSchedule };
import { AxiosResponse } from 'axios';
import api from './Api'

export const getAllTrainingHistoryByMemberIdAndTrainingDateTimeBetween = (startDateTime: string, endDateTime: string): Promise<AxiosResponse<ITrainingHistoryBasicWebResponseV2[]>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/training-history/my/web/v2?startDateTime=${encodeURIComponent(startDateTime)}&endDateTime=${(encodeURIComponent(endDateTime))}`);
}


export const getAllTrainingHistoryByMemberIdAndIssuedWellnessTicketIdAndTrainingDateTimeBetween = (issuedWellnessTicketId: string, startDateTime: string, endDateTime: string): Promise<AxiosResponse<ITrainingHistoryBasicWebResponseV2[]>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/training-history/my/issued-wellness-ticket/web/v2/${issuedWellnessTicketId}?startDateTime=${encodeURIComponent(startDateTime)}&endDateTime=${(encodeURIComponent(endDateTime))}`);
}

import { Modal } from "antd"
import { TextBtn } from "../../button/textBtn"

interface IProps {
    openModal: boolean;
    setOpenModal: Function
}
const TicketCancleModal = ({ openModal, setOpenModal }: IProps) => {
    return <>
        <TextBtn onClick={() => setOpenModal(true)}>자세히</TextBtn>
        <Modal
            centered
            title={'티켓 취소 정책'}
            open={openModal}
            footer={false}
            onOk={() => setOpenModal(false)}
            onCancel={() => setOpenModal(false)}
        >
            <div>
                <div>- 결제액의 10% 위약금 + 사용기간만큼 공제 후 지불</div>
                <div>- 정기권 사용 가능 횟수로 정산하여 환불</div>
                <div>- 사용기간은 (정기권 구매일 ~ 환불 요청일) 입니다.</div>
            </div>
        </Modal>
    </>
}

export { TicketCancleModal }
import MainPremiumMembershipContents from "./ui/mainPremiumMembershipContents";
import PositiveHotelMemberShip from "./ui/positiveHotelMemberShip";
import YogaProgram from "./ui/yogaProgram";
import PrivateService from "./ui/privateService";
import KitchenProduct from "./ui/kitchenProduct";


const PremiumMembership = () => {
    return <>
        <MainPremiumMembershipContents />
        <PositiveHotelMemberShip />
        <YogaProgram />
        <PrivateService />
        <KitchenProduct />
    </>
}
export default PremiumMembership
import { useEffect } from "react";
import './index.css'

interface IProps {
    setToast: Function;
    text: string;
}
const CustomToast = ({ setToast, text }: IProps) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            setToast(false);
        }, 1000);
        return () => {
            clearTimeout(timer);
        };
    }, [setToast]);

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ maxWidth: "432px", width: "90%", borderRadius: "var(--radius4)", paddingBlock: "var(--space12)", backgroundColor: '#000000B2', color: "#FFFFFF", position: "fixed", bottom: 40, animation: "slide-in-bottom 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both" }}>
                <div className="body/b2" style={{ whiteSpace: "pre-wrap", textAlign: "center" }}>{text}</div>
            </div>
        </div>
    );
}

export { CustomToast };
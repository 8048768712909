import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MyPaidDetailSkeleton from "../../../../components/skeleton/my/myPaidDetailSkeleton";
import { TopBar } from "../../../../widgets";
import { DividerThin, WellnessTicketReceipt } from "../../../../shared";
import { converteYogaCenter, formatPhoneNumber } from "../../../../utils";
import { getPaidHistoryByIssuedWellnessTicketId } from "../../../../service/paidHistory";
import dayjs from "dayjs";


const PaidHistoryDetail = () => {
    const { id } = useParams();
    const [paidHistory, setPaidHistory] = useState<IPaidHistoryDetailWebResponseV2>();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!id) return;
        getPaidHistoryByIssuedWellnessTicketId(id)
            .then((res) => setPaidHistory(res.data))
            .catch((error) => console.error('error', error))
            .finally(() => setIsInitLoading(false))
    }, [])

    const priceOnceCalc = () => {
        if (!paidHistory) return;
        const getPeriodUsableCnt = () => {
            const startDate = dayjs(paidHistory.issuedWellnessTicketStartDate);
            const expireDate = dayjs(paidHistory.issuedWellnessTicketExpireDate);
            switch (paidHistory.issuedWellnessTicketFrequencyUnit) {
                case 'WEEK':
                    return expireDate.diff(startDate, 'week');
                case 'MONTH':
                    return expireDate.diff(startDate, 'month');
                case 'YEAR':
                    return expireDate.diff(startDate, 'year');
                default:
                    return 1;
            }
        }

        const totalUseableCnt = paidHistory.issuedWellnessTicketType === 'COUNT'
            ? paidHistory.issuedWellnessTicketTotalUsableCnt
            : getPeriodUsableCnt()

        return Number((paidHistory.totalPayValue / totalUseableCnt).toFixed()).toLocaleString() + '원'
    }

    return <>
        {isInitLoading && <MyPaidDetailSkeleton />}
        <TopBar title="결제 내역 상세" rightIcon={false} />
        {paidHistory && <>
            <div style={{ padding: '24px 20px 40px' }}>
                <WellnessTicketReceipt wellnessTicket={{
                    name: paidHistory.issuedWellnessTicketName,
                    price: paidHistory.wellnessTicketPrice,
                    startColor: paidHistory.issuedWellnessTicketStartColor,
                    endColor: paidHistory.issuedWellnessTicketEndColor,
                    totalUsableCnt: paidHistory.issuedWellnessTicketTotalUsableCnt,
                    textColor: paidHistory.issuedWellnessTicketTextColor,
                    discountedPrice: paidHistory.wellnessTicketDiscountedPrice,
                    startDate: paidHistory.issuedWellnessTicketStartDate,
                    expireDate: paidHistory.issuedWellnessTicketExpireDate
                }} >
                    <div className="title/t1">회원 정보</div>
                    <div className="body/b2" style={{ marginTop: 'var(--space20)' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                            <div className="secondary/s800">이름</div>
                            <div className="base/black">{paidHistory.memberName}</div>
                        </div>
                        <DividerThin />

                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                            <div className="secondary/s800">연락처</div>
                            <div className="base/black">{formatPhoneNumber(paidHistory.memberMobile)}</div>
                        </div>
                        <DividerThin />
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                            <div className="secondary/s800">이메일</div>
                            <div className="base/black">{paidHistory.socialProfileEmail}</div>
                        </div>
                    </div>

                    <div className="title/t1" style={{ marginTop: 'var(--space40)' }}>정기권 정보</div>
                    <div className="body/b2" style={{ marginTop: 'var(--space20)' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                            <div className="secondary/s800">상담 지점</div>
                            <div className="base/black">{converteYogaCenter(paidHistory.tosPayYogaCenterName)}</div>
                        </div>
                        <DividerThin />

                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                            <div className="secondary/s800">수강권명</div>
                            <div className="base/black">{paidHistory.issuedWellnessTicketName}</div>
                        </div>
                        <DividerThin />
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                            <div className="secondary/s800">사용기한</div>
                            <div className="base/black">{dayjs(paidHistory.issuedWellnessTicketStartDate).format('YYYY.MM.DD') + " - " + dayjs(paidHistory.issuedWellnessTicketExpireDate).format('YYYY.MM.DD')}</div>
                        </div>
                    </div>

                    <div className="title/t1" style={{ marginTop: 'var(--space40)' }}>결제 정보</div>
                    {paidHistory.paymentCategoryEnum === "ON_SITE"
                        ? <div style={{ fontSize: '13px', color: "#777", textAlign: "center", marginTop: 'var(--space20)' }}>인앱 결제 내역이 없습니다.</div>
                        : <div className="body/b2" style={{ marginTop: 'var(--space20)' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                                <div className="secondary/s800">수강권 금액</div>
                                <div>{paidHistory.wellnessTicketPrice.toLocaleString()}원</div>
                            </div>
                            <DividerThin />
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                                <div className="secondary/s800">수강권명</div>
                                <div className="base/black">{paidHistory.issuedWellnessTicketName}</div>
                            </div>
                            <DividerThin />
                            {paidHistory.pointValue <= 0
                                ? <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                                        <div className="secondary/s800">포인트 사용</div>
                                        <div className="base/black">{paidHistory.pointValue ? paidHistory.pointValue.toLocaleString() : 0}</div>
                                    </div>
                                    <DividerThin />
                                </>
                                : <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                                        <div className="secondary/s800">포인트 적립</div>
                                        <div className="base/black">{paidHistory.pointValue ? paidHistory.pointValue.toLocaleString() : 0}</div>
                                    </div>
                                    <DividerThin />
                                </>}
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                                <div>
                                    <div className="secondary/s800">쿠폰</div>
                                    <div className="body/b1 secondary/s500">{paidHistory.couponName}</div>
                                </div>
                                <div className="base/black">{paidHistory.couponDiscountValue * paidHistory.wellnessTicketDiscountedPrice === 0 ? '0' : `-${(paidHistory.couponDiscountValue * paidHistory.wellnessTicketDiscountedPrice).toLocaleString()}`} 원</div>
                            </div>
                            <DividerThin />
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space12)' }}>
                                <div className="secondary/s800">할인</div>
                                <div className="base/black">{paidHistory.wellnessTicketDiscountType === 'PERCENT' ?
                                    paidHistory.wellnessTicketDiscountedValue / 100 * paidHistory.wellnessTicketPrice === 0 ? '0' : `-${(paidHistory.wellnessTicketDiscountedValue / 100 * paidHistory.wellnessTicketPrice).toLocaleString()}`
                                    : paidHistory.wellnessTicketDiscountedValue === 0 ? '0' : `-${paidHistory.wellnessTicketDiscountedValue.toLocaleString()}`} 원</div>
                            </div>
                            <DividerThin />
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: 'var(--space20)' }}>
                                <div className="sub-title/st2 secondary/s800">총 결제 금액</div>
                                <div className="sub-title/st3 base/black">{paidHistory.totalPayValue.toLocaleString()} 원</div>
                            </div>
                            <div className="body/b1 secondary/s500" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>회당 금액</div>
                                <div>{priceOnceCalc()}</div>
                            </div>
                        </div>}

                </WellnessTicketReceipt>
            </div>
        </>}
    </>
}

export { PaidHistoryDetail };
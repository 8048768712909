import { Flex, Modal } from "antd";
import React, { useState } from "react"
import { ReactComponent as Info } from '../../../../assets/images/icon/info.svg';

interface IProps {
    title: string
    name?: string
    description?: string
}

const ProgramInfoWithModal = ({ title, name, description }: IProps) => {
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    return (
        <div>
            <Flex
                className="sub-title/st1 secondary/s700"
                style={{ alignItems: 'center' }}
                gap={4}>
                {title}
                <Info width={16} height={16} fill="#676767" style={{ cursor: 'pointer' }} onClick={() => setIsOpenModal(true)} />
            </Flex>
            <Modal
                centered
                open={isOpenModal}
                styles={{
                    content: { padding: "16px" },
                    body: { display: "flex", flexDirection: "column", gap: "16px" },
                    footer: { margin: 0 }
                }}
                onCancel={() => setIsOpenModal(false)}
                closeIcon={false}
                footer={false}
            >
                <div style={{ fontSize: "16px", fontWeight: "600", lineHeight: "19.2px", textAlign: "left" }}>
                    프로그램 안내
                </div>
                <div style={{ fontSize: "13px", whiteSpace: "pre-wrap", padding: "12px", textAlign: "center", backgroundColor: "#F4F4F4", display: 'flex', flexDirection: "column", gap: "12px", borderRadius: "4px" }}>
                    <div style={{ fontSize: "16px", fontWeight: 600, lineHeight: "19.2px", textAlign: "center" }}>
                        {name ? name : "Positive"}
                    </div>
                    <div style={{ fontSize: "14px", lineHeight: "24px", textAlign: "center", color: "#676767" }}>
                        {description ? description : "파지티브 호텔 요가에서 경험할 수 있는 요가로 다양한 동작을 통해 흐름 안에서 의미 있는 움직임을 만듭니다."}
                    </div>
                </div>
                <div style={{ color: "#7D7D7D", border: "1px solid #7D7D7D", borderRadius: '4px', padding: "12px 20px", width: "calc(100% - 42px)", textAlign: "center", cursor: "pointer" }} onClick={() => setIsOpenModal(false)}>
                    확인
                </div>
            </Modal>
        </div>

    )
}

export { ProgramInfoWithModal }
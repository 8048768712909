import { Divider, Flex, Skeleton } from "antd";
import { useMyContext } from "../../../../entities/context";


const TeacherDetailSkeleton = () => {
    const { topHeight } = useMyContext();

    return (
        <div style={{ position: 'absolute', maxWidth: "480px", backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), padding: '0 24px' }}>
            <div>
                <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
                    <div style={{ width: '215px', display: 'inline-block' }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
                    </div>
                </div>
            </div>
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 375, marginTop: 13 }} />
            <div>
                <Skeleton.Button active={true} size={"default"} block={true} style={{ width: 160, height: 22, marginTop: 24 }} />
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 72, marginTop: 12 }} />
                <Divider style={{ margin: '20px 0' }} />
                <Skeleton.Button active={true} size={"default"} block={true} style={{ width: 160, height: 22, marginTop: 24 }} />
                <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 72, marginTop: 12 }} />
                <Divider style={{ margin: '20px 0' }} />
                <Skeleton.Button active={true} size={"default"} block={true} style={{ width: 160, height: 32 }} />
                <div style={{ marginTop: '24px' }}>
                    <Flex gap={16}>
                        <Skeleton.Avatar active={true} size={"default"} shape={'circle'} style={{ width: 72, height: 72 }} />
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 80 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 160, marginTop: 4 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, marginTop: 12 }} />
                        </div>
                    </Flex>
                </div>
                <div style={{ marginTop: '50px' }}>
                    <Flex gap={16}>
                        <Skeleton.Avatar active={true} size={"default"} shape={'circle'} style={{ width: 72, height: 72 }} />
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 80 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 160, marginTop: 4 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, marginTop: 12 }} />
                        </div>
                    </Flex>
                </div>
                <div style={{ marginTop: '50px' }}>
                    <Flex gap={16}>
                        <Skeleton.Avatar active={true} size={"default"} shape={'circle'} style={{ width: 72, height: 72 }} />
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 80 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 160, marginTop: 4 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, marginTop: 12 }} />
                        </div>
                    </Flex>
                </div>
                <div style={{ marginTop: '50px' }}>
                    <Flex gap={16}>
                        <Skeleton.Avatar active={true} size={"default"} shape={'circle'} style={{ width: 72, height: 72 }} />
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 80 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 160, marginTop: 4 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, marginTop: 12 }} />
                        </div>
                    </Flex>
                </div>
                <div style={{ marginTop: '50px' }}>
                    <Flex gap={16}>
                        <Skeleton.Avatar active={true} size={"default"} shape={'circle'} style={{ width: 72, height: 72 }} />
                        <div>
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 80 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 160, marginTop: 4 }} />
                            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, marginTop: 12 }} />
                        </div>
                    </Flex>
                </div>
            </div >
        </div>)
}

export default TeacherDetailSkeleton
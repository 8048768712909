import { TopBar } from "../../../widgets";

const PointUsePolicy = () => {
    return <>
        <TopBar title="포인트 이용기준" rightIcon={false} />
        <div style={{ padding: '24px 20px' }}>
            <div className="body/b2">
                <div>1. 본 기준은 주식회사 파지티브호텔(이하 "회사")이 모바일 어플리케이션 '파지티브호텔 모바일 컨시어지(Positivehotel)'(이하 "어플리케이션")을 통해 가 제공하는 각종 포인트의 적립과 이용 등에 관한 제반사항을 정함을 목적으로 합니다.</div>
                <br />
                <div>2. "회사"는 포인트 적립액 등을 포함하여 포인트 이용기준을 어느 시기에나 변경할 권리를 갖고 있으며 사전 고지 없이 내용을 변경할 수 있습니다. 변경된 내용은 홈페이지 공지, 회원가입 시 공지 또는 전자우편 등의 기타 방법으로 공지함으로써 효력이 발생됩니다. "회사"는 또한 “마지막 수정일”의 날짜를 약관의 가장 상단에 나타낼 것입니다. "회원"은 당사가 변경된 약관의 수정사항을 홈페이지, 어플리케이션 또는 이메일 등의 방법을 통해 공지한 경우, 웹사이트, 어플리케이션 또는 서비스를 지속적으로 이용할 경우에 변경 사항에 대해 동의 한 것으로 간주합니다. 만일 수정 약관에 대해 동의하지 않는 경우에는 사이트와 서비스 이용을 즉시 중단 하거나 탈퇴함으로써 약관의 내용을 거부할 수 있습니다.</div>
                <br />
                <div>3. "회원"은 원칙적으로 "회사"의 "어플리케이션" 또는 서비스 등을 통한 상품과 컨텐츠의 구매/이용금액에 비례하여 "회사"가 정하는 적립률에 따라 포인트를 적립받을 수 있습니다. 구매/이용금액은 상품과 컨텐츠의 가격에서 쿠폰과 보유 포인트를 통한 할인액을 제외한 순 결제금액을 지칭합니다. 적립받은 포인트는 소수점 아래 첫 번째 자리에서 올림 처리됩니다.</div>
                <br />
                <div>4. "회사"는 "회원"의 "어플리케이션" 내 일정 기간 누적 구매/이용금액에 따라 복수의 회원등급을 설정하고 각 "회원"에게 부여한 뒤, 회원등급에 따라 포인트 적립률을 다르게 설정할 수 있습니다. 각 회원등급 별 포인트 적립률은 "어플리케이션" 내 설정 페이지에서 확인할 수 있습니다.</div>
                <br />
                <div>5. "회사"는 정책에 따라 1) 계좌이체 등 특정 결제수단에 대하여, 2) 구매/이용금액 이외의 부대비용 또는 이용계약의 변경이나 위반으로 인하여 추가 발생되는 비용에 대하여, 그리고 3) 쿠폰 사용 및 보유 포인트 차감으로 할인받은 비용 등은 적립대상에서 제외하거나 적립률을 다르게 설정할 수 있습니다.</div>
                <br />
                <div>6. "회사"는 1) 할인이 적용되거나, 2) 세트 또는 묶음으로 판매되거나, 3) 무료 증정 등의 무료 혜택이 적용되는 상품/컨텐츠에 대해서는 적립대상에서 제외하거나 적립률을 다르게 설정할 수 있습니다.</div>
                <br />
                <div>7. 회원은 지급받은 포인트를 "어플리케이션" 내에서 상품과 컨텐츠의 구매/이용에 사용할 수 있습니다. 단, 회사는 정책에 따라 1) 할인이 적용되거나, 2) 세트 또는 묶음으로 판매되거나, 3) 무료 증정 등의 무료 혜택이 적용되는 상품/컨텐츠에 대해서는 포인트 사용을 제한할 수 있습니다.</div>
                <br />
                <div>8. "회사"는 "회원"의 환불 등 기타 사유에 의해 적립된 포인트의 조정이 필요한 경우 환불 후 크레딧을 정산하여 자동 차감할 수 있습니다.</div>
                <br />
                <div>본 포인트 이용기준(임시)은 2024년 1월 말부터 적용됩니다.</div>
            </div>
        </div>
    </>
}
export { PointUsePolicy };
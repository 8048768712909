import MainFooter from "../../layout/MainLayout/footer";
import { ReactComponent as PositivehotelLogo } from "../../assets/images/positivehotelLogo.svg";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TopBar } from "../../widgets";

declare module "react-router-dom" {
    interface URLSearchParams {
        get(grade: MyQueryParams): string | null;
    }
}
const MemberShip = () => {
    const [searchParams] = useSearchParams();
    const [selcetMemberShip, setSelcetMemberShip] = useState<number>(searchParams.get("grade") ? Number(searchParams.get("grade")) : 0);
    const navigate = useNavigate();

    return <>
        <div style={{ background: "#F2F2F2" }}>
            <TopBar title="멤버십 안내" rightIcon={false} />
            <div style={{ margin: "auto 20px" }}>
                <div style={{ textAlign: "center", padding: "46px 0 27px 0" }}>
                    <PositivehotelLogo />
                </div>
                <div style={{ color: "#555", textAlign: "center", fontSize: "16px", display: "flex", height: "20px", flexDirection: "column", justifyContent: "center" }}>
                    정기권을 구매한 파지티브 호텔 회원에게<br />등급별 할인 혜택을 제공합니다.
                </div>
                <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "center", marginTop: "29px" }}>
                    <div onClick={() => setSelcetMemberShip(0)} style={{ width: "33%", height: "31px", backgroundColor: (selcetMemberShip === 0) ? "#FFF743" : "#555555", color: (selcetMemberShip === 0) ? "#000" : "#FFF", fontWeight: (selcetMemberShip === 0) ? "700" : "300", fontSize: "12px", padding: "0px 0px", display: "flex", justifyContent: "center", flexDirection: "column", textAlign: "center", cursor: "pointer" }}>
                        STANDARD
                    </div>
                    <div onClick={() => setSelcetMemberShip(1)} style={{ width: "33%", height: "31px", backgroundColor: (selcetMemberShip === 1) ? "#FFF743" : "#27262B", color: (selcetMemberShip === 1) ? "#000" : "#FFF", fontWeight: (selcetMemberShip === 1) ? "700" : "300", fontSize: "12px", padding: "7px 0px", display: "flex", justifyContent: "center", flexDirection: "column", textAlign: "center", cursor: "pointer" }}>
                        DELUXE
                    </div>
                    <div onClick={() => setSelcetMemberShip(2)} style={{ width: "33%", height: "31px", backgroundColor: (selcetMemberShip === 2) ? "#FFF743" : "#000", color: (selcetMemberShip === 2) ? "#000" : "#FFF", fontWeight: (selcetMemberShip === 2) ? "700" : "300", fontSize: "12px", padding: "15px 0px", display: "flex", justifyContent: "center", flexDirection: "column", textAlign: "center", cursor: "pointer" }}>
                        <div>
                            SUITE
                        </div>
                        <div>
                            (ALL PASS)
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ margin: "29px auto 18px 0", borderBottom: "1px solid #555", paddingBottom: "15px" }}>
                        <div style={{ fontSize: "22px", fontWeight: "700", marginBottom: '5px' }}>
                            {(selcetMemberShip === 0) ? "STANDARD" : (selcetMemberShip === 1) ? "DELUXE" : "SUITE (ALL PASS)"}
                        </div>
                        <div style={{ color: "#555", fontSize: "13px" }}>
                            {(selcetMemberShip === 0) ? "파지티브 호텔 앱 다운로드 고객" : (selcetMemberShip === 1) ? <>파지티브 호텔 요가 강남 스튜디오 정기권 구매 고객 (1회권 제외)</> : <>파지티브 호텔 요가 프리미엄 센터 (선릉/도산) 정기권 구매 고객 강남 스튜디오 포함 전 센터 이용 가능 (1회권 제외)</>}
                        </div>
                    </div>
                    {(selcetMemberShip === 0) ? <div style={{ fontSize: "13px" }}>
                        <div style={{ display: '-webkit-flex', gap: "10%" }}>
                            <div style={{ width: "30%" }}>적립 혜택</div>
                            <div>3%</div>
                        </div>
                        <div style={{ display: '-webkit-flex', fontSize: "13px", marginTop: '20px', gap: "10%" }}>
                            <div style={{ width: "30%" }}>쿠폰</div>
                            <div>
                                {/* <div>매월 10% 식사 할인권 2매</div>
                                <div style={{ marginTop: '6px' }}>매월 10% MD 추가할인권 2매</div> */}
                                <div>Coming Soon</div>
                            </div>
                        </div>
                    </div> : (selcetMemberShip === 1) ? <div style={{ fontSize: "13px" }}>
                        <div style={{ display: '-webkit-flex', gap: "10%" }}>
                            <div style={{ width: "30%" }}>적립 혜택</div>
                            <div>3%</div>
                        </div>
                        <div style={{ display: '-webkit-flex', fontSize: "13px", marginTop: '20px', gap: "10%" }}>
                            <div style={{ width: "30%" }}>드링크 서비스</div>
                            {/* <div>매월 아메리카노 1잔</div> */}
                            <div>Coming Soon</div>
                        </div>
                        <div style={{ display: '-webkit-flex', fontSize: "13px", marginTop: '20px', gap: "10%" }}>
                            <div style={{ width: "30%" }}>쿠폰</div>
                            <div>
                                {/* <div>매월 10% 식사 할인권 2매</div>
                                <div style={{ marginTop: '6px' }}>매월 10% MD 추가할인권 2매</div> */}
                                <div>Coming Soon</div>
                            </div>
                        </div>
                        <div style={{ display: '-webkit-flex', fontSize: "13px", marginTop: '20px', gap: "10%" }}>
                            <div style={{ width: "30%" }}>멤버쉽 라운지</div>
                            <div>
                                {/* <div>매월 10% 식사 할인권 2매</div>
                                <div style={{ marginTop: '6px' }}>매월 10% MD 추가할인권 2매</div> */}
                                <div>Coming Soon</div>
                            </div>
                        </div>
                    </div> : (selcetMemberShip === 2) ? <div style={{ fontSize: "13px" }}>
                        <div style={{ display: '-webkit-flex', gap: "10%" }}>
                            <div style={{ width: "30%" }}>적립 혜택</div>
                            <div>3%</div>
                        </div>
                        <div style={{ display: '-webkit-flex', fontSize: "13px", marginTop: '20px', gap: "10%" }}>
                            <div style={{ width: "30%" }}>드링크 서비스</div>
                            <div>
                                {/* <div>매월 아메리카노 1잔</div>
                                <div style={{ marginTop: '6px' }}>매월 웰니스 스무디 1잔</div> */}
                                <div>Coming Soon</div>
                            </div>
                        </div>
                        <div style={{ display: '-webkit-flex', fontSize: "13px", marginTop: '20px', gap: "10%" }}>
                            <div style={{ width: "30%" }}>쿠폰</div>
                            <div>
                                {/* <div>매월 10% 식사 할인권 2매</div>
                                <div style={{ marginTop: '6px' }}>매월 10% MD 추가할인권 2매</div> */}
                                <div>Coming Soon</div>
                            </div>
                        </div>
                        <div style={{ display: '-webkit-flex', fontSize: "13px", marginTop: '20px', gap: "10%" }}>
                            <div style={{ width: "30%" }}>멤버쉽 라운지</div>
                            <div>
                                {/* <div>매월 10% 식사 할인권 2매</div>
                                <div style={{ marginTop: '6px' }}>매월 10% MD 추가할인권 2매</div> */}
                                <div>Coming Soon</div>
                            </div>
                        </div>
                        <div style={{ display: '-webkit-flex', fontSize: "13px", marginTop: '20px', gap: "10%" }}>
                            <div style={{ width: "30%" }}>제품 제공</div>
                            <div>
                                {/* <div>매월 10% 식사 할인권 2매</div>
                                <div style={{ marginTop: '6px' }}>매월 10% MD 추가할인권 2매</div> */}
                                <div>Coming Soon</div>
                            </div>
                        </div>
                    </div> : <></>
                    }

                    <div
                        onClick={() => navigate("/wellness-ticket-group")}
                        style={{ height: "56px", backgroundColor: "#000000", color: "#FFF", display: "flex", alignItems: 'center', justifyContent: 'center', fontSize: "18px", fontWeight: "500", margin: "30px 0 30px 0", cursor: "pointer" }}>
                        정기권 구매하러 가기
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "#D9D9D9", color: "#555", fontSize: "13px", width: "100%", maxWidth: "480px", marginTop: '30px' }}>
                <div style={{ padding: '20px', lineHeight: '20px' }}>
                    <div>
                        &middot; 모든 멤버십 혜택은 앱 다운로드를 필수로 합니다.
                    </div>
                    <div>
                        &middot; 포인트는 클래스, F&B, MD 이용시 적립됩니다.
                    </div>
                    <div>
                        &middot; 드링크 서비스는 변경될 수 있습니다.
                    </div>
                    <div>
                        &middot; 쿠폰 혜택은 변경될 수 있습니다.
                    </div>
                    <div>
                        &middot; 쿠폰은 1월 31일부터 발행 및 사용가능합니다.
                    </div>
                </div>
            </div>
            <MainFooter activeButton="my" />
        </div >
    </>
}
export default MemberShip;
import { Skeleton } from "antd";
import { useMyContext } from "../../../../entities/context";

const HomeSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<div style={{ position: 'absolute', maxWidth: "480px", backgroundColor: 'white', zIndex: 3 }}>
        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 624, paddingTop: (topHeight + 'px') }} />
        <div style={{ padding: '0 24px 118px' }}>
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 18, marginTop: 34, width: 196 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 180, marginTop: 32 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 38, marginTop: 16 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 38, marginTop: 8 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 16, width: 44 }} />

            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 180, marginTop: 32 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 38, marginTop: 16 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 38, marginTop: 8 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 16, width: 44 }} />

            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 180, marginTop: 32 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 38, marginTop: 16 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 38, marginTop: 8 }} />
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, marginTop: 16, width: 44 }} />
        </div>
    </div>)
}

export default HomeSkeleton;
import dayjs from "dayjs"
import { RequestComponent } from "../../../../shared"
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import { isLogin } from "../../../../utils";

interface IProps {
    title: string
    issuedWellnessTicketList: IIssuedWellnessTicketByMemberIdAndWellnessLectureIdResponseV2[]
    isReservation: boolean
    selectedId?: number
    setSelectedId: Function
}
const MyIssuedWellnessTicketAvaiable = ({ title, issuedWellnessTicketList, isReservation, selectedId, setSelectedId }: IProps) => {



    return <>
        <div>
            <div className="sub-title/st3" style={{ paddingBlock: 6 }}>{title}</div>
        </div>
        <div style={{ marginTop: 16 }}>
            {!isLogin()
                ? <RequestComponent text={'로그인이 필요 합니다.'} />
                : isReservation
                    ? <RequestComponent text={`이미 예약된 수업입니다.`} />
                    : issuedWellnessTicketList.length === 0
                        ? <RequestComponent text={`${title}이 없습니다.`} />
                        : issuedWellnessTicketList.map(issuedWellnessTicket =>
                            <Flex
                                key={issuedWellnessTicket.issuedWellnessTicketId}
                                onClick={() => issuedWellnessTicket.issuedWellnessTicketIsUsableDate && setSelectedId(issuedWellnessTicket.issuedWellnessTicketId)}
                                style={{ padding: 12, backgroundColor: 'var(--secondarys100)', marginBottom: 12, justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
                            >
                                <Flex gap={8}>
                                    {(selectedId === issuedWellnessTicket.issuedWellnessTicketId)
                                        ? <CheckCircleFilled style={{ fontSize: '20px' }} />
                                        : <CheckCircleOutlined style={{ fontSize: '20px', color: '#AFAFAF' }} />
                                    }
                                    <div className="sub-title/st2" style={{ fontWeight: issuedWellnessTicket.issuedWellnessTicketIsUsableDate ? "700" : "400" }}>{issuedWellnessTicket.issuedWellnessTicketName}</div>
                                    <div className="body/b2">{issuedWellnessTicket.issuedWellnessTicketRemainingCnt}회 사용 가능</div>
                                </Flex>
                                <div className="body/b1 secondary/s700">
                                    {issuedWellnessTicket.issuedWellnessTicketIsUsableDate ? dayjs(issuedWellnessTicket.issuedWellnessTicketExpireDate).format("YYYY.MM.DD") + "까지" : "| 사용 불가 날짜 |"}
                                </div>
                            </Flex>
                        )
            }
        </div>
    </>

}

export { MyIssuedWellnessTicketAvaiable }
interface IProps {
    title?: string | React.ReactNode;
    content?: string | React.ReactNode;
    styles?: { container?: React.CSSProperties, title?: React.CSSProperties, content?: React.CSSProperties };
    onClick?: () => void;
}

const InlineTitleAndContent = ({ title = '', content = '', styles, onClick }: IProps) => {
    return <div onClick={onClick} style={{ display: 'flex', ...styles?.container }}>
        <div className="sub-title/st2 secondary/s700" style={{ minWidth: '50px', ...styles?.title }}>{title}</div>
        <div className="body/b2 base/black" style={{ marginLeft: 'var(--space12)', ...styles?.content }}>{content}</div>
    </div>
}
export { InlineTitleAndContent }
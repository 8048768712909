import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getNaverUserByAccessToken } from "../../../../service";
import { getUrl, removeUrl } from "../../../../service";
import { updateFcmToken } from "../../../../service";
import { isLogin } from "../../../../utils";
import PolicyDataTransfer from "../../../../components/modal/policyDataTransfer";
import { getPolicyDataTransferByToken } from "../../../../service";

declare module "react-router-dom" {
    interface URLSearchParams {
        get(name: MyQueryParams): string | null;
    }
}

const NaverLoginMobileAppCallback = () => {
    const navigate = useNavigate();
    const NAVER_AUTH_URL = `nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_LOGIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NAVER_LOGIN_REDIRECT_URI}&state=${process.env.REACT_APP_NAVER_LOGIN_STATE}`;
    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get('accessToken')?.replaceAll(" ", "+");
    const refreshToken = searchParams.get('refreshToken')?.replaceAll(" ", "+");
    const tokenType = searchParams.get('tokenType')?.replaceAll(" ", "+");
    const expiresAt = searchParams.get('expiresAt')?.replaceAll(" ", "+");
    const [policyModalStatus, setPolicyModalStatus] = useState<boolean>(false);
    useEffect(() => {
        requestNaveruserByAccessToken();
    }, [])

    const requestNaveruserByAccessToken = async () => {
        if (!accessToken || !refreshToken || !tokenType || !expiresAt) {
            navigate("//" + NAVER_AUTH_URL)
            return;
        }
        try {

            await getNaverUserByAccessToken({ accessToken, refreshToken, tokenType, expiresAt })
            if (window.native_yoga_bridge) {
                window.native_yoga_bridge.sendMessage(JSON.stringify({
                    functionName: 'getFCMToken',
                    data: {}
                }));
                window.getFCMToken = (fcmToken: string) => {
                    isLogin() && updateFcmToken(({ fcmToken: fcmToken }))
                }
            }
            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) {
                window.webkit.messageHandlers.native_yoga_bridge.postMessage(JSON.stringify({
                    functionName: 'getFCMToken',
                    data: {}
                }))
                window.getFCMToken = (fcmToken: string) => {
                    isLogin() && updateFcmToken(({ fcmToken: fcmToken }))
                }
            }
            const beforeUrl = getUrl();
            removeUrl();
            getPolicyDataTransferByToken()
                .then(res => {
                    if (!res.data.isPersonInfoPolicyAgree || !res.data.isServiceUseTermAgree) { setPolicyModalStatus(true) } else { navigate(beforeUrl ? beforeUrl : '/', { replace: true }) }
                })
                .catch(error => console.error("error", error))
        } catch (error) {
            console.error('error', error);
            navigate('/login')
        }
    }

    return <>
        <PolicyDataTransfer propsModalStatus={policyModalStatus} />
    </>
}
export default NaverLoginMobileAppCallback;
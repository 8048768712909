import { AxiosResponse } from 'axios';
import api from './Api'

export const getAllPaidHistoryByMemberIdAndPaidDateTimeBetween = (memberId: string, startDateTime: string, endDateTime: string): Promise<AxiosResponse<IPaidHistoryBasicWebResponseV2[]>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/paid-history/my/web/v2/${memberId}?startDateTime=${encodeURIComponent(startDateTime)}&endDateTime=${encodeURIComponent(endDateTime)}`)
}


export const getPaidHistoryByIssuedWellnessTicketId = (issuedWellnessTicketId: string): Promise<AxiosResponse<IPaidHistoryDetailWebResponseV2>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/paid-history/my/detail/web/v2/${issuedWellnessTicketId}`)
}
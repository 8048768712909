import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MyEventSkeleton from "../../../components/skeleton/my/myEventSkeleton";
import { getEventList } from "../../../service";
import { TopBar } from "../../../widgets";

const Event = () => {
    const [eventList, setEventList] = useState<IEventBoard[]>([]);
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        getEventList()
            .then(res => { setEventList(res.data) })
            .catch(err => console.error(err))
            .finally(() => setIsInitLoading(false))
    }, [])

    return <>
        {isInitLoading ? <MyEventSkeleton /> : <>
            <TopBar title="이벤트" rightIcon={false} />
            <div style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "40px" }}>
                {(eventList?.length ?? 0) > 0 && eventList?.filter((e) => e.isShow).map(event =>
                    <div key={event.id} style={{ display: "flex", flexDirection: "column", gap: "8px" }} onClick={() => navigate(`/event/${event.id ?? 1}`)}>
                        <div style={{ borderRadius: "12px" }}>
                            <img alt="event" style={{ borderRadius: "12px" }} src={event?.thumbnailImgUrl ?? ""} width="100%" />
                        </div>
                        <div>
                            <div style={{ fontSize: "22px", fontWeight: 600, fontFamily: "Pretendard", height: "31px", lineHeight: "31px" }}>
                                {event.title}
                            </div>
                            <div style={{ color: "#7D7D7D", fontSize: "12px", height: "20px", lineHeight: "20px" }}>
                                {event?.startDate ? event?.startDate?.slice(0, 10)?.replaceAll("-", ". ") : "2024-04-02T13:00:00"?.slice(0, 10)?.replaceAll("-", ". ")} - {event?.endDate ? event?.endDate?.slice(0, 10)?.replaceAll("-", ". ") : "이벤트 종료 시"}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>}
    </>
}

export default Event;
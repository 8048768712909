import { AxiosResponse } from 'axios';
import api from './Api'

export const getNoticePopup = (): Promise<AxiosResponse<Array<INoticePopup>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/notice-popup`);
}
export const getNoticePopupById = (id: string): Promise<AxiosResponse<INoticePopup, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/notice-popup/${id}`);
}
export const getNoticePopupForPopup = (): Promise<AxiosResponse<INoticePopup, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/notice-popup/popup`);
}

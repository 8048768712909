import { useParams } from "react-router-dom";
import { Button, Flex, Modal } from "antd";
import { useEffect, useState } from "react";
import { getIssuedCouponMobileById, issuedCouponUse } from "../../../service";
import { OnlyBarcodeFullScreenModal } from "../../../shared";
import { TopBar } from "../../../widgets";

const IssuedCouponDetail = () => {
    const { id } = useParams();
    const [issuedCouponData, setIssuedCouponData] = useState<IIssuedCouponMobileDetail | undefined>(undefined);
    const [couponModalStatus, setCouponModalStatus] = useState<boolean>(false);
    const [isOpenUseCouponModal, setIsOpenUseCouponModal] = useState<boolean>(false);

    useEffect(() => {
        requestIssuedCouponData();
    }, [id])

    const requestIssuedCouponData = () => {
        if (!id) return;

        getIssuedCouponMobileById(id)
            .then((res) => {
                setIssuedCouponData(res.data);
            }).catch((error) => console.error('error', error))

    }
    const clickUseCoponButton = () => {
        if (!id) return;
        issuedCouponUse(id)
            .then((res) => {
                setIsOpenUseCouponModal(false);
                requestIssuedCouponData();
            })
            .catch((error) => console.error('error', error))
    }

    return <>
        <TopBar title="쿠폰 상세" rightIcon={false} />
        <div style={{ backgroundColor: "#F2F2F2", minHeight: "100vh", padding: 20 }}>
            {issuedCouponData && <>
                <div style={{ backgroundColor: "#FFF" }}>
                    <div style={{ borderBottom: "1px dotted #DDDDDD", padding: "32px 0", textAlign: "center" }}>
                        <div className="body/b2">
                            {issuedCouponData.couponDto.name}
                        </div>
                        <div className="title/t4" style={{ margin: "8px 0 11px 0" }}>
                            {issuedCouponData.couponDto.shortDescription}
                        </div>
                        <div className="body/b1 secondary/s700">
                            유효기간&nbsp; {`${issuedCouponData.expireDate.slice(0, 4)}년 ${issuedCouponData.expireDate.slice(5, 7)}월 ${issuedCouponData.expireDate.slice(8, 10)}일까지`}
                        </div>
                    </div>

                    <div style={{ padding: "25px 0", position: "relative" }}>
                        <div onClick={() => !(issuedCouponData.isUsed || issuedCouponData.isDelete) && setCouponModalStatus(true)}>
                            <img src={issuedCouponData.barcodeUrl} style={{ width: "100%", height: "47.7px" }} />
                            {(issuedCouponData.isUsed || issuedCouponData.isDelete) &&
                                <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100px", background: '#000000B2', display: "flex", alignItems: "center", justifyContent: "center", color: " white", }}>
                                    <div className="sub-title/st3">
                                        {issuedCouponData.isUsed ? "사용이 완료되었습니다" : issuedCouponData.isDelete && "기간이 만료되었습니다"}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
            }
            <div style={{ marginTop: "24px" }}>
                {(issuedCouponData && (issuedCouponData.isUsed || issuedCouponData.isDelete))
                    ? <div
                        className="sub-title/st3"
                        style={{ padding: '16px', backgroundColor: 'var(--secondarys200)', color: 'var(--secondarys400)', textAlign: 'center', borderRadius: '5px' }}
                    >
                        스탭 확인
                    </div>
                    : <div
                        className="sub-title/st3"
                        style={{ padding: '16px', backgroundColor: 'black', color: 'white', textAlign: 'center', borderRadius: '5px', cursor: 'pointer' }}
                        onClick={() => setIsOpenUseCouponModal(true)}
                    >
                        스탭 확인
                    </div>
                }
                <div className="sub-title/st1" style={{ color: 'var(--secondarys700)', marginTop: '16px', textAlign: 'center' }}>스탭에게 쿠폰을 보여주세요</div>
            </div>

            <div style={{ marginTop: "24px" }}>
                <div className="sub-title/st1" style={{ color: "var(--secondarys700)" }}>
                    유의사항
                    <div className="body/b1" style={{ marginTop: "4px" }}>
                        <div>&#183; 할인 쿠폰은 다른 쿠폰과 중복 사용이 불가능합니다.</div>
                        <div>&#183; 제휴 및 임직원 할인 사용 시 쿠폰 중복 적용이 불가능합니다.</div>
                    </div>
                </div>
            </div>
        </div>
        {issuedCouponData && <OnlyBarcodeFullScreenModal isOpen={couponModalStatus} setIsOpen={setCouponModalStatus} barcodeUrl={issuedCouponData.barcodeUrl} />}
        <Modal
            open={isOpenUseCouponModal}
            closable={false}
            centered
            onOk={() => console.log('ok')}
            onCancel={() => setIsOpenUseCouponModal(false)}
            footer={[
                <Flex style={{ marginTop: '25px' }}>
                    <Button
                        key="back"
                        onClick={() => setIsOpenUseCouponModal(false)}
                        style={{ width: '50%', height: '50px', fontSize: '16px', fontWeight: '600' }}>
                        취소
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => clickUseCoponButton()}
                        style={{ width: '50%', height: '50px', fontSize: '16px', fontWeight: '600' }}>
                        사용
                    </Button>
                </Flex>
            ]}
        >
            <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '22px', fontWeight: '600' }}>쿠폰을 사용하시겠습니까?</div>
                <div style={{ marginTop: '15px' }}>사용을 완료하면 취소가 불가합니다.</div>
            </div>
        </Modal>
    </>
}

export default IssuedCouponDetail;
import { Flex } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom"
import { ReservationBadge } from "../../../reservationBadge";
import { DividerThin } from "../../../etc/dividerThin";

interface IProps {
    dataList: Array<IYogaLectureListByRangeDateAndYogaCenterIdGetResponse> | null
}

const LectureCardInReservation = ({ dataList }: IProps) => {
    const navigate = useNavigate();

    const getReservationBadge = (yogaLecture: IYogaLectureListByRangeDateAndYogaCenterIdGetResponse) => {

        if (yogaLecture.isReservation) {
            if (yogaLecture.isCreatedReview) {
                if (dayjs(yogaLecture.endDateTime) < dayjs() && dayjs(yogaLecture.endDateTime) > dayjs().add(-4, 'day')) {
                    return <ReservationBadge status="ReviewUpdate" onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        event.preventDefault();
                        event.stopPropagation();
                        navigate(`/review/update/${yogaLecture.id}`)
                    }} />
                } else {
                    return <ReservationBadge status="ReviewView" onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        event.preventDefault();
                        event.stopPropagation();
                        navigate(`/review/view/${yogaLecture.id}`)
                    }} />
                }
            }

            if (yogaLecture.checkInStatus === 'ABSENT') {
                return <ReservationBadge status="Absent" />
            }


            if (dayjs(yogaLecture.endDateTime) < dayjs() && dayjs(yogaLecture.endDateTime) > dayjs().add(-4, 'day')) {
                return <ReservationBadge status="ReviewCreate" onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    event.preventDefault();
                    event.stopPropagation();
                    navigate(`/review/create/${yogaLecture.id}`)
                }} />
            }

            if (yogaLecture.checkInStatus === 'CHECK_IN') {
                return <ReservationBadge status="CheckIn" />
            }


            return <ReservationBadge status="Complete" />
        }

        if (dayjs().isAfter(dayjs(yogaLecture.startDateTime))) {
            return <ReservationBadge status="Close" />
        }

        if (yogaLecture.maxReservationMember - yogaLecture.nowReservationCnt > 0) {
            return <ReservationBadge status="Available" />
        }

        return <ReservationBadge status="Close" />
    }

    return <>
        <DividerThin style={{ height: '6px', marginTop: '12px' }} />
        <div className="sub-title/st3" style={{ marginTop: '24px', paddingInline: '20px' }}>
            수업 선택
            {dataList === null
                ? <div style={{ textAlign: "center", fontSize: "14px" }}>조회할 수업이 없습니다.</div>
                : dataList.length === 0
                    ? <div className="body/b2 secondary/s600" style={{ textAlign: 'center', marginTop: 24 }}>예약 가능한 수업이 없습니다</div>
                    : <div style={{ paddingTop: 4 }}>
                        {dataList.map((yogaLecture, idx) => <div
                            key={idx}
                            style={{ marginTop: 20, padding: '8px 0', cursor: 'pointer' }}
                            onClick={() => navigate(`/lecture/${yogaLecture.id}`)}
                        >
                            <Flex style={{ justifyContent: 'space-between' }} gap={16}>
                                <div style={{ width: '25%' }}>
                                    <img src={yogaLecture.profileImageUrl} alt="수업사진" width={'100%'} style={{ borderRadius: "999px" }} />
                                </div>
                                <div style={{ width: '75%' }}>
                                    <Flex style={{ justifyContent: 'space-between' }}>
                                        <div>
                                            <div className="sub-title/st1">{yogaLecture.yogaProgramName && (yogaLecture.yogaCenterName !== "강남") ? yogaLecture.yogaProgramName : ""}</div>
                                            <div className="sub-title/st3" style={{ marginTop: 2 }}>{yogaLecture.wellnessLectureName}</div>
                                        </div>
                                        <div>
                                            {getReservationBadge(yogaLecture)}
                                        </div>
                                    </Flex>
                                    <Flex style={{ justifyContent: 'space-between', marginTop: 12 }}>
                                        <div className="body/b1 secondary/s500">{dayjs(yogaLecture.startDateTime).format('HH:mm')} - {dayjs(yogaLecture.endDateTime).format('HH:mm')}</div>
                                        <div className="body/b1 secondary/s500">{yogaLecture.teacherName} 코치 | {yogaLecture.yogaCenterName} {yogaLecture.room}</div>
                                    </Flex>
                                </div>
                            </Flex>
                        </div>
                        )}
                    </div>
            }</div>
    </>
}

export { LectureCardInReservation }
import { useEffect, useState } from "react";
import ReservationSkeleton from "../../components/skeleton/reservation/reservationSkeleton";
import { YogaCenterSelcetorModal } from "./ui/yogaCenterSelector";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/images/icon/arrow-right.svg";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../modules";
import { ThunkDispatch } from "redux-thunk";
import { LectureCardInReservation, DateSelectorCarousel, GnbFooter } from "../../shared";
import { isLogin } from "../../utils";
import { getAllWellnessLectureByYogaCenterIdAndMemberIdAndStartDateTimeBetweenAndIsDeleteFalse, getMyReservedDateList, getUsedYogaCenterIdByMobile } from "../../service";
import { useMyContext } from "../../entities/context";
import WellnessLectureSkeleton from "../../components/skeleton/reservation/wellnessLectureSkeleton";
import { getSelectedYogaCenteAndDaterAsync } from "../../modules/selectedYogaCenterAndDate";
import CalendarReservation from "./ui/calendarReservation";

const Reservation = () => {
    const location = useLocation();
    const update = getSelectedYogaCenteAndDaterAsync;
    const dispatch: ThunkDispatch<any, any, any> = useDispatch();
    const navigate = useNavigate();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const [isLectureLoading, setIsLectureLoading] = useState<boolean>(false);
    const [reservedDateList, setReservedDateList] = useState<Array<string> | undefined>(undefined);
    const { yogaCenterId, date } = useSelector((state: RootState) => state.selectedYogaCenterAndDate);
    const [lectureList, setLectureList] = useState<Array<IYogaLectureListByRangeDateAndYogaCenterIdGetResponse> | undefined>(undefined);
    const paramId = location.search && new URLSearchParams(location.search).get("id")
    const { topHeight } = useMyContext();

    useEffect(() => {
        if (paramId) {
            dispatch(update({ yogaCenterId: Number(paramId), date: date }))
            return;
        }

        if (isLogin()) {
            getUsedYogaCenterIdByMobile()
                .then((res) => {
                    let reduxYogaCenterId = 3;
                    if (yogaCenterId) {
                        reduxYogaCenterId = yogaCenterId;
                    } else if (res.data.selectYogaCenter.id) {
                        reduxYogaCenterId = res.data.selectYogaCenter.id;
                    }
                    dispatch(update({ yogaCenterId: reduxYogaCenterId, date: date }))
                })
                .catch(error => console.error("Error: ", error))
            return;
        }
    }, [])

    useEffect(() => {
        requesstAllWellnessLectureByYogaCenterIdAndMemberIdAndStartDateTimeBetweenAndIsDeleteFalse();
    }, [yogaCenterId, date])

    const requesstAllWellnessLectureByYogaCenterIdAndMemberIdAndStartDateTimeBetweenAndIsDeleteFalse = async () => {
        if (yogaCenterId) {
            const dateWithCurrentTime = dayjs(date).hour(dayjs().hour()).minute(dayjs().minute()).second(dayjs().second()).format('YYYY-MM-DDT00:00:00Z');

            try {
                setIsLectureLoading(true);

                if (isLogin()) {
                    getMyReservedDateList(yogaCenterId)
                        .then((res) => setReservedDateList(res.data.map(e => dayjs(e).format('YYYY-MM-DD'))))
                        .catch((error) => console.error('error', error));
                } else {
                    setReservedDateList([]);
                }

                const res = await getAllWellnessLectureByYogaCenterIdAndMemberIdAndStartDateTimeBetweenAndIsDeleteFalse(yogaCenterId, dateWithCurrentTime, dayjs(date).format('YYYY-MM-DDT23:59:59Z'));
                setLectureList(res.data);
                if (res.data !== null && res.data.length > 0) {
                    const img = new Image();
                    if (!res.data[0].profileImageUrl) {
                        setIsInitLoading(false);
                        setIsLectureLoading(false);
                    } else {
                        img.src = res.data[0].profileImageUrl
                        img.onload = () => {
                            setIsInitLoading(false);
                            setIsLectureLoading(false);
                        }
                    }
                }
                if (res.data.length === 0) {
                    setIsInitLoading(false);
                    setIsLectureLoading(false);
                }
            } catch (error) {
                console.error("Error: ", error)
            }
        }
    }

    const onClickDateSelector = (date: string) => {
        dispatch(update({ yogaCenterId: yogaCenterId, date: date }))
    }

    return (<>
        {isInitLoading && <ReservationSkeleton />}
        <div style={{ paddingTop: (topHeight + 'px') }}></div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'baseline', padding: '13px 20px' }} >
            {!isInitLoading && <YogaCenterSelcetorModal selectedYogaCenterId={yogaCenterId} setSelectedYogaCenterId={(selectedYogaCenterId: number) => dispatch(update({ yogaCenterId: selectedYogaCenterId, date: date }))} />}
            <div onClick={() => navigate(`/yoga-center/${yogaCenterId}`)}>
                <div style={{ display: "flex", gap: "4px", cursor: "pointer", alignItems: 'center' }}>
                    <div className="btn/text-btn secondary/s600">스튜디오 소개</div>
                    <ArrowRight width={12} height={12} stroke={"#737373"} />
                </div>
            </div>
        </div>

        {reservedDateList && <>
            <CalendarReservation reservedDateList={reservedDateList} selectedDate={dayjs(date).format("YYYY-MM-DD") ?? dayjs().format("YYYY-MM-DD")} onClick={onClickDateSelector} />
            {/* <DateSelectorCarousel reservedDateList={reservedDateList} selectedDate={dayjs(date).format("YYYY-MM-DD") ?? dayjs().format("YYYY-MM-DD")} onClick={onClickDateSelector} /> */}
        </>
        }
        {!isInitLoading && !isLectureLoading && lectureList
            ? <LectureCardInReservation dataList={lectureList} />
            : <WellnessLectureSkeleton />}
        <div style={{ height: '100px' }}></div>
        <GnbFooter activeButton='reservation' />
    </>)
}

export { Reservation }





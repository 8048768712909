import { useNavigate } from "react-router-dom";
import { ReactComponent as Home } from "../../../assets/images/icon/home.svg";
import { ReactComponent as Booking } from "../../../assets/images/icon/booking.svg";
import { ReactComponent as My } from "../../../assets/images/icon/my.svg";
interface IProps {
    activeButton?: string;
}

const MainFooter = ({ activeButton }: IProps) => {
    const navigate = useNavigate();

    return <>
        <div style={{ height: '72px' }}></div>
        {(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) && <div style={{ height: '24px' }}></div>}
        {/* footer start */}
        <div style={{ position: "fixed", bottom: "0", width: "100%", maxWidth: "480px" }}>
            <div style={{ backgroundColor: "black", display: "flex", justifyContent: "space-evenly" }} >
                <div style={{ color: 'white', textAlign: 'center', fontSize: "9px", cursor: "pointer", padding: '15px' }} onClick={() => navigate("/")}>
                    {activeButton && activeButton === 'home' ? <>
                        <Home width={24} height={24} fill="#fff743" />
                        <div style={{ marginTop: '4px', color: '#fff743' }}>홈</div>
                    </> : <>
                        <Home width={24} height={24} fill="#555555" />
                        <div style={{ marginTop: '4px' }}>홈</div>
                    </>}
                </div>
                <div style={{ color: 'white', textAlign: 'center', fontSize: "9px", cursor: "pointer", padding: '15px' }} onClick={() => navigate("/reservation")}>
                    {activeButton && activeButton === 'reservation' ? <>
                        <Booking width={24} height={24} fill="#FFF743" />
                        <div style={{ marginTop: '4px', color: '#fff743' }}>수업 예약</div>
                    </> : <>
                        <Booking width={24} height={24} fill="#555555" />
                        <div style={{ marginTop: '4px' }}>수업 예약</div>
                    </>}
                </div>
                <div style={{ color: 'white', textAlign: 'center', fontSize: "9px", cursor: "pointer", padding: '15px' }} onClick={() => navigate("/my")}>
                    {activeButton && activeButton === 'my' ? <>
                        <My width={24} height={24} fill="#FFF743" />
                        <div style={{ marginTop: '4px', color: '#fff743' }}>MY</div>
                    </> : <>
                        <My width={24} height={24} fill="#555555" />
                        <div style={{ marginTop: '4px' }}>MY</div>
                    </>}
                </div>
            </div >
            {(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) && <div style={{ height: '24px', backgroundColor: 'black', width: '100%' }}></div>}
        </div >
        {/* footer end */}
    </>
}

export default MainFooter;
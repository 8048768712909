import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { getSafeAreaTopHeight } from '../../utils';

interface MyContextType {
    topHeight: string;
    currentDeviceBuildVersion: boolean;
}

const MyContext = createContext<MyContextType | undefined>(undefined);

export const MyContextProvider = ({ children }: { children: ReactNode }) => {
    const [topHeight, setTopHeight] = useState<string>('0');
    const [currentDeviceBuildVersion, setCurrentDeviceBuildVersion] = useState<boolean>(false);
    useEffect(() => {
        getSafeAreaTopHeight(setTopHeight, setCurrentDeviceBuildVersion)
    }, [])

    return (
        <MyContext.Provider value={{ topHeight, currentDeviceBuildVersion }}>
            {children}
        </MyContext.Provider>
    );
};

export const useMyContext = () => {
    const context = useContext(MyContext);
    if (!context) {
        throw new Error('useMyContext must be used within a MyContextProvider');
    }
    return context;
};
import { AxiosResponse } from 'axios';
import api from './Api'


export const createWellnessLectureReview = (params: ICreateWellnessLectureReviewRequest): Promise<AxiosResponse<boolean>> => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/wellness-lecture/review/web/v2`, params)
}

export const getWellnessLectureReviewByWellnessLectureId = (wellnessLectureId: string): Promise<AxiosResponse<IWellnessLectureReview>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/wellness-lecture/review/web/v2/${wellnessLectureId}`)
}

export const updateWellnessLectureReview = (params: IUpdateWellnessLectureReviewRequest): Promise<AxiosResponse<boolean>> => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/wellness-lecture/review/web/v2`, params)
}

export const deleteWellnessLectureReview = (wellnessLectureReviewId: string): Promise<AxiosResponse<boolean>> => {
    return api.delete(`${process.env.REACT_APP_API_DOMAIN}/wellness-lecture/review/web/v2/${wellnessLectureReviewId}`)
}

import { useEffect, useState } from "react";
import { Carousel } from "../../shared"
import { getBannerListForMobile } from "../../service";

interface IProps {
    setImageLoaded?: Function;
}
const BannerCarousel = ({ setImageLoaded }: IProps) => {
    const [bannerList, setBannerList] = useState<IBanner[]>([]);
    const defaultBannerList: IBanner[] = [{
        id: 0,
        name: 'basic',
        startDateTime: '1999-01-01',
        endDateTime: '3000-12-31',
        imageUrl: 'https://img.positivehotel.io/2024/7/17/240717-yoga-banner.jpg',
        showOrdering: '1',
        linkUrl: '/',
        isShow: true,
        createdDate: '2024-09-02',
        lastUpdatedDate: '2024-09-02',
    }]
    useEffect(() => {
        getBannerListForMobile()
            .then(res => {
                const img = new Image();
                if (res.data.length < 1) {
                    setBannerList(defaultBannerList);
                    img.src = defaultBannerList[0].imageUrl;
                } else {
                    setBannerList(res.data)
                    img.src = res.data[0].imageUrl;
                }
                img.onload = () => {
                    setImageLoaded?.(true);
                }
            })
            .catch(err => console.error('error', err))
    }, [])
    return <>
        <Carousel carouselList={bannerList.sort((a, b) => Number(b.showOrdering) - Number(a.showOrdering))} />
    </>
}

export { BannerCarousel }
import { Link } from "react-router-dom";

interface IProps {
    to?: string;
    text?: string;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const BottomBtn = ({ to = '', text, onClick, disabled }: IProps) => {
    return <>
        {(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge)
            ? <div style={{ paddingBottom: '100px' }}></div>
            : <div style={{ paddingBottom: '75px' }}></div>
        }

        {disabled ? <>
            <div>
                <div style={{ position: 'fixed', bottom: "0", width: "100%", maxWidth: "480px", zIndex: 2 }} >
                    <div style={{ backgroundColor: '#DDDDDD', padding: "var(--space16) var(--space20) var(--space40) var(--space20)", textAlign: 'center' }} >
                        <div className="sub-title/st3" style={{ color: "#BBBBBB" }}>
                            {text}
                        </div>
                    </div>
                    {(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) && <div style={{ height: '24px', backgroundColor: '#DDDDDD', width: '100%' }}></div>}
                </div>
            </div>
        </>
            : <>
                <div onClick={onClick}>
                    <Link to={to} style={{ position: 'fixed', bottom: "0", width: "100%", maxWidth: "480px", zIndex: 2, textDecorationLine: 'none' }} >
                        <div style={{ backgroundColor: 'var(--baseblack)', padding: "var(--space16) var(--space20) var(--space40) var(--space20)", textAlign: 'center' }} >
                            <div className="sub-title/st3 primary/p500">
                                {text}
                            </div>
                        </div>
                        {(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) && <div style={{ height: '24px', backgroundColor: 'var(--baseblack)', width: '100%' }}></div>}
                    </Link>
                </div>

            </>
        }


    </>
}
export { BottomBtn }
import { Flex, Skeleton } from "antd";
import { useMyContext } from "../../../../entities/context";

const NotificationSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<div style={{ position: 'absolute', maxWidth: "480px", backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), width: '100%' }}>
        <div style={{ padding: '0 24px 118px' }}>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '13px' }}>
                <div style={{ width: '215px', display: 'inline-block' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 21 }} />
                </div>
            </div>

            <Flex style={{ justifyContent: 'space-between', gap: 8, marginTop: 100 }}>
                <div style={{ width: '100%' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 48, marginTop: 2 }} />
                </div>
                <div style={{ width: '67px' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 67 }} />
                </div>
            </Flex>

            <Flex style={{ justifyContent: 'space-between', gap: 8, marginTop: 32 }}>
                <div style={{ width: '100%' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 48, marginTop: 2 }} />
                </div>
                <div style={{ width: '67px' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 67 }} />
                </div>
            </Flex>
            <Flex style={{ justifyContent: 'space-between', gap: 8, marginTop: 32 }}>
                <div style={{ width: '100%' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 48, marginTop: 2 }} />
                </div>
                <div style={{ width: '67px' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 67 }} />
                </div>
            </Flex>
            <Flex style={{ justifyContent: 'space-between', gap: 8, marginTop: 32 }}>
                <div style={{ width: '100%' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 48, marginTop: 2 }} />
                </div>
                <div style={{ width: '67px' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 67 }} />
                </div>
            </Flex>
            <Flex style={{ justifyContent: 'space-between', gap: 8, marginTop: 32 }}>
                <div style={{ width: '100%' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 48, marginTop: 2 }} />
                </div>
                <div style={{ width: '67px' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 67 }} />
                </div>
            </Flex>
            <Flex style={{ justifyContent: 'space-between', gap: 8, marginTop: 32 }}>
                <div style={{ width: '100%' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 48, marginTop: 2 }} />
                </div>
                <div style={{ width: '67px' }}>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 22, width: 67 }} />
                </div>
            </Flex>
        </div>
    </div>)
}

export default NotificationSkeleton;
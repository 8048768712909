import { combineReducers } from 'redux';
import initYogaCenterSelector from './initYogaCenterSelector'
import selectedYogaCenterAndDate from './selectedYogaCenterAndDate';

const rootReducer = combineReducers({
    selectedYogaCenterAndDate,
    initYogaCenterSelector
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
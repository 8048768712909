import { AxiosResponse } from 'axios';
import api from './Api'

export const getNotificationListByMethodStatusAndCreatedDateBetween = (methodStatus: string = '', startDate: string, endDate: string): Promise<AxiosResponse<Array<INotification>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/notification/period?methodStatus=${methodStatus}&startDate=${startDate}&endDate=${endDate}`)
}
export const getNotificationUserAlarmBoxList = (startDate: string, endDate: string): Promise<AxiosResponse<INotificationV2[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/api/positeam/v1/admin/notification/user-alarm-box/list?startDate=${startDate}&endDate=${endDate}`)
}
export const requestNotificationReceiptOk = (notificationReceiptOkId: string) => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/api/positeam/v1/notification/request-notification-receipt-ok`, { notificationReceiptOkId });
}
import { Flex } from "antd"

interface IProps {
    label: string,
    value: string,
    style?: React.CSSProperties
}

const WellnessLectureInfoRow = ({ label, value, style }: IProps) => {
    return <Flex style={style}>
        <div className="sub-title/st2" style={{ letterSpacing: 20 }}>{label}</div>
        <div className="body/b2">{value}</div>
    </Flex>
}

export { WellnessLectureInfoRow }
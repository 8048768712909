import { Fragment, useEffect, useState } from "react";
import { NavigateLogin, TrainingHistoryCard, DividerThin, BottomSheet } from "../../../shared";
import { TopBar } from "../../../widgets";
import { isLogin } from "../../../utils";
import MyTrainingHistorySkeleton from "../../../components/skeleton/my/myTrainingHistorySkeleton";
import { getAllTrainingHistoryByMemberIdAndTrainingDateTimeBetween } from "../../../service";
import dayjs from "dayjs";

const TrainingHistory = () => {
    const bottomSheetDataList: IBottomSheet[] = [
        { id: 1, label: '최근 7일', value: [dayjs().add(-7, 'd').format('YYYY-MM-DDT00:00:00Z'), dayjs().format('YYYY-MM-DDTHH:mm:00Z')].toString() },
        { id: 2, label: '최근 30일', value: [dayjs().add(-30, 'd').format('YYYY-MM-DDT00:00:00Z'), dayjs().toISOString()].toString() },
        { id: 3, label: '최근 60일', value: [dayjs().add(-60, 'd').format('YYYY-MM-DDT00:00:00Z'), dayjs().toISOString()].toString() },
        { id: 4, label: '전체', value: [null, null].toString() }
    ]
    const [bottomSheetValue, setButtomSheetValue] = useState<string>(bottomSheetDataList[0].value.toString());
    const [trainingHistoryList, setTrainingHistoryList] = useState<ITrainingHistoryBasicWebResponseV2[]>([]);
    const [isInitLoading, setIsInitLoading] = useState<boolean>(false);
    const [bottomSheetModalStatus, setBottomSheetModalStatus] = useState<boolean>(false);

    useEffect(() => {
        if (!bottomSheetValue) return;
        const startDateTime = bottomSheetValue.split(',')[0]
        const endDateTime = bottomSheetValue.split(',')[1]
        getAllTrainingHistoryByMemberIdAndTrainingDateTimeBetween(startDateTime, endDateTime)
            .then((res) => setTrainingHistoryList(res.data))
            .catch((error) => console.error('error', error))
            .finally(() => setIsInitLoading(false))
    }, [bottomSheetValue])

    return <>
        {isInitLoading && <MyTrainingHistorySkeleton />}
        {!isLogin()
            ? <>
                <TopBar title="수련 내역 & 리뷰" rightIcon={false} />
                <NavigateLogin />
            </>
            : <>
                <TopBar title="수련 내역 & 리뷰" rightIcon={false} />
                <div style={{ padding: '0 var(--space20) var(--space32)' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBlock: '24px' }}>
                        <div className="sub-title/st3 base/black">
                            {trainingHistoryList.length} 건
                        </div>
                        <BottomSheet
                            isOpen={bottomSheetModalStatus}
                            setIsOpen={setBottomSheetModalStatus}
                            dataList={bottomSheetDataList}
                            value={bottomSheetValue}
                            setValue={setButtomSheetValue}
                            isShowLabel />
                    </div>
                    <div>
                        {trainingHistoryList.map((checkIn, idx) => <Fragment key={idx}>
                            <TrainingHistoryCard trainingHistory={checkIn} />
                            {trainingHistoryList.length - 1 !== idx && <DividerThin style={{ marginBlock: 'var(--space16)' }} />}
                        </Fragment>)}

                    </div>
                </div>
            </>}
    </>
}

export { TrainingHistory }
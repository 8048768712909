import { useNavigate } from "react-router-dom";
import { ReactComponent as Home } from "../../assets/images/icon/home.svg";
import { ReactComponent as Booking } from "../../assets/images/icon/booking.svg";
import { ReactComponent as My } from "../../assets/images/icon/my.svg";
import { isIosMobile } from "../../utils";

interface IProps {
    activeButton?: TGnbMenu;
}
type TGnbMenu = 'home' | 'reservation' | 'my';

const GnbFooter = ({ activeButton }: IProps) => {
    const navigate = useNavigate();
    const isActiveButtonByValue = (value: TGnbMenu, target: 'icon' | 'caption') => {
        const isActive = activeButton === value;
        if (target === 'icon') {
            return isActive ? "#fff743" : "#555555";
        }
        if (target === 'caption') {
            return isActive ? 'primary/p500' : 'base/white';
        }
        return 'base/white';
    };

    return <>
        <div style={{ height: '76px' }} />
        {isIosMobile() && <div style={{ height: '24px' }} />}
        <div style={{ position: "fixed", bottom: "0", width: "100%", maxWidth: "480px" }}>
            <div style={{ backgroundColor: "black", display: "flex", justifyContent: "space-evenly" }} >
                <div style={{ textAlign: 'center', cursor: "pointer", padding: '15px' }} onClick={() => navigate("/")}>
                    <Home width={24} height={24} fill={isActiveButtonByValue('home', 'icon')} />
                    <div className={`caption/c1 ` + isActiveButtonByValue('home', 'caption')} style={{ marginTop: '6px' }}>홈</div>
                </div>
                <div style={{ textAlign: 'center', cursor: "pointer", padding: '15px' }} onClick={() => navigate("/reservation")}>
                    <Booking width={24} height={24} fill={isActiveButtonByValue('reservation', 'icon')} />
                    <div className={`caption/c1 ` + isActiveButtonByValue('reservation', 'caption')} style={{ marginTop: '6px' }}>수업 예약</div>
                </div>
                <div style={{ textAlign: 'center', cursor: "pointer", padding: '15px' }} onClick={() => navigate("/my")}>
                    <My width={24} height={24} fill={isActiveButtonByValue('my', 'icon')} />
                    <div className={`caption/c1 ` + isActiveButtonByValue('my', 'caption')} style={{ marginTop: '6px' }}>MY</div>
                </div>
            </div >
            {isIosMobile() && <div style={{ height: '24px', backgroundColor: 'black', width: '100%' }} />}
        </div >
    </>
}

export { GnbFooter }
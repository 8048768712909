import { AxiosResponse } from 'axios';
import api from './Api'

interface IParams {
    name: string;
    gender: string;
    mobile: string;
    email: string;
    birthday: string;
    birthyear: number;
    nickname: string;
    socialAccessToken: string;
    social: string;
    sub: string;
}

export const requestSignup = (params: IParams): Promise<AxiosResponse<string, any>> => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/social-profile`, params);
}


import { AxiosResponse } from 'axios';
import api from './Api'

export const getAllWellnessTicketGroupAndWellnessTicketNameWithCenterInfoListByCompanyId = (companyId: number): Promise<AxiosResponse<any[]>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/wellness-ticket-group/web/v2/all/${companyId}`)
}

export const getWellnessTicketGroupDetailByWellnessTicketGroupId = (wellnessTicketGroupId: string): Promise<AxiosResponse<any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/wellness-ticket-group/web/v2/${wellnessTicketGroupId}`)
}



import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TicketByCenterIdSkeleton from "../../../components/skeleton/ticket/ticketByCenterIdSkeleton";
import { TopBar } from "../../../widgets";
import { BottomBtn } from "../../../shared";
import { getWellnessTicketGroupDetailByWellnessTicketGroupId } from "../../../service/wellnessTicketGroup";
import { WellnessTicketCardForWellnessTicketGroupDetail } from "../../../shared/card/wellnessTicketCardForWellnessTicketGroupDetail";

const WellnessTicketGroupDetail = () => {
    const [selectedWellnessTicketId, setSelectedWellnessTicketId] = useState<number>();
    const [wellnessTicketGroup, setWellnessTicketGroup] = useState<any>();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const { id } = useParams();

    useEffect(() => {
        if (!id) return;
        getWellnessTicketGroupDetailByWellnessTicketGroupId(id)
            .then((res) => { setWellnessTicketGroup(res.data); })
            .catch((error) => { console.error('error', error); })
            .finally(() => { setIsInitLoading(false); });
    }, [id])

    return <>
        {isInitLoading && <TicketByCenterIdSkeleton />}
        <TopBar title={wellnessTicketGroup?.title} rightIcon={false} />
        <div style={{ padding: '24px var(--space20)' }}>
            {wellnessTicketGroup?.wellnessTicketList
                .filter((wellnessTicket: any) => wellnessTicket.isDisplay)
                .map((wellnessTicket: any) => <WellnessTicketCardForWellnessTicketGroupDetail key={wellnessTicket.id} wellnessTicket={wellnessTicket} isSelect={selectedWellnessTicketId === wellnessTicket.id} onClick={() => setSelectedWellnessTicketId(wellnessTicket.id)} />)}
        </div>
        <BottomBtn disabled={!selectedWellnessTicketId} to={selectedWellnessTicketId ? '/pay-info/' + selectedWellnessTicketId : ''} text="선택하기" />
    </>
}
export { WellnessTicketGroupDetail }
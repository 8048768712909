interface IProps {
    text?: string
    style?: React.CSSProperties;
}

const TextBadge = ({ text, style }: IProps) => {

    const tagStyle: React.CSSProperties = {
        width: '24px',
        height: '14px',
        padding: 'var(--space4) var(--space8)',
        borderRadius: 'var(--radius4)',
        backgroundColor: 'var(--secondarys100)',
        lineHeight: '14.32px',
    };


    return <div className={"caption/c1"} style={{ ...tagStyle, ...style }}>{text}</div>
};

export { TextBadge };
import dayjs from "dayjs"
import { CourseBadge } from "../../"
import { Button, Flex } from "antd"
import { EditOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"


interface IProps {
    trainingHistory: ITrainingHistory
    style?: React.CSSProperties
}

interface ITrainingHistory {
    wellnessLectureName: string
    wellnessLectureStartDateTime: string
    wellnessLectureEndDateTime: string
    status: string
    issuedWellnessTicketName?: string
    yogaCenterName: string
    wellnessLectureImageUrl: string
    isCreatedReview: boolean
    wellnessLectureId: number;
    teacherName: string
}

const TrainingHistoryCard = ({ trainingHistory, style }: IProps) => {
    const navigate = useNavigate();

    const getReviewButtonComponent = (trainingHistory: ITrainingHistory) => {
        if (trainingHistory.status === 'CHECK_IN_RESERVATION_CANCELED' || trainingHistory.status === 'ABSENT') {
            return <></>
        }
        if (trainingHistory.isCreatedReview) {
            if (dayjs(trainingHistory.wellnessLectureEndDateTime) < dayjs() && dayjs(trainingHistory.wellnessLectureEndDateTime) > dayjs().add(-4, 'day')) {
                return <Button
                    onClick={() => {
                        navigate(`/review/update/${trainingHistory.wellnessLectureId}`)
                    }}
                    size="small"
                    style={{ width: '100%', marginTop: 12, fontSize: 12 }}>
                    리뷰 수정 <EditOutlined />
                </Button>
            }
            return <Button
                onClick={() => {
                    navigate(`/review/view/${trainingHistory.wellnessLectureId}`)
                }}
                size="small"
                style={{ width: '100%', marginTop: 12, fontSize: 12 }}>
                리뷰 확인
            </Button>
        }
        if (dayjs(trainingHistory.wellnessLectureEndDateTime) < dayjs() && dayjs(trainingHistory.wellnessLectureEndDateTime) > dayjs().add(-4, 'day')) {
            return <Button
                size="small"
                style={{ width: '100%', marginTop: 12, fontSize: 12 }}
                onClick={() => navigate(`/review/create/${trainingHistory.wellnessLectureId}`)}>
                리뷰 작성 <EditOutlined />
            </Button>
        }
        return <></>
    }
    return <Flex style={{ justifyContent: 'space-between' }} gap={16}>
        <div style={{ width: 83, height: 83 }}>
            <img src={trainingHistory.wellnessLectureImageUrl} alt="수업사진" width={'100%'} style={{ borderRadius: "999px", height: '100%', objectFit: 'cover' }} />
        </div>
        <div style={{ width: '75%' }}>
            <Flex style={{ justifyContent: 'space-between' }}>
                <div>
                    <div className="sub-title/st3" style={{ marginTop: 2 }}>{trainingHistory.wellnessLectureName}</div>
                </div>
                <div>
                    <CourseBadge status={trainingHistory.status} />
                </div>
            </Flex>

            <div style={{ marginTop: 12 }}>
                <div className="body/b1 secondary/s500">{dayjs(trainingHistory.wellnessLectureStartDateTime).format('YYYY.MM.DD (dd) HH:mm')} - {dayjs(trainingHistory.wellnessLectureEndDateTime).format('HH:mm')} | {trainingHistory.yogaCenterName}</div>
                <Flex style={{ justifyContent: 'space-between' }}>
                    <div className="body/b1 secondary/s500">{trainingHistory.issuedWellnessTicketName ? trainingHistory.issuedWellnessTicketName + ' 사용' : ''} </div>
                    <div className="body/b1 secondary/s500">{trainingHistory.teacherName} 코치</div>
                </Flex>
            </div>
            {getReviewButtonComponent(trainingHistory)}
        </div>
    </Flex>
}
export { TrainingHistoryCard }
import { Flex } from "antd"
import { ReactComponent as ArrowRight } from "../../../assets/images/icon/arrow-right.svg"
import { useNavigate } from "react-router-dom";

interface IProps {
    imageUrl: string;
    title?: React.ReactNode | string;
    content?: React.ReactNode | string;
    to?: string;
    style?: React.CSSProperties;
}

const CenterCard = ({ imageUrl, title, content, to, style }: IProps) => {
    const navigate = useNavigate();

    return <div style={{ ...style }}>
        <img alt="" style={{ height: '180px', width: '100%', objectFit: 'cover', borderRadius: '7px' }} src={imageUrl} />
        <div className="sub-title/st3"
            style={{ marginTop: 16 }}>
            {title}
        </div>
        <div className="body/b2"
            style={{ marginTop: 8 }}>
            {content}
        </div>

        <div className="btn/text-btn"
            style={{ color: '#737373', marginTop: 16, cursor: 'pointer', display: 'inline-block' }}
            onClick={() => navigate(to ?? '#')}>
            <Flex style={{ alignItems: 'center' }} gap={4}>
                자세히 <ArrowRight width={12} height={12} stroke="#737373" />
            </Flex>
        </div>
    </div>
}

export { CenterCard }
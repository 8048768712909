import MainHeader from "../../layout/MainLayout/header";
import { ReactComponent as ArrowRight } from "../../assets/images/icon/arrow-right.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getPopupTicketByLectureId } from "../../service";
import { TopBar } from "../../widgets";



const PopupTicket = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [popupTicketList, setPopupTicketList] = useState<Array<IMobileDefaultTicket>>([]);

    useEffect(() => {
        requestPopupTicket();
    }, [])
    const requestPopupTicket = async () => {
        if (!id) return;
        try {
            const res = await getPopupTicketByLectureId(id);
            setPopupTicketList(res.data);
        } catch (error) {
            console.error('error', error);
        }
    }

    return <>
        <TopBar title="수강권 선택" rightIcon={false} />
        <div style={{ backgroundColor: '#F4F4F4', minHeight: 'calc(100vh - 54px)', position: 'relative' }}>
            <div style={{ padding: '24px 20px' }}>
                {popupTicketList.sort((a, b) => b.name.length - a.name.length).map((ticket: IMobileDefaultTicket) => <>
                    <div style={{ height: '12px' }}></div>
                    <div style={{ backgroundColor: 'white', padding: '20px', cursor: 'pointer' }} onClick={() => navigate(`/pay-info/${ticket.id}`)}>
                        <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'start', height: '52px' }}>
                            <div style={{ fontSize: '16px', fontWeight: '500', lineHeight: '26px' }}>
                                <div style={{ whiteSpace: 'pre-wrap' }}>{ticket.name}</div>
                            </div>
                            <div><ArrowRight width={16} height={16} stroke="#000000" /></div>
                        </div>
                        <div style={{ height: '30px' }}></div>
                        <div style={{ height: '22px' }}></div>

                    </div>
                </>)}
            </div>
            <div style={{ position: 'absolute', bottom: 0, backgroundColor: "#D9D9D9", color: "#555", fontSize: "13px", width: "100%", maxWidth: "480px", marginTop: '30px' }}>
                <div style={{ padding: '20px', lineHeight: '20px' }}>
                    <div>&middot; 강남 스튜디오 정기권 구매 고객은 DELUXE로 업데이트 됩니다.</div>
                    <div>&middot; ALL PASS 정기권 구매 고객은 SUITE로 업데이트 됩니다.</div>
                    <div>&middot; 단, 1회권, POPUP 수강권은 제외됩니다.</div>
                    <div>&middot; ALL PASS 정기권은 강남, 도산, 선릉에서 사용 가능합니다.</div>
                    <div>&middot; private 수업 및 일부 수업은 제한됩니다.</div>
                    <div>&middot; 쿠폰 혜택은 프로모션에 따라 변경될 수 있습니다.</div>
                    <div>&middot; 쿠폰은 2024년 3월 1일부터 발행 및 사용가능합니다.</div>
                    <div>&middot; 모든 수강권은 구매시 3%의 포인트 적립 혜택이 적용됩니다.</div>
                    <div>&middot; 멤버십에 대한 사항은 <Link to={'/member-ship'} style={{ color: '#555', textDecoration: 'none' }}>[멤버십 안내]</Link>에서 확인하실 수 있습니다.</div>
                </div>
                {(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) && <div style={{ height: '24px', backgroundColor: '#D9D9D9', width: '100%' }}></div>}
            </div>
        </div>

    </>
}

export default PopupTicket;

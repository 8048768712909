import { Modal, message } from "antd";
import { useEffect, useState } from "react";
import { ReactComponent as CheckCircle } from "../../../assets/images/checkCircle.svg";
import { useNavigate } from "react-router-dom";
import { createOrUpdatePolicyDataTransferAgree, removeUser } from "../../../service";
import { CloseOutlined } from "@ant-design/icons";
import { MarketingAgreement, PersonInfoPolicy, ServiceUseTerm } from "../../../shared";
import { useMyContext } from "../../../entities/context";

interface IProps {
    propsModalStatus: boolean
    beforeUrl?: string | null
}

const PolicyDataTransfer = ({ propsModalStatus, beforeUrl }: IProps) => {
    const [modalStatus, setModalStatus] = useState<boolean>(propsModalStatus);
    const [personInfoModalStatus, setPersonInfoModalStatus] = useState<boolean>(false);
    const [serviceUseTermModalStatus, setServiceUseTermModalStatus] = useState<boolean>(false);
    const [marketingAgreementModalStatus, setMarketingAgreementModalStatus] = useState<boolean>(false);
    const [agreememt, setAgreememt] = useState<Array<boolean>>([false, false, false]);
    const navigate = useNavigate();
    const policyText = ["서비스 이용약관", "개인정보 처리방침", "마케팅 정보 수신 동의"]
    const { topHeight } = useMyContext();

    useEffect(() => {
        setModalStatus(propsModalStatus)
    }, [propsModalStatus])

    return <>
        <Modal width={"100%"} style={{ margin: "30% 3.2%" }} open={modalStatus} closeIcon={<CloseOutlined onClick={() => { if (window.confirm("동의를 거부할 권리가 있습니다. 다만 필수 동의 거부시 서비스가 제한될 수 있습니다,")) { removeUser(); setModalStatus(false); navigate("/") } }} />} footer={false} >
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "11px", padding: "10px 6px" }}>
                <div style={{ display: "flex", alignContent: "center", justifyContent: "center", fontSize: "22px", fontWeight: "600" }}>
                    약관 및 개인정보 동의
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: "15px", width: "100%" }}>
                    <div style={{ display: "flex", gap: "10px", padding: "10px 0px", borderBottom: "0.5px solid #DDD", width: "100%" }}>
                        <CheckCircle style={{ cursor: "pointer" }} fill={agreememt.every((i) => i) ? "#000000" : "#FFFFFF"} stroke={agreememt.every((i) => i) ? "#FFFFFF" : "#DDDDDD"} onClick={() => agreememt.every((i) => i) ? setAgreememt([false, false, false]) : setAgreememt([true, true, true])} />
                        <div style={{ fontSize: "16px", fontWeight: "500" }} onClick={() => agreememt.every((i) => i) ? setAgreememt([false, false, false]) : setAgreememt([true, true, true])} >전체동의</div>
                    </div>
                    <div style={{ display: "flex", padding: "0px 5px 15px 0px", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: "15px", color: "#555555" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <CheckCircle style={{ cursor: "pointer" }} fill={agreememt[0] ? "#000000" : "#FFFFFF"} stroke={agreememt[0] ? "#FFFFFF" : "#DDDDDD"} onClick={() => setAgreememt((curr) => [!curr[0], curr[1], curr[2]])} />
                            <div style={{ fontSize: "13px" }} onClick={() => setAgreememt((curr) => [!curr[0], curr[1], curr[2]])}>[필수] 서비스 이용약관</div> <div style={{ fontSize: "11px", fontWeight: "300", textDecorationLine: "underline", cursor: "pointer" }} onClick={() => setServiceUseTermModalStatus(true)}>자세히</div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <CheckCircle style={{ cursor: "pointer" }} fill={agreememt[1] ? "#000000" : "#FFFFFF"} stroke={agreememt[1] ? "#FFFFFF" : "#DDDDDD"} onClick={() => setAgreememt((curr) => [curr[0], !curr[1], curr[2]])} />
                            <div style={{ fontSize: "13px" }} onClick={() => setAgreememt((curr) => [curr[0], !curr[1], curr[2]])} >[필수] 개인정보 처리방침</div> <div style={{ fontSize: "11px", fontWeight: "300", textDecorationLine: "underline", cursor: "pointer" }} onClick={() => setPersonInfoModalStatus(true)}>자세히</div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <CheckCircle style={{ cursor: "pointer" }} fill={agreememt[2] ? "#000000" : "#FFFFFF"} stroke={agreememt[2] ? "#FFFFFF" : "#DDDDDD"} onClick={() => setAgreememt((curr) => [curr[0], curr[1], !curr[2]])} />
                            <div style={{ fontSize: "13px" }} onClick={() => setAgreememt((curr) => [curr[0], curr[1], !curr[2]])} >[선택] 마케팅 정보 수신 동의</div> <div style={{ fontSize: "11px", fontWeight: "300", textDecorationLine: "underline", cursor: "pointer" }} onClick={() => setMarketingAgreementModalStatus(true)}>자세히</div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "5px", border: agreememt.every((i, idx, arr) => (idx === arr.length - 1) ? true : i) ? "1px solid #000" : "1px solid #555", background: agreememt.every((i, idx, arr) => (idx === arr.length - 1) ? true : i) ? "#000" : "", color: agreememt.every((i, idx, arr) => (idx === arr.length - 1) ? true : i) ? "#FFFFFF" : "#555555", cursor: agreememt.every((i, idx, arr) => (idx === arr.length - 1) ? true : i) ? "pointer" : "", width: "100%", height: "48px" }} onClick={() => agreememt.every((i, idx, arr) => (idx === arr.length - 1) ? true : i) ? createOrUpdatePolicyDataTransferAgree({ isAgree: agreememt.every((i) => i), isServiceUseTermAgree: agreememt[0], isPersonInfoPolicyAgree: agreememt[1], isMarketingAgreementAgree: agreememt[2] }).then(() => navigate(beforeUrl ? beforeUrl : '/', { replace: true })).catch((error) => console.error("error", error)) : agreememt.forEach((i, idx, arr) => (!i && (idx !== arr.length - 1)) && message.error(policyText[idx] + "를 동의해 주세요"))}>
                    동의합니다
                </div>
            </div>
        </Modal>
        <Modal style={{ top: Number(topHeight) + 16 }} open={personInfoModalStatus} onCancel={() => setPersonInfoModalStatus(false)} footer={false}>
            <PersonInfoPolicy isModal={true} />
        </Modal>
        <Modal style={{ top: Number(topHeight) + 16 }} open={serviceUseTermModalStatus} onCancel={() => setServiceUseTermModalStatus(false)} footer={false}>
            <ServiceUseTerm isModal={true} />
        </Modal>
        <Modal style={{ top: Number(topHeight) + 16 }} open={marketingAgreementModalStatus} onCancel={() => setMarketingAgreementModalStatus(false)} footer={false}>
            <MarketingAgreement isModal />
        </Modal>
    </>
}

export default PolicyDataTransfer;
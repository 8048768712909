import { TopBar } from "../../../widgets";

const RefundPolicy = () => {
    return <>
        <TopBar title="정기권 환불 정책" rightIcon={false} />
        <div className="body/b2" style={{ padding: '24px 20px' }}>
            <div>- 결제 금액의 10% 위약금 + 사용기간(정기권 구매 ~ 환불 요청 당일)만큼 공제 후 지불</div>
            <div>- 정기권 사용 가능 횟수로 정산하여 환불</div>
        </div>
    </>
}
export { RefundPolicy };
import { Flex } from "antd"
import { ReactComponent as ArrowRight } from "../../assets/images/icon/arrow-right.svg"

interface IProps {
    title: string | React.ReactNode;
    onClick?: Function;
    content?: string | React.ReactNode;
}

const BasicInfoRow = ({ title, onClick, content }: IProps) => {
    return <>
        <Flex
            onClick={() => onClick?.()}
            className="body/b3"
            style={{ padding: content ? 12 : '14px 0 14px 12px', justifyContent: 'space-between', alignItems: 'center', cursor: onClick ? 'pointer' : 'auto' }}>
            <div style={{ color: 'var(--secondarys800)' }}>{title}</div>
            <div>{content ? content : <ArrowRight width={16} height={16} stroke="#737373" />}</div>
        </Flex>
    </>
}

export { BasicInfoRow }
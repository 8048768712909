import { Fragment, useState } from "react";

const HEADERHEIGHT = 40;
const anchorHeaderStyle = {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: `${HEADERHEIGHT}px`,
    flex: 1,
    cursor: 'pointer'
}

const activeHeaderStyle = {
    color: 'var(--baseBlack)',
    borderBottom: '2px solid black',
    ...anchorHeaderStyle
}
const defaultHeaderStyle = {
    color: 'var(--secondarys600)',
    borderBottom: '1px solid #F4F4F4',
    ...anchorHeaderStyle
}

interface Item {
    label: string,
    key: number,
    children: React.ReactNode
}

interface IProps {
    items: Array<Item>
    defaultkey?: number;
}

const CustomTabs = ({ items, defaultkey = 0 }: IProps) => {
    const [key, setKey] = useState<number>(defaultkey);

    return <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', textAlign: 'center' }}>
            {items.map((item: Item) => <div key={item.key}
                className="sub-title/st2"
                style={key === item.key ? activeHeaderStyle : defaultHeaderStyle}
                onClick={() => setKey(item.key)}>
                {item.label}
            </div>)}
        </div>
        <div>
            {items.find((item) => item.key === key)?.children}
        </div>
    </>
}

export { CustomTabs }
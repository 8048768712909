import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';

export const GET_INITYOGACENTERSELECTOR = 'InitYogaCenterSelector/GET_INITYOGACENTERSELECTOR';
export const GET_INITYOGACENTERSELECTOR_SUCCESS = 'InitYogaCenterSelector/GET_INITYOGACENTERSELECTOR_SUCCESS';
export const GET_INITYOGACENTERSELECTOR_ERROR = 'InitYogaCenterSelector/GET_INITYOGACENTERSELECTOR_ERROR';

export const getInitYogaCenterSelectorAsync = createAsyncAction(
    GET_INITYOGACENTERSELECTOR,
    GET_INITYOGACENTERSELECTOR_SUCCESS,
    GET_INITYOGACENTERSELECTOR_ERROR
)<undefined, IInitYogaCenterSelectorModule, AxiosError>();



interface IProps {
    text: string
}
const RequestComponent = ({ text }: IProps) => {
    return <div style={{ textAlign: 'center', backgroundColor: "var(--secondarys100)", padding: '26px 0' }}>
        <div className="body/b2" style={{ color: "var(--secondarys600)" }}>
            {text}
        </div>
    </div>
}

export { RequestComponent }
import { useState } from "react";
import { SolidBtn, TextBtn } from "../../shared"
import { Input, Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { IssuedYogaCouponCard } from "./ui/issuedYogaCouponCard";
import './index.css'
interface IProps {
    yogaCouponModalStatus: boolean
    setYogaCouponModalStatus: Function
    availableIssuedYogaCouponList: IAvailableIssuedYogaCoupon[]
    selectYogaCouponClick: Function
    selectIssuedYogaCoupon?: IAvailableIssuedYogaCoupon
    clickCouponApply: Function
    couponId: string
    loading: boolean
    getIssuedYogaCoponloading: boolean
}
const UseCouponByPayInfo = ({ yogaCouponModalStatus, setYogaCouponModalStatus, availableIssuedYogaCouponList, selectYogaCouponClick, selectIssuedYogaCoupon, clickCouponApply, couponId, loading, getIssuedYogaCoponloading }: IProps) => {
    const [couponCode, setCouponCode] = useState<string>('');
    const [selectedIssuedYogaCouponId, setSelectedIssuedYogaCouponId] = useState<number | undefined>(selectIssuedYogaCoupon?.issuedYogaCouponId);
    const [contentHeight, setContentHeight] = useState<number>(selectIssuedYogaCoupon ? 565 : 484);
    const minWidth = window.innerWidth <= 480 ? '100vw' : '480px';
    const marginInline = window.innerWidth <= 480 ? '0' : (window.innerWidth - 480) / 2;

    return <>
        <TextBtn
            type='underline' icon={false}
            style={{ color: 'var(--baseblack)' }}
            onClick={() => setYogaCouponModalStatus(true)}
            children={selectIssuedYogaCoupon || couponId !== '' ? '쿠폰 변경' : '사용 가능 쿠폰'}
        />
        <Modal
            transitionName="custom-modal"
            style={{ top: `calc(100vh - ${contentHeight}px)`, padding: 0, margin: 0 }}
            styles={{
                content: {
                    minWidth: minWidth, overflow: "scroll", borderRadius: "5% 5% 0 0", padding: "var(--space20) var(--space20) var(--space40)", marginInline: marginInline + 'px',
                    minHeight: `${contentHeight}px`
                }
            }}
            title={null} footer={null} closeIcon={null}
            open={yogaCouponModalStatus}
            onCancel={() => !loading && setYogaCouponModalStatus(false)}
        >
            {getIssuedYogaCoponloading
                ? <Spin indicator={<LoadingOutlined spin />} />
                : <>
                    <div className="title/t1" style={{ color: '#111111' }}>쿠폰 번호 입력</div>
                    <div style={{ display: 'flex', gap: 'var(--space8)', marginTop: 'var(--space20)' }} >
                        <Input className="body/b2" placeholder="번호를 입력해 주세요" value={couponCode} onChange={e => setCouponCode(e.target.value.toUpperCase())} disabled={loading || couponId !== ''} />
                        <SolidBtn
                            style={{ padding: 'var(--space8) var(--space16)' }}
                            type='line' children={loading ? <Spin indicator={<LoadingOutlined spin />} /> : <div className="sub-title/st2" style={{ width: '25px', height: '24px' }}>적용</div>} icon={false}
                            state={(loading || couponId !== '') ? 'disabled' : 'default'}
                            onClick={() => clickCouponApply(couponCode)}
                        />
                    </div>
                    <div className="title/t1" style={{ color: '#111111', marginTop: 'var(--space32)', marginBottom: 'var(--space20)' }}>사용 가능 쿠폰</div>
                    {availableIssuedYogaCouponList.length === 0
                        ? <div className="body/b2 secondary/s600" style={{ marginTop: 'var(--space20)', padding: '26px var(--space12)' }}>사용 가능한 쿠폰이 없습니다.</div>
                        : <>
                            <div style={{ height: 270, overflow: 'scroll' }}>
                                {availableIssuedYogaCouponList.map((availableIssuedYogaCoupon, idx) =>
                                    <IssuedYogaCouponCard
                                        key={idx}
                                        issuedYogaCoupon={availableIssuedYogaCoupon}
                                        isSelect={availableIssuedYogaCoupon.issuedYogaCouponId === selectedIssuedYogaCouponId}
                                        onClick={() => {
                                            setCouponCode('');
                                            if (availableIssuedYogaCoupon.issuedYogaCouponId === selectedIssuedYogaCouponId) {
                                                setSelectedIssuedYogaCouponId(undefined)
                                                selectYogaCouponClick(undefined)
                                                setContentHeight(484)
                                            } else {
                                                setSelectedIssuedYogaCouponId(availableIssuedYogaCoupon.issuedYogaCouponId);
                                                setContentHeight(565)
                                            }
                                        }} />
                                )}
                            </div>
                            {selectedIssuedYogaCouponId && <div
                                className="sub-title/st3"
                                style={{
                                    width: '100%',
                                    maxWidth: '440px',
                                    backgroundColor: 'var(--baseblack)',
                                    color: 'var(--basewhite)',
                                    textAlign: 'center',
                                    paddingBlock: 16,
                                    borderRadius: '4px',
                                    margin: '32px auto 0 auto'
                                }}
                                onClick={() => {
                                    selectYogaCouponClick(availableIssuedYogaCouponList.find(data => data.issuedYogaCouponId === selectedIssuedYogaCouponId));
                                    setYogaCouponModalStatus(false)
                                }}>쿠폰 적용하기</div>
                            }
                        </>
                    }
                </>}
        </Modal>
    </>
}
export { UseCouponByPayInfo }
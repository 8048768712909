import { Skeleton } from "antd";
import { useMyContext } from "../../../../entities/context";


const MyEventDetailSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<>
        <div style={{ position: 'absolute', maxWidth: "480px", backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px') }}>
            <Skeleton.Button active={true} size={"default"} block={true} style={{ height: '100vh' }} />
        </div>
    </>
    )
}

export default MyEventDetailSkeleton;
import { Divider, Flex, Skeleton } from "antd";
import { ReactComponent as ArrowDown } from "../../../../assets/images/icon/arrow-down.svg";
import './index.css'
import { useMyContext } from "../../../../entities/context";
import WellnessLectureSkeleton from "../wellnessLectureSkeleton";

const ReservationSkeleton = () => {
    const { topHeight } = useMyContext();

    return (<div style={{ position: 'absolute', maxWidth: "480px", backgroundColor: 'white', zIndex: 3, paddingTop: (topHeight + 'px'), width: '100%' }}>
        <div style={{ padding: '13px 24px 0' }}>
            <div style={{ width: '100%' }}>
                <Flex style={{ justifyContent: 'space-between' }}>
                    <Flex style={{ width: '80px', alignItems: 'center' }} gap={4}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 26 }} />
                        <ArrowDown width={16} height={16} stroke={"#AEAEAE"} />
                    </Flex>
                    <div style={{ width: 90 }}>
                        <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 26 }} />
                    </div>
                </Flex>
            </div>

            <div style={{ margin: '10px 4px 0 4px' }}>
                <Flex style={{ justifyContent: 'space-between' }}>
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 24, width: 24, margin: '12px 0 0 12px' }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 24, width: 107, margin: '12px 0 0 12px' }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 24, width: 24, margin: '12px 0 0 12px' }} />
                </Flex>
            </div>
        </div>
        <Divider style={{ margin: '21px 0 8px 0' }} />
        <div style={{ padding: '0px 30px 20px 30px' }}>
            <Flex style={{ justifyContent: 'space-between', marginTop: 10 }}>
                <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
            </Flex>
            <Flex style={{ justifyContent: 'space-between', marginTop: 10 }}>
                <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
            </Flex>
            {Array.from({ length: 5 }).map((_, index) => (
                <Flex style={{ justifyContent: 'space-between', marginTop: 22 }}>
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                    <Skeleton.Button className="skeletonCustom" active={true} size={"default"} block={false} style={{ height: 18, width: 32 }} />
                </Flex>
            ))}
        </div>
        <WellnessLectureSkeleton />
    </div>)
}

export default ReservationSkeleton
import { useEffect, useState } from "react";
import WellnessLecutreDetailSkeleton from "../../components/skeleton/lecture/lectureDetailSkeleton";
import { useNavigate, useParams } from "react-router-dom";
import { isLogin, lectureDatePeriodFormat } from "../../utils";
import { ProgramInfoWithModal } from "./ui/programInfoWithModal";
import { Flex } from "antd";
import { WellnessLectureInfoRow } from "./ui/lectureInfoRow";
import dayjs from "dayjs";
import { BottomBtn, DividerThin } from "../../shared";
import { ClassDescription } from "./ui/classDescription";
import { ReactComponent as ArrowRight } from '../../assets/images/icon/arrow-right.svg';
import { MyIssuedWellnessTicketAvaiable } from "./ui/myIssuedWellnessTicketAvaiable";
import { CancelReservationWithModalInWellnessLectureDetail } from "./ui/modal/cancelReservationWithModalInLectureDetail";
import { ReservationWithModalInWellnessLectureDetail } from "./ui/modal/reservationWithModalInLectureDetail";
import { TopBar } from "../../widgets";
import { useMyContext } from "../../entities/context";
import { getWellnessLectureDetailByWellnessLectureId } from "../../service/wellnessLecture";
import { getAllIssuedWellnessTicketByMemberIdAndWellnessLectureId } from "../../service";

const WellnessLecture = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [wellnessLecture, setWellnessLecture] = useState<IWellnessLectureDetailWebResponseV2>();
    const [issuedWellnessTicketList, setIssuedWellnessTicketList] = useState<IIssuedWellnessTicketByMemberIdAndWellnessLectureIdResponseV2[]>([]);
    const [selectedIssuedWellnessTicketId, setSelectedIssuedWellnessTicketId] = useState<number>();
    const { topHeight } = useMyContext();

    useEffect(() => {
        requestWellnessLectureById();
    }, [id])

    const requestWellnessLectureById = async () => {
        if (!id) return;
        try {
            const res = await getWellnessLectureDetailByWellnessLectureId(id);
            setWellnessLecture(res.data)
            const img = new Image();
            if (res.data && res.data.wellnessLectureImageUrl) {
                img.src = res.data.wellnessLectureImageUrl
            } else {
                img.src = `${process.env.PUBLIC_URL}/assets/LectureDetailDefault.jpg`
            }
            img.onload = () => {
                setImageLoaded(true);
            }

            if (!isLogin()) return;

            const res2 = await getAllIssuedWellnessTicketByMemberIdAndWellnessLectureId(id);
            setIssuedWellnessTicketList(res2.data)
            setSelectedIssuedWellnessTicketId(res2.data[0]?.issuedWellnessTicketId)
        } catch (error) {
            console.error('error', error);
        }
    }

    useEffect(() => { if (imageLoaded) setIsInitLoading(false) }, [imageLoaded])

    return <>
        {isInitLoading && <WellnessLecutreDetailSkeleton />}
        <TopBar
            position='fixed'
            title="수업 상세"
            rightIcon={false}
            style={{ backgroundColor: 'var(--basewhite)' }}
        />

        {wellnessLecture && <>
            <img
                style={{ width: "100%", objectFit: 'cover', marginTop: (Number(topHeight) + 48 + 'px') }}
                alt="Not"
                src={wellnessLecture.wellnessLectureImageUrl ? wellnessLecture.wellnessLectureImageUrl : `${process.env.PUBLIC_URL}/assets/LectureDetailDefault.jpg`} />

            <div style={{ padding: '24px 20px' }}>
                {wellnessLecture.yogaCenterName !== "강남" &&
                    <ProgramInfoWithModal
                        name={wellnessLecture.yogaProgramName}
                        description={wellnessLecture.yogaProgramDescription}
                        title={wellnessLecture.yogaProgramName}
                    />
                }
                <div className="title/t2" style={{ marginTop: 8 }}>{wellnessLecture.wellnessLectureName}</div>
                <div style={{ height: 20 }}></div>

                <WellnessLectureInfoRow label="시간" value={lectureDatePeriodFormat(wellnessLecture.wellnessLectureStartDateTime, wellnessLecture.wellnessLectureEndDateTime)} />
                <WellnessLectureInfoRow label="장소" value={`${wellnessLecture.yogaCenterName} ${wellnessLecture.room}`} />
                <WellnessLectureInfoRow label="코치" value={`${wellnessLecture.teacherName} 코치`} />

                <ClassDescription description={wellnessLecture.wellnessLectureDescription} />
                <DividerThin style={{ marginBlock: 20 }} />

                <Flex
                    style={{ justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => navigate(`/teacher/${wellnessLecture.teacherId}`)}>
                    <div>
                        <div className="sub-title/st3">{wellnessLecture.teacherName} 코치</div>
                        <div className="body/b1 secondary/s700" style={{ marginTop: 8 }}>코치 소개 보기</div>
                    </div>
                    <ArrowRight width={16} height={16} stroke="#555555" style={{ paddingInline: 8 }} />
                </Flex>

                <DividerThin style={{ marginBlock: 20 }} />

                <MyIssuedWellnessTicketAvaiable
                    title={`사용 가능 ${wellnessLecture.yogaProgramName.includes('Positive COMMUNITY') ? '수강권' : '정기권'}`}
                    issuedWellnessTicketList={issuedWellnessTicketList}
                    isReservation={wellnessLecture.isReservation}
                    selectedId={selectedIssuedWellnessTicketId}
                    setSelectedId={setSelectedIssuedWellnessTicketId} />
            </div>

            {!isLogin()
                ? <BottomBtn text="먼저 로그인 하기" to={'/login'} />
                : dayjs().isAfter(dayjs(wellnessLecture.wellnessLectureStartDateTime))
                    ? <BottomBtn text="이미 지난 수업 입니다." />
                    : wellnessLecture.isReservation
                        ? <CancelReservationWithModalInWellnessLectureDetail
                            wellnessLecture={wellnessLecture}
                            requestWellnessLectureById={requestWellnessLectureById}
                        />
                        : wellnessLecture.isFullBooking
                            ? <BottomBtn text="정원 초과" />
                            : selectedIssuedWellnessTicketId
                                ? <ReservationWithModalInWellnessLectureDetail
                                    wellnessLecture={wellnessLecture}
                                    requestWellnessLectureById={requestWellnessLectureById}
                                    issuedWellnessTicketId={selectedIssuedWellnessTicketId} />
                                : issuedWellnessTicketList.length === 0
                                    ? <>{wellnessLecture.yogaProgramName.includes('Positive COMMUNITY') ?
                                        <BottomBtn text="수강권 구매하러 가기" to={`/popup/ticket/${id}`} /> :
                                        <BottomBtn text="정기권 구매하러 가기" to={`/wellness-ticket-group`} />}
                                    </>
                                    : <BottomBtn text="정기권을 선택해 주세요." />
            }
        </>}
    </>
}

export { WellnessLecture }
import { Spin } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextBtn } from "../../shared";
import { LoadingOutlined } from "@ant-design/icons";
import { CancelReservationWithModal } from "..";
import dayjs from "dayjs";

interface IProps {
    dosanProgramMember: IDosanProgramMember | undefined;
    requestMyReservationByToken: Function;
    requestMyTicketByToken: Function;
    memberData: MemberMyWebResponseV2;
    myReservationList: IReservationMyWebResponseV2[];
}

const ReservatedProgramList = ({ dosanProgramMember, requestMyReservationByToken, requestMyTicketByToken, memberData, myReservationList }: IProps) => {
    const navigate = useNavigate();
    const [isRequestCancel, setIsRequestCancel] = useState<boolean>(false);
    return <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space12)', paddingInline: 'var(--space20)' }}>
        <div className="title/t1" style={{ paddingBlock: 'var(--space8)' }}>다가오는 프로그램</div>
        {dosanProgramMember &&
            <>
                <div style={{ backgroundColor: '#000000', fontSize: '13px', color: '#ffffff', display: 'flex', alignItems: 'center', marginTop: '28px', borderRadius: "4px", padding: '12px 16px', gap: '16px' }} onClick={() => navigate('/invitation/dosan/program')}>
                    <div><img alt="" src={`${process.env.PUBLIC_URL}/assets/icon/ClubhouseProgram.svg`} /></div>
                    <div>
                        <div style={{ color: '#F4F4F4', fontSize: '12px', lineHeight: '20px' }}>{dosanProgramMember?.startDate}</div>
                        <div style={{ marginTop: '4px', color: '#ffffff', fontSize: '16px', lineHeight: '23px', fontFamily: 'TuskerGrotesk6500Medium' }}>GOOD IN BAD OUT</div>
                    </div>
                </div>
            </>}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space12)' }}>
            {myReservationList.length === 0
                ? <div style={{ padding: '12px var(--space12) 12px var(--space12)', backgroundColor: 'var(--secondarys100)', borderRadius: 'var(--radius4)' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%', textAlign: 'center' }}>
                        <div className="body/b2 secondary/s600">
                            예약한 프로그램이 없습니다
                        </div>
                        <TextBtn type='underline' textDecorationColor='' style={{ color: 'var(--secondarys800)' }} onClick={() => navigate('/reservation')} icon={false}>
                            수업 예약하기
                        </TextBtn>
                    </div>
                </div>
                : myReservationList.map((myReservation, idx) =>
                    <div key={idx} style={{ pointerEvents: isRequestCancel ? "none" : "auto", display: 'flex', gap: 'var(--space16)', backgroundColor: 'var(--secondarys100)', padding: 'var(--space12)', borderRadius: "var(--radius4)", cursor: 'pointer' }} >
                        <img style={{ width: '52px', height: '52px', borderRadius: "50%" }} alt="" src={myReservation.teacherProfileImageUrl} onClick={() => navigate(`/lecture/${myReservation.wellnessLectureId}`)} />
                        <div style={{ display: "flex", flexDirection: 'column', gap: 'var(--space8)', width: '100%' }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div className="caption/c1 secondary/s700" onClick={() => navigate(`/lecture/${myReservation.wellnessLectureId}`)}>
                                    {dayjs(myReservation.wellnessLectureStartDateTime).format('YYYY.MM.DD (dd) HH:mm')} - {dayjs(myReservation.wellnessLectureEndDateTime).format('HH:mm')} | {myReservation.yogaCenterName}
                                </div>
                                <TextBtn type='underline' icon={false} >
                                    {isRequestCancel
                                        ? <Spin indicator={<LoadingOutlined spin />} />
                                        : <CancelReservationWithModal
                                            text={"취소"}
                                            myReservation={myReservation}
                                            isRequestCancel={isRequestCancel}
                                            setIsRequestCancel={setIsRequestCancel}
                                            requestMyReservationByToken={requestMyReservationByToken}
                                            requestMyTicketByToken={requestMyTicketByToken}
                                        />}
                                </TextBtn>
                            </div>
                            <div className="title/t1 base/black" onClick={() => navigate(`/lecture/${myReservation.wellnessLectureId}`)}>{myReservation.wellnessLectureName}</div>
                        </div>
                    </div>)
            }
        </div>
    </div>
}
export { ReservatedProgramList };
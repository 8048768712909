import { useNavigate } from "react-router-dom";

const YogaProgram = () => {
    const navigate = useNavigate();

    return (
        <div>
            <div style={{ marginTop: 48, padding: '0 20px' }}>
                <div style={{ fontSize: '20px', lineHeight: '30px', fontFamily: 'TuskerGrotesk6500Medium' }}>YOGA PROGRAM</div>
                <div style={{ color: '#676767', fontSize: '14px', lineHeight: '24px', marginTop: '8px' }}>BLACK 50회 | YELLOW 30회</div>
                <div style={{ fontSize: '14px', lineHeight: '24px', marginTop: '20px' }}>전문 웰니스 코치가 큐레이션하는 다양한 운동 프로그램과 건강한 삶의 가치를 공유하는 커뮤니티를 통해 움직임을 넘어 몸과 마음의 변화를 경험해 보세요.</div>
                <div
                    onClick={() => navigate('/reservation')}
                    style={{ border: '1px solid #676767', padding: '16px', fontSize: '16px', fontWeight: 600, lineHeight: '20px', textAlign: 'center', color: '#676767', marginTop: '40px', borderRadius: '4px', cursor: 'pointer' }}>
                    프로그램 일정 확인
                </div>
            </div>
        </div>
    )
}

export default YogaProgram;
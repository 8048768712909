import { Divider, Flex } from "antd"
import MainHeader from "../../../layout/MainLayout/header"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react";
import { getPrivateClassInquiryList } from "../../../service";
import { TopBar } from "../../../widgets";

const badgeStyle = {
    width: '38px',
    height: '20px',
    borderRadius: '4px',
    padding: '3px 6px',
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

const PrivateClassApplyHistory = () => {
    const navigate = useNavigate();
    const [privateClassInquiryList, setPrivateClassInquiryList] = useState<Array<IPrivateClassInquiry>>([]);

    useEffect(() => {
        requestPrivateClassInquiry();
    }, [])

    const requestPrivateClassInquiry = () => {
        getPrivateClassInquiryList()
            .then(res => {
                setPrivateClassInquiryList(res.data);
            }).catch((error) => console.error('error', error))
    }

    return (<>
        <TopBar title="프라이빗 클래스" rightIcon={false} />
        <div style={{ padding: '24px 20px' }}>
            <div style={{ fontWeight: 700, fontSize: '16px', lineHeight: '20px' }}>신청 내역</div>
            <div style={{ marginTop: '24px' }}>
                {privateClassInquiryList.map((privateClassInquiry: IPrivateClassInquiry, index: number) => {
                    return <div key={index} onClick={() => privateClassInquiry.status !== '취소' && navigate(`/private/class/apply/detail/${privateClassInquiry.id}`)}>
                        <div style={{ padding: '8px 12px' }}>
                            <Flex style={{ justifyContent: 'space-between' }}>
                                <div style={{ fontSize: '16px' }}>{privateClassInquiry.programName}</div>
                                {privateClassInquiry.status === '신청중' ?
                                    <div style={{ backgroundColor: '#E9E9E9', color: '#555555', ...badgeStyle }}>{privateClassInquiry.status}</div> :
                                    privateClassInquiry.status === '취소' ?
                                        <div style={{ backgroundColor: '#ffffff', color: '#555555', border: '1px solid #555555', ...badgeStyle }}>{privateClassInquiry.status}</div> :
                                        privateClassInquiry.status === '확정 완료' ?
                                            <div style={{ backgroundColor: '#000000', color: '#ffffff', ...badgeStyle }}>{privateClassInquiry.status}</div> :
                                            <div>{privateClassInquiry.status}</div>}
                            </Flex>
                            <div style={{ fontSize: '12px', color: '#7D7D7D' }}>
                                <div style={{ marginTop: '8px' }}>
                                    <Flex gap={10}>
                                        <div>신청 날짜</div>
                                        <div>{privateClassInquiry.requestDate}</div>
                                    </Flex>
                                </div>
                                <div style={{ marginTop: '4px' }}>
                                    <Flex gap={10}>
                                        <div>신청 인원</div>
                                        <div>{privateClassInquiry.personCnt}</div>
                                    </Flex>
                                </div>
                            </div>
                        </div>
                        {index < privateClassInquiryList.length - 1 && <Divider style={{ margin: '18px 0', borderColor: '#D9D9D9' }} />}
                    </div>
                })}
            </div>
        </div>
        <div style={{ height: '50px', width: 'calc(100% - 40px)', maxWidth: '440px', margin: '25px 20px', backgroundColor: '#FFF743', color: 'black', position: 'fixed', bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '4px', fontSize: '16px', fontWeight: '600' }}
            onClick={() => navigate('/private/class/apply')}
        >클래스 신청하기</div>
    </>)
}

export default PrivateClassApplyHistory
import { AxiosResponse } from 'axios';
import api from './Api'

export const getIssuedTicketPaymentListByMemberIdAndPeriod = (memberId: number, period: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/issued-wellness-ticket/member/${memberId}?period=${period}`)
}

export const getIssuedWellnessTicketDetailDataForIssuedWellnessTicketId = (issuedWellnessTicketId: string): Promise<AxiosResponse<IIssuedWellnessTicketBasicWebResponseV2>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/issued-wellness-ticket/my/web/v2/${issuedWellnessTicketId}`)
}

export const getAllIssuedWellnessTicketByMemberId = (): Promise<AxiosResponse<IIssuedWellnessTicketBasicResponseV2[]>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/issued-wellness-ticket/my/web/v2`)
}

export const getAllIssuedWellnessTicketByMemberIdAndWellnessLectureId = (wellnessLectureId: string): Promise<AxiosResponse<IIssuedWellnessTicketByMemberIdAndWellnessLectureIdResponseV2[]>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/issued-wellness-ticket/wellness-lecture/web/v2/${wellnessLectureId}`)
}

export const createIssuedWellnessTicketAndPaymentByMemberDtoAndWellnessTicketIdAndOptionalEventCouponIdAndOptionalIssuedYogaCouponIdAndUsedPointWithoutMoney = (params: { wellnessTicketId: string, eventCouponId?: number, usedPoint: number, issuedYogaCouponId?: number, yogaCenterEnum: string }): Promise<AxiosResponse<Boolean, any>> => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/issued-wellness-ticket/eventcoupon/without-money`, params)
}
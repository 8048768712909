import { Flex } from "antd";
import { useNavigate } from "react-router-dom"
import { ReactComponent as ArrowRight } from "../../../assets/images/icon/arrow-right.svg"

const KitchenProduct = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div style={{ marginTop: 48, padding: '0 20px 80px 20px' }}>
                <div style={{ fontSize: '20px', lineHeight: '30px', fontFamily: 'TuskerGrotesk6500Medium' }}>KITCHEN & PRODUCT</div>
                <div style={{ color: '#676767', fontSize: '14px', lineHeight: '24px', marginTop: '8px' }}>BLACK 50만원 | YELLOW 30만원</div>
                <div style={{ fontSize: '14px', lineHeight: '24px', marginTop: '20px' }}>선릉 스튜디오, 도산 클럽하우스에서 제공하는 건강한 식문화. 그리고 좋은 원료를 바탕으로 개발된 제품을 통해 일상 속에서웰니스 경험을 선사합니다.</div>
                <div style={{ marginTop: 40 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                        <div style={{ width: '100%', position: 'relative', cursor: 'pointer' }} onClick={() => navigate('/center/3')}>
                            <img src={'https://img.positivehotel.io/2024/7/11/seolleung-02.jpg'} alt="파지티브호텔 멤버십" style={{ width: '100%', borderRadius: '4px', filter: 'brightness(0.5)' }} />
                            <div style={{ position: 'absolute', bottom: 0, width: 'calc(100% - 24px)', padding: '12px', fontSize: '16px' }}>
                                <Flex style={{ justifyContent: 'space-between', color: 'white', fontWeight: '600' }}>
                                    <div>선릉 스튜디오</div>
                                    <div><ArrowRight width={16} height={16} stroke="#000000" /></div>
                                </Flex>
                            </div>
                        </div>
                        <div style={{ width: '100%', position: 'relative', cursor: 'pointer' }} onClick={() => navigate('/center/2')}>
                            <img src={'https://img.positivehotel.io/2023/12/8/PHB09250.jpg'} alt="파지티브호텔 멤버십" style={{ width: '100%', borderRadius: '4px', filter: 'brightness(0.5)' }} />
                            <div style={{ position: 'absolute', bottom: 0, width: 'calc(100% - 24px)', padding: '12px' }}>
                                <Flex style={{ justifyContent: 'space-between', color: 'white', fontWeight: '600', fontSize: '16px' }}>
                                    <div>도산 클럽하우스</div>
                                    <div><ArrowRight width={16} height={16} stroke="#000000" /></div>
                                </Flex>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '100px', color: '#555555', fontSize: '12px', textAlign: 'center' }}>멤버십 안내는 [마이 {'>'} 멤버십]에서 다시 확인하실 수 있습니다.</div>
                <div
                    onClick={() => navigate('/')}
                    style={{ border: '1px solid #676767', padding: '16px', fontSize: '16px', fontWeight: 600, lineHeight: '20px', textAlign: 'center', color: '#676767', marginTop: '16px', borderRadius: '4px', cursor: 'pointer' }}>
                    홈으로
                </div>
            </div>

        </div>
    )
}
export default KitchenProduct
import { Modal } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const PrivateService = () => {
    const navigate = useNavigate();
    const [isOpenApplySolutionModal, setIsOpenApplySolutionModal] = useState<boolean>(false);

    return (<>
        <div style={{ marginTop: 80, backgroundColor: 'black', color: 'white' }}>
            <img src={'https://img.positivehotel.io/2024/7/23/PrivateService.png'} alt="파지티브호텔 멤버십" style={{ width: '100%' }} />
            <div style={{ marginTop: 48, padding: '0 20px 80px 20px' }}>
                <div style={{ fontSize: '20px', fontFamily: 'TuskerGrotesk6500Medium' }}>PRIVATE SERVICE</div>
                <div style={{ marginTop: 48 }}>
                    <div style={{ fontWeight: 700, fontSize: '16px', lineHeight: '20px' }}>클럽하우스 솔루션</div>
                    <div style={{ marginTop: '8px', lineHeight: '24px', fontSize: '14px' }}>응모 후 추첨 방식</div>
                    <div style={{ marginTop: '20px', lineHeight: '24px', fontSize: '14px' }}>1년간 6개의 ‘GOOD IN BAD OUT’ 고감도 테라피 솔루션을 선보입니다.</div>
                    <div
                        onClick={() => setIsOpenApplySolutionModal(true)}
                        style={{ border: '1px solid #7D7D7D', padding: '16px', fontSize: '16px', fontWeight: 600, lineHeight: '20px', textAlign: 'center', color: '#D9D9D9', marginTop: '40px', borderRadius: '4px', cursor: 'pointer' }}>
                        솔루션 응모
                    </div>
                </div>

                <div style={{ marginTop: 48 }}>
                    <div style={{ fontWeight: 700, fontSize: '16px', lineHeight: '20px' }}>프라이빗 그룹 클래스</div>
                    <div style={{ marginTop: '8px', lineHeight: '24px', fontSize: '14px' }}>BLACK 5회 | YELLOW 3회</div>
                    <div style={{ marginTop: '20px', lineHeight: '24px', fontSize: '14px' }}>더욱 깊이 있는 소수 프라이빗 클래스를 통해 차별화된 웰니스 라이프를 즐겨보세요.</div>
                    <div
                        onClick={() => navigate('/private/class/apply')}
                        style={{ border: '1px solid #7D7D7D', padding: '16px', fontSize: '16px', fontWeight: 600, lineHeight: '20px', textAlign: 'center', color: '#D9D9D9', marginTop: '40px', borderRadius: '4px', cursor: 'pointer' }}>
                        프라이빗 클래스 신청
                    </div>
                </div>
            </div>
        </div>
        <Modal centered open={isOpenApplySolutionModal} onCancel={() => setIsOpenApplySolutionModal(false)} footer={[]}>
            <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '22px', fontWeight: 'bold', marginTop: '20px' }}>응모를 완료했습니다</div>
                <div style={{ marginTop: '5px', fontSize: '13px' }}>추첨을 통해 카카오 알림톡으로<br />초대장을 보내드려요</div>
            </div>
            <div style={{ backgroundColor: 'black', border: '1px solid #555', borderRadius: '5px', color: 'white', padding: '15px', textAlign: 'center', fontSize: '15px', marginTop: '15px', cursor: 'pointer' }}
                onClick={() => setIsOpenApplySolutionModal(false)}>확인</div>
        </Modal></>
    )
}
export default PrivateService;
import { Flex } from "antd";
import { ReactComponent as ArrowRight } from "../../assets/images/icon/arrow-right.svg"
import { Link } from "react-router-dom";

interface IProps {
    title: string | React.ReactNode;
    value: string;
    to: string;
    state?: any;
}

const MemberBenefitInfo = ({ title, value, to, state }: IProps) => {
    return (<>
        <div style={{ display: 'inline-block', cursor: 'pointer' }}>
            <Link to={to} style={{ textDecoration: 'none', color: 'var(--baseBlack)' }} state={state}>
                <Flex style={{ alignItems: 'center' }} gap={4}>
                    <div className="body/b3">{title}</div>
                    <ArrowRight width={16} height={16} stroke="#AEAEAE" style={{ marginBottom: 3 }} />
                </Flex>
                <div className="sub-title/st4">{value}</div>
            </Link>
        </div>
    </>
    )
}
export { MemberBenefitInfo }